import React from 'react';
import { Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Popover, TextField, Typography } from '@mui/material';
import styles from 'custom/style.module.css';
import noteStyles from '../../../common/notes.module.css';
import SendIcon from '../../../../../assets/images/actionIcons/paperPlane.svg';
import { PopoverContent } from 'custom/components';
import { DateTimeFormat } from 'custom/constants';
import { dateTolocale } from 'utils/converter';

const NOTE_LENGTH = 50;

const AddNotes = (props) => {
  const { popup, anchorEl, closePopup } = props;

  return (
    <div>
      <Popover
        open={popup === 'notes'}
        anchorEl={anchorEl}
        onClose={() => closePopup()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <PopoverContent className={styles.p2} sx={{ width: 300 }}>
          <div className={noteStyles.notesWrapper}>
            <TextField
              placeholder='Add Notes...'
              multiline
              rows={5}
              maxRows={Infinity}
              variant='standard'
              autoFocus
              name='note'
              fullWidth
              onChange={(e) => props.handleNotes(e)}
              value={props.note}
              InputProps={{
                disableUnderline: true,
                classes: { input: noteStyles.ipFontSize }, 
              }}
            />
            <IconButton className={`${styles.sendIcon} ${styles.p0} ${styles.dBlock} ${styles.mlauto}`} onClick={props.handleNoteBlur}>
              <img src={SendIcon} alt='send' width={'20px'} height={'auto'} />
            </IconButton>
          </div>
          <List sx={{
            maxHeight: 300,
            overflow: 'auto',
            marginRight: '-10px',
            paddingRIght: '10px',
            marginTop: '10px',
            paddingRight: '10px',
          }}>
            {props.statRowData && props.statRowData?.note ? props.statRowData?.note?.map((note, index) => (
              <ListItem key={index} className={`${noteStyles.historyListItem} ${noteStyles.noteListItem}`}>
                <ListItemAvatar className={styles.mr2}>
                  <Typography variant='span' color='white' textTransform={'uppercase'} fontSize='10px' bgcolor={'rebeccapurple'} fontWeight={800} px={0.5} py={0.25} borderRadius={'4px'} lineHeight={'normal'} minWidth={'70px'} display={'block'} textAlign={'center'}>
                    {note.user.role}
                  </Typography>
                </ListItemAvatar>
                <ListItemText
                  className={noteStyles.historyListText}
                  primaryTypographyProps={{
                    fontSize: 12,
                    color: 'primary.main',
                    fontWeight: 500,
                  }}
                  secondaryTypographyProps={{ fontSize: 12 }}
                  primary={<>
                    <span className={styles.overflowBreak}>{props.showMoreNote[index] ? 
                      (note?.note?.length > NOTE_LENGTH ? note?.note?.slice(0, NOTE_LENGTH) + '...' : note?.note) : note?.note}</span>
                    {note?.note?.length > NOTE_LENGTH &&
                    <Button onClick={() => props.handleReadMore(index)} size='small' variant='text'>
                      <Typography fontStyle={'italic'} component='span' fontSize={12} color={'text.secondary'} textTransform={'capitalize'} >
                         &nbsp;Read {props.showMoreNote[index] ? 'more' : 'less'}</Typography>
                    </Button>
                    }</>}
                  secondary={`${note.user.first_name} ${note.user.last_name} | ${dateTolocale(note.created, DateTimeFormat).toUpperCase()}`} />
              </ListItem>
            )) : null}
          </List>
        </PopoverContent>
      </Popover>
    </div>
  );
};
export default AddNotes;