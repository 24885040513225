import React from 'react';
import { Box, Grid, IconButton, Stack, Tabs, Typography } from '@mui/material';
import styles from '../../../custom/style.module.css';
import { CustomTab, DataGridProps, StyledCard, StyledCardTitle, StyledDataGrid } from 'custom/components';
import { ExcelIcon } from 'custom/constants';
import { CsvToExcel } from 'custom/export';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import theme from 'custom/theme';
import moment from 'moment';

const FfmDashboard = (props) => {

  const apiRef = React.useRef(null);

  const columns = [
    {
      field: 'airline',
      headerName: 'Airline',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'flight',
      headerName: 'Flight/TRK',
      type: 'text',
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'seg',
      headerName: 'Segment',
      type: 'text',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'source',
      headerName: 'Sender Mail ID',
      type: 'text',
      minWidth: 60,
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'Last FFM Received',
      type: 'text',
      minWidth: 60,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ color: params.row.notRecvd ? 'red': 'inherit' }}>{params.value}</div>
        );
      }
    }
  ];

  const msgColumns = [
    {
      field: 'created',
      headerName: 'Date',
      type: 'text',
      minWidth: 60,
      flex: 1,
    },
    {
      field: 'source',
      headerName: 'Sender Mail ID',
      type: 'text',
      minWidth: 60,
      flex: 1,
    },
    {
      field: 'msg',
      headerName: 'Message',
      type: 'text',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (params.row.content == 'TEXT') {
          const data = params.value.trim().split('\n');
          let parsedData = {};
          data.reverse().pop();
          let flight_parse = data.pop();
          parsedData.flight_no = flight_parse.split('/')[1];
          parsedData.flight_date = moment(flight_parse.split('/')[2], 'DDMMM').format('YYYY-MM-DD');
          parsedData.org = flight_parse.split('/')[3];
          parsedData.reg_no = flight_parse.split('/')?.[4] || null;
          parsedData.dest = data.pop()?.split('/')[0];
          return (
            <div>
              <div><strong>Flight No:</strong> {parsedData.flight_no}</div>
              <div><strong>Flight Date:</strong> {parsedData.flight_date}</div>
              <div><strong>Origin:</strong> {parsedData.org}</div>
              <div><strong>Destination:</strong> {parsedData.dest}</div>
              <div><strong>Reg No:</strong> {parsedData.reg_no}</div>
            </div>
          );
        } else {
          return params.row.type;
        }
      }
    },
    {
      field: 'log',
      headerName: 'Log',
      type: 'text',
      minWidth: 60,
      flex: 1,
      renderCell: (params) => {
        if (params.row.content == 'TEXT') {
          if (params.row.log) {
            if (params.row.log?.message) {
              return (
                <div><pre>{params.row.log.message}</pre></div>
              );
            } else {
              return (
                <div><pre>{params.row.log?.error}</pre></div>
              );
            }
          } else {
            return (
              <div><pre>Not Processed</pre></div>
            );
          }
        } else {
          return params.row.verfied_by?.name;
        }
      }
    }
  ];


  const handleExport = async () => {
    const csvData = await apiRef.current.getDataAsCsv();
    CsvToExcel(csvData, 'FFM');
  };
  function TabPanel(tabPanelProps) {
    const { children, index, ...other } = tabPanelProps;
    return (
      <div
        role='tabpanel'
        hidden={props.value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabPanelProps.value === index && (
          <Box sx={{ pt: 0 }}>
            <Typography component='span'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(path) {
    const index = path.replace('approvals/', '');
    return {
      id: `simple-tab-${index}`,
      value: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledCard className={styles.positionRelative}>
          <div className={`${styles.fSpaceBtw} ${styles.mb3}`}>
            <StyledCardTitle gutterBottom variant='' component='div' className={styles.pb0}>FFM Dashboard</StyledCardTitle>
            <Stack direction={'row'} spacing={2}>
              {props.value == 'dashbaord' ? <ExcelIcon title='Download File' onClick={handleExport} className={`${styles.tableIcon}`} /> :
              // <CustomSearch
              //   id='flight-search'
              //   placeholder='Enter flight Number'
              //   type='search'
              //   fullWidth
              //   InputProps={{
              //     disableUnderline: true,
              //     endAdornment: <InputAdornment position='end'>
              //       <IconButton
              //         size='small'
              //         aria-label='search'
              //         onClick={() => props.onSearch(true)}
              //         edge='end'
              //       >
              //         <Search fontSize='inherit' />
              //       </IconButton>
              //     </InputAdornment>,
              //   }}

                //   InputLabelProps={{ shrink: false }}
                //   name='awb'
                //   className={`${styles.brInput}`}
                // // error={}
                // // helperText={}
                // />
                null
              }
            </Stack>
          </div>
          <Tabs value={props.value} onChange={(_, v) => props.handleTabChange(v)}
            className={`${styles.customTabs} ${styles.dFlex}`}
            // variant='scrollable'
            variant='fullWidth'
            aria-label='FFM tabs'
            indicatorColor='transparent'
            classes={{ scroller: styles.tabFlexContainer }}
            ScrollButtonComponent={(props) => {
              if (
                props.direction === 'left'
              ) {
                return (
                  <IconButton {...props} disableRipple size='small'>
                    <ArrowLeftIcon fontSize='inherit'
                      sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                    />
                  </IconButton>
                );
              } else if (
                props.direction === 'right'
              ) {
                return (
                  <IconButton {...props} disableRipple>
                    <ArrowRightIcon
                      sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                    />
                  </IconButton>
                );
              } else {
                return null;
              }
            }}>
            <CustomTab label={'Dashbaord'} {...a11yProps('dashbaord')} />
            <CustomTab label={'Gatekeeper'} {...a11yProps('gatekeeper')} />
          </Tabs>
          <TabPanel value={props.value} index={'dashbaord'}>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter}`}>
                <StyledDataGrid
                  apiRef={apiRef}
                  {...DataGridProps}
                  rows={props.data}
                  columns={columns}
                  param={props.data?.length}
                />
              </Box>
            </div>
          </TabPanel>
          <TabPanel value={props.value} index={'gatekeeper'}>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.msgs}
                  columns={msgColumns}
                  param={props.data?.length}
                  onRowClick={(params) => {
                    console.log(params.row);
                    props.handleOpen(params.row);
                  }}
                  initialState={
                    {
                      sorting: {
                        sortModel: [
                          {
                            field: 'created',
                            sort: 'desc',
                          },
                        ],
                      }
                    }
                  }
                />
              </Box>
            </div>
          </TabPanel>
        </StyledCard>
      </Grid>
    </Grid>
  );
};

export default FfmDashboard;