import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const primaryBlue = '#092b4a';

const styles = StyleSheet.create({
  label: {
    fontSize: 8,
    paddingBottom: 2,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    fontFamily: 'NunitoRegular',
    color: '#808080',

  },
  data: {
    fontSize: 10,
    lineHeight: 'normal',
    fontFamily: 'NunitoRegular',
    color: primaryBlue,
  },
  fSpaceBtw: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  info: {
    width: '20%',
    padding: 4,
  },
  infoAuto: {
    width: 'auto',
    padding: 4,
  },
  infoLg: {
    width: '60%',
    padding: 4,
  },
  title: {
    fontSize: 10,
    textTransform: 'uppercase',
    color: primaryBlue,
    fontFamily: 'NunitoMedium',
  },

  footerContainer: {
    position: 'absolute',
    bottom: 50,
    left: 40,
    right: 40,
    backgroundColor: '#f0f0f0',
  },
  footerWrapper: {
    flexDirection: 'row',
    padding: 10,
  },
  loadAccessories: {
    flex: 1,
    paddingRight: 10,
    borderRight: '1px solid lightgrey',
  },
  uldDetails: {
    flex: 2,
    paddingLeft: 10,
  },
});


const Footer = (props) => {

  const { uld, accessories } = props;

  return (
    <View style={styles.footerContainer}>
      <View style={styles.footerWrapper}>
        <View style={styles.loadAccessories}>
          <Text style={styles.title}>Loading Accessories Used</Text>
          {accessories.map((accessory, index) => (
            <View style={styles.fSpaceBtw} key={index}>
              <Text style={styles.label}>{accessory.description}</Text>
              <Text style={styles.data}>{uld && uld.uld_loadingaccessories && uld.uld_loadingaccessories?.find((acc) => acc.loadingaccessoriesId === accessory.id)?.value || ''}</Text>
            </View>
          ))}
        </View>
        <View style={styles.uldDetails}>
          <Text style={styles.title}>ULD Closing Details</Text>
          <View style={styles.row}>
            <View style={styles.info}>
              <Text style={styles.label}>ULD Dest</Text>
              <Text style={styles.data}>{uld.dest}</Text>
            </View>
            <View style={styles.info}>
              <Text style={styles.label}>Contour</Text>
              <Text style={styles.data}>{uld.contour}</Text>
            </View>
            <View style={styles.infoLg}>
              <Text style={styles.label}>Remarks</Text>
              <Text style={styles.data}>{uld.remark}</Text>
            </View>
            <View style={styles.info}>
              <Text style={styles.label}>Scale Wgt</Text>
              <Text style={styles.data}>{uld.scale_wgt}</Text>
            </View>
            <View style={styles.info}>
              <Text style={styles.label}>Dolly Tare</Text>
              <Text style={styles.data}>{uld.dolly_tare}</Text>
            </View>
            <View style={styles.info}>
              <Text style={styles.label}>ULD Wgt</Text>
              <Text style={styles.data}>{uld.wgt}</Text>
            </View>
            <View style={styles.infoAuto}>
              <Text style={styles.label}>Finalized By</Text>
              <Text style={styles.data}>{uld.finalized_by? `${uld.finalized_by.first_name} ${uld.finalized_by.last_name}` : ''}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Footer;