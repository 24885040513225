import React from 'react';
import { Document, Page, StyleSheet, View, Text } from '@react-pdf/renderer';
import moment from 'moment';
import cookies from 'utils/cookies';
import FlightInfo from './FlightInfo';
import FlightNotes from './FlightNotes';
import ManifestSummary from './ManifestSummary';
import Discrepancy from './Discrepancy';
import FlightReport from './FlightReport';
import FlightHistory from './FlightHistory';
// import Manifest from './Manifest';
const timezone = cookies.get('timezone') || null;
const station_code = cookies.get('station_code') || null;

const invoice = {
  trans_date: timezone? moment().tz(timezone).format('DD MMM YYYY')?.toUpperCase() : null, 
  due_date: timezone? moment().add('1','months').tz(timezone).format('DD MMM YYYY') : null,
  station: station_code, 
};

const primaryBlue = '#092b4a';

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 30,
    paddingLeft:60,
    paddingRight:60,
    lineHeight: 1.5,
    flexDirection: 'column',
    paddingBottom: 65,
    paddingHorizontal: 35,
  }, 
  titleContainer:{
    flexDirection: 'row',
    justifyContent: 'space-between', 
    alignItems: 'flex-end',
    margin: '-30px -35px 0 -35px',
    padding: '30px 35px 0',
    color: primaryBlue,
    borderBottom: '1px solid #092b4a',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  date: {
    fontSize: 10,
    color: primaryBlue,
    width: 100,
    textAlign: 'right',
  },
  invoiceLabel: {
    fontSize: 14,
    textTransform: 'uppercase',
    lineHeight: 'normal',
    color: primaryBlue,
    paddingBottom: 5,
    fontFamily: 'NunitoRegular',
  },  
});


const MyDocument = (props) => {
  return (
    <Document>
      <Page size='A4' style={styles.page} wrap={true}>
        <View style={styles.titleContainer}>
          <Text style={styles.invoiceLabel}>Flight Report</Text>
          <Text style={styles.date}>{invoice.trans_date} | {invoice.station}</Text>
        </View>
        <FlightInfo info={props.flightInfo} flight_gate={props.flight_gate} ctype={props.ctype} />
        <ManifestSummary summary={props?.manifestSummary} nil={props.nil} />
        {/* <Manifest manifest={flightReportDetails?.manifest} products={flightReportDetails?.products} /> */}
        <FlightReport report={props?.flightReport} 
          statistics={props?.statistics} 
          checklist_performed_by={props?.checklist_performed_by}
        /> 
        <Discrepancy discrepancy={props?.discrepancy} />
        <FlightNotes notes={props?.note} />
        <FlightHistory history={props?.history} />
  
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
  
      </Page>
    </Document>
  );
};

export default MyDocument;
