import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import {
  Card, CardContent, Checkbox, Grid, Stack, FormGroup, FormControl, MenuItem, FormHelperText, 
  CardHeader, Typography, Icon, RadioGroup, Radio, 
  IconButton,
  Divider,
  Button,
  Popper,
} from '@mui/material';
import flightStyles from './myflights.module.css';
import styles from '../../../custom/style.module.css';
import { BorderLinearProgress, CustomButton, CustomCheckbox, CustomDatePicker, CustomInputLabel, CustomRadio, CustomSelectRounded, DataGridProps, InputProps, SelectInputProps, StyledCard, StyledCardTitle, StyledDataGrid } from 'custom/components';
import AddAdhocFlightModal from './AddAdhocFlightModal';
import { Link } from 'react-router-dom';
import { DateFormat, DeleteIcon, EditIcon, TickIcon } from 'custom/constants';
import moment from 'moment-timezone';
import cookies from 'utils/cookies';
import dayjs from 'dayjs';
import Countdown from 'react-countdown';
import allData from 'airline-iata-code';
import { dateTolocale } from 'utils/converter';
import { grey } from '@mui/material/colors';
import FolderTop from '../../../assets/images/folderTopBlack.svg';
import { Cancel } from '@mui/icons-material';

const STACKFILTER = {
  direction:{
    xs: 'column',
    lg: 'row',
  }, 
  align:{
    xs: 'flex-start',
    lg: 'center',
  }
};

const station = cookies.get('station_code') || null;

const getpercentage = (date1, date2) => {
  let total = moment(date2).diff(date1, 'minutes');
  let current = moment(moment().utc().format('YYYY-MM-DD HH:mm:ss')).diff(date1, 'minutes');
  if (current < 0) {
    return 0;
  } else {
    if (current >= total) return 100;
    let rem = Math.round((current / total) * 100);
    return rem;
  }
};

const getCountdown = (date) => {
  let dateUTC = moment.utc(date).local().format('YYYY-MM-DDTHH:mm:ss');
  return new Date(dateUTC);
};

const renderer = ({ hours, minutes, completed }) => {
  if (completed) {
    // Render a completed state
    return '00:00';
  } else {
    // Render a countdown
    return <span>{String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}</span>;
  }
};

const MyFlights = (props) => {
  
  const getFlightReportStatus = (flight) => {
    let reports = flight?.reports || [];
    let crg_hdle = reports?.find((report) => report.type === 'Cargo Handling');
    let flt_ops = reports?.find((report) => report.type === 'Flight Ops');
    let dry_age = reports?.find((report) => report.type === 'Drayage');
    return (
      <Stack direction={'row'} spacing={0}>
        {
          props?.searchFields?.contract_type === 'Cargo Handling' &&
        <IconButton 
          onMouseEnter={(e) => props.handlePopover(e.currentTarget, crg_hdle, 'Cargo Handling')}
          onMouseLeave={props.handlePopoverClose} 
          disableRipple className={styles.p0}>
          <ReportIcon icon='C' data={crg_hdle} title='Cargo Handling' 
          />
        </IconButton>
        }
        {
          props?.searchFields?.contract_type === 'Flight Ops' &&
        <IconButton 
          onMouseEnter={(e) => props.handlePopover(e.currentTarget, flt_ops, 'Flight Ops')}
          onMouseLeave={props.handlePopoverClose} 
          disableRipple className={styles.p0}>
          <ReportIcon icon='F' data={flt_ops} title='Flight Ops' 
          />
        </IconButton>
        }
        {
          props?.searchFields?.contract_type === 'Drayage' &&
        <IconButton 
          onMouseEnter={(e) => props.handlePopover(e.currentTarget, dry_age, 'Drayage')}
          onMouseLeave={props.handlePopoverClose} 
          disableRipple className={styles.p0}>
          <ReportIcon icon='D' data={dry_age} title='Drayage' 
          />                   
        </IconButton>
        }
      </Stack>
    );
  };

  const columns = [
    {
      field: 'airline',
      headerName: 'Airline',
      align: 'center',
      renderCell: ({ row }) => {
        let airline = allData(row?.airline?.code);
        return (
          <div className={styles.positionRelative}>
            {airline && airline.length > 0 && (<img src={airline[0].logo} alt='img' height={50} />)}
            {row?.notes?.length > 0 && 
            <span className={`${flightStyles.folderBadgeGreen} ${flightStyles.tableNoteCount}`}>{row?.notes?.length}</span>}</div>
        );
      }
    },
    {
      field: 'flight_no',
      headerName: 'Flight',
      minWidth: 140,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div>{row?.airline?.code + row?.flight_no}/{row?.flight_date ? moment(row?.flight_date).format(DateFormat).toUpperCase() : ''}</div>
        );
      }
    },
    {
      field: 'arr_dept',
      headerName: 'ARR/DEPT',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div>{row.dest === station ? 'ARR' : 'DEP'}</div>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div>{row.status}</div>

        );
      }
    },
    {
      field: 'rengo',
      headerName: 'REGNO',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div>{row.reg_no}</div>
        );
      }
    },
    {
      field: 'ac_type',
      headerName: 'AC Type',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div>{row?.ac_type}</div>
        );
      }
    },
    {
      field: 'org_dest',
      headerName: 'ORG-DEST',
      minWidth: 110,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div>{row?.org + '-' + row?.dest}</div>
        );
      }
    },
    {
      field: 'schdl_arr_dept',
      headerName: 'SCHDL ARR/DEPT',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <span><b>{row.dest === station ? dateTolocale(row?.schdl_arr, 'HH:mm') : dateTolocale(row?.schdl_dept, 'HH:mm')}</b></span>
        );
      }
    },
    {
      field: 'countdown',
      headerName: 'Countdown',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div className={flightStyles.progressWrapper}><BorderLinearProgress variant='determinate' value={getpercentage(row?.act_dept || row?.est_dept || row?.schdl_dept, row?.act_arr || row?.est_arr || row?.schdl_arr)} className={styles.w100} /> <span><Countdown date={getCountdown(row?.act_arr || row?.est_arr || row?.schdl_arr)} startTime={getCountdown(row?.act_dept || row?.est_dept || row?.schdl_dept)} renderer={renderer} /></span></div>
        );
      }
    },
    {
      field: 'ontime',
      headerName: 'Flight Status',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div>{row.dest === station ?
            (moment(row?.schdl_arr).isSame(row?.act_arr || row?.est_arr || row?.schdl_arr) ? 'On Time' :
              (moment(row?.act_arr || row?.est_arr).isAfter(row?.schdl_arr) ? 'Delayed' : 'Early')) :
            (moment(row?.schdl_dept).isSame(row?.act_dept || row?.est_dept || row?.schdl_dept) ? 'On Time' :
              (moment(row?.act_dept || row?.est_dept).isAfter(row?.schdl_dept) ? 'Delayed' : 'Early'))
          }</div>
        );
      }
    },
    {
      field: 'gate',
      headerName: 'Gate',
      minWidth: 60,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div>{row.org == station ? row?.dept_gate : row?.arr_gate}</div>
        );
      }
    },
    {
      field: 'flight_report_status',
      headerName: 'Flight Report Status',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => getFlightReportStatus(row)
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          !row.flight_schedule_id ?
            <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
              <EditIcon onClick={(e) => { props.handleOpen(e, 'adhoc_flight', 'edit', row); }} />
              <DeleteIcon onClick={(e) => props.handleDelete(row.id, e.target)} />
            </Stack> : <>-</>
        );
      }
    },
  ];

  const ReportIcon = (reportProps) => {

    return (
      <>
        <div className={reportProps?.status === 'Rejected' ? flightStyles.blink : ''}></div>
        <Icon className={`${flightStyles.reportIcon}`} style={{ backgroundColor: statusColor(reportProps) }}></Icon>
      </>
    );
  };

  const statusColor = (statusData, type) => {
    let color = 'var(--primaryblue)';
    let className = flightStyles.blueTop;
    if (statusData) {
      if (statusData.status === 'Finalized') {
        color = 'var(--finalized)';
        className = flightStyles.greenTop;
      } else if (statusData.status === 'Submitted') { //inprogress
        color = 'var(--in_progress)';
        className = flightStyles.amberTop;
      } else if (statusData.status === 'Rejected') {
        color = 'var(--rejected)';
        className = flightStyles.redTop;
      } else if (statusData.status === 'Reopen') {
        color = 'var(--primaryblue)';
        className = flightStyles.blueTop;
      } else if (statusData.status === 'Approved' || statusData.approved_acc || statusData.approved_air) {
        color = 'var(--approved)';
        className = flightStyles.greenTop;
      }
    }
    return type === 'className' ? className : color;
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <div 
          className={`${styles.wAuto} ${flightStyles.headerWraperTop}`}>
          <StyledCard className={`${flightStyles.filterCard}`}>
            <StyledCardTitle gutterBottom className={`${flightStyles.filterTitle}`}>Filter Flights</StyledCardTitle>
            <Grid container columnSpacing={{
              xs: 4,
              lg: 2,
              xl: '2%'
              // xl: 'clamp(2rem, 5%, 4rem)'
            }} rowSpacing={{
              xs: 2,
              lg: 0,
            }} justifyContent={{
              xs: 'flex-start',
              lg: 'center',
            }}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <Stack direction={{
                  xs: 'column',
                  lg: 'row',
                }}
                align={{
                  xs: 'flex-start',
                  lg: 'center',
                }}>
                  <CustomInputLabel htmlFor='' aria-label='Enter from date' className={`${styles.ipLabel}`}>From</CustomInputLabel>
                  <CustomDatePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        placeholder: 'From Date',
                        fullWidth: true
                      },
                    }}
                    format='DD MMM YYYY'
                    className={`${styles.brInput} ${styles.w100} ${styles.minWidth125}`}
                    onChange={(v) => props.onFilterMain(
                      {
                        target: {
                          type: 'date',
                          name: 'from',
                          value: moment(v.$d).startOf('day').format('YYYY-MM-DD HH:mm')
                        }
                      })}
                    value={dayjs(moment(props.searchFields.from).format('YYYY-MM-DD HH:mm'))}
                    disabled={props.showRejectedFlights}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <Stack {...STACKFILTER}>
                  <CustomInputLabel htmlFor='' aria-label='Enter to date' className={`${styles.ipLabel}`}>To</CustomInputLabel>
                  <CustomDatePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        placeholder: 'To Date',
                        fullWidth: true
                      },
                    }}
                    format='DD MMM YYYY'
                    className={`${styles.brInput} ${styles.w100} ${styles.minWidth125}`}
                    onChange={(v) => props.onFilterMain(
                      {
                        target: {
                          type: 'date',
                          name: 'to',
                          value: moment(v.$d).endOf('day').format('YYYY-MM-DD HH:mm')
                        }
                      })}
                    value={dayjs(moment(props.searchFields.to).format('YYYY-MM-DD HH:mm'))}
                    disabled={props.showRejectedFlights}
                  />
                </Stack>
              </Grid>
            </Grid>
          </StyledCard>
        </div>

        <Grid container flexWrap={'wrap'} spacing={0}>
          <Grid item xs={12} md={12} lg={3} className={flightStyles.addFlightWrapper}>
            <div className={flightStyles.addFlight}>
              <CustomButton variant='contained' onClick={() => { props.handleOpen('adhoc_flight', 'add'); }} className={styles.ml3}>Add Adhoc Flight/Truck</CustomButton>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={5} flexWrap={'wrap'}>
            <FormGroup aria-label='position' row className={`${flightStyles.checkboxFilter} ${styles.h100}`}>
              <Stack direction='row' spacing={{
                xl: '4%',
                lg: '2%',
                md: 1,
                xs: '4%',
              }}
              justifyContent={{
                xl: 'flex-end',
                lg: 'flex-start',
                xs: 'flex-start',
              }}
              flexWrap={'wrap'}
              className={styles.w100}
              >
                <CustomCheckbox
                  control={<Checkbox
                    inputProps={{ 'aria-label': 'arrival', }}
                    size='small'
                    checked={props.flightStatus.arr}
                    onChange={props.changeCheck}
                    name='arr' />}
                  label='Arrivals'
                  classes={{
                    root: flightStyles.flightCheckboxRoot,
                    label: flightStyles.flightCheckboxLabel,
                  }}
                />
                <CustomCheckbox
                  control={<Checkbox
                    inputProps={{ 'aria-label': 'depature', }}
                    size='small'
                    checked={props.flightStatus.dept}
                    onChange={props.changeCheck} name='dept' />}
                  label='Depatures'
                  classes={{
                    root: flightStyles.flightCheckboxRoot,
                    label: flightStyles.flightCheckboxLabel,
                  }}
                />
                {( cookies.get('role') === 'Office Agent' || 
                    cookies.get('role') === 'Senior Office Agent' || 
                    cookies.get('role') === 'System Admin' || 
                    cookies.get('role') === 'Supervisor' || 
                    cookies.get('role') === 'Manager' ||
                    cookies.get('role') === 'Accounting' ) &&                  
                  <CustomCheckbox
                    control={<Checkbox
                      inputProps={{ 'aria-label': 'submitted', }}
                      size='small'
                      checked={props.flightStatus.submitted}
                      onChange={props.changeCheck} name='submitted' />}
                    label='Hide Finalized'
                    classes={{
                      root: flightStyles.flightCheckboxRoot,
                      label: flightStyles.flightCheckboxLabel,
                    }}
                  />
                }
                <CustomCheckbox
                  control={<Checkbox
                    inputProps={{ 'aria-label': 'rejected', }}
                    size='small'
                    checked={props.showRejectedFlights}  
                    onChange={props.handleRejectedFlights} 
                    name='rejected' />}
                  label='Show Rejected'
                  classes={{
                    root: flightStyles.flightCheckboxRoot,
                    label: flightStyles.flightCheckboxLabel,
                  }}
                />
                {/* {(cookies.get('role') === 'Office Agent' || 
                cookies.get('role') === 'Senior Office Agent' || 
                cookies.get('role') === 'System Admin' || 
                cookies.get('role') === 'Supervisor' || 
                cookies.get('role') === 'Manager' ) &&
                <CustomCheckbox
                  control={<Checkbox inputProps={{ 'aria-label': 'hide completed', }}
                    size='small'
                    checked={props.flightStatus.hideCompleted} onChange={props.changeCheck}
                    name='hideCompleted' />}
                  label='Hide Finalized Flights'
                  disabled={props.showRejectedFlights}
                  classes={{
                    root: flightStyles.flightCheckboxRoot,
                    label: flightStyles.flightCheckboxLabel,
                  }} />
                }  */}
              </Stack>
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={4} className={`${flightStyles.selectFilter}`}>
            <Stack direction='row' justifyContent={'flex-end'} alignItems={'flex-end'} spacing={2}>
              <Stack direction='row' justifyContent={{
                md: 'flex-start',
                xs: 'left'
              }} alignItems='center'  flexWrap='wrap'>
                <CustomInputLabel id='airline-label' aria-label='Enter airline' className={styles.mr2}>Airline</CustomInputLabel>
                <FormControl variant='standard' fullWidth className={flightStyles.w150}> 
                  <CustomSelectRounded
                    {...SelectInputProps}
                    id='airline'
                    labelId='airline-label'
                    fullWidth
                    onChange={props.changeCheck}
                    name='airline'
                    value={!props.flightStatus.airline ? 'placeholder' : props.flightStatus.airline}
                    variant='outlined'
                    className={`${styles.brInput}`}
                    classes={{ select: !props.flightStatus.airline ? styles.colorPlaceholder : '' }}
                  >
                    <MenuItem value='placeholder' disabled>Select an Airline</MenuItem>
                    <MenuItem value=''>All</MenuItem>
                    {props.airlines.map((airline, i) => (
                      <MenuItem key={i} value={airline.code}>
                        {airline.code}
                      </MenuItem>
                    ))}
                  </CustomSelectRounded>
                  <FormHelperText>{''}</FormHelperText>
                </FormControl>
              </Stack>
              <Stack direction='row' alignItems='center' justifyContent={'flex-start'}  flexWrap='wrap'>
                <CustomInputLabel id='contract_type-label' aria-label='Select Department' className={styles.mr2}>Department</CustomInputLabel>
                <FormControl variant='standard' fullWidth  className={flightStyles.w160}>
                  <CustomSelectRounded
                    {...SelectInputProps}
                    fullWidth
                    id='contract_type'
                    labelId='contract_type'
                    name='contract_type'
                    variant='outlined'
                    onChange={(v) => {
                      props.onFilterMain({
                        target: {
                          type: 'contract_type',
                          name: 'contract_type',
                          value: v ? v.target.value : null
                        }
                      });
                    }}
                    value={props.searchFields.contract_type}
                    className={`${styles.brInput}`}
                    classes={{ select: !props.searchFields.contract_type ? styles.colorPlaceholder : '' }}
                  >
                    <MenuItem value='placeholder' disabled>Select Department</MenuItem>
                    {
                      props.contractTypes.map((contractType, index) => (
                        <MenuItem key={index} value={contractType}>{contractType}</MenuItem>
                      ))
                    }
                  </CustomSelectRounded>
                </FormControl>
              </Stack>
            </Stack>

          </Grid>
        </Grid>
        <StyledCard className={flightStyles.flightFolderWrapper} sx={props.showTableView === 'folder' ? { overflow: 'auto' } : {}}>
          <div className={flightStyles.statusWrapper}>
            <RadioGroup
              aria-labelledby='folder-view-radio-buttons-group'
              name='folder-view-radio-buttons-group'
              value={props.showTableView}
              row
              onChange={props.handleToggle}
              className={`${styles.radioToggleGroup} ${flightStyles.flightSwitch}`} 
            >
              <CustomRadio
                value={'folder'}
                control={<Radio className={styles.radioToggleBtn} />}
                label={'Folder View'}
                className={`${styles.radioToggle} ${props.showTableView === 'folder' ? styles.radioToggleBtnChecked : ''}`}
                classes={{ label: styles.radioToggleLabel }}
              />
              <CustomRadio
                value={'table'}
                control={<Radio className={styles.radioToggleBtn} />}
                label={'Table View'}
                className={`${styles.radioToggle} ${props.showTableView === 'table' ? styles.radioToggleBtnChecked : ''}`}                
                classes={{ label: styles.radioToggleLabel }}
              />    
            </RadioGroup>    
          </div>

          <Grid container flexWrap={'wrap'} rowSpacing={12} columnSpacing={2} justifyContent={{
            md: 'flex-start',
            sm: 'center',
          }}>
            {props.showTableView === 'table' ?
              <Grid item xs={24} >
                <div className={styles.tableWrapper}>
                  <Box className={`${styles.tableOuter} ${flightStyles.flightTableOuter}`}>
                    <StyledDataGrid
                      {...DataGridProps}
                      rows={props.flightList}
                      columns={columns}
                      disableRowSelectionOnClick={false}
                      isRowSelectable={(params) => props.rowSelected(params)}
                      getSelectedRows={(params) => console.log(params, 'getSelectedRows')}
                      param={props.flightList?.length}
                    />
                  </Box>
                </div>
              </Grid>
              :
              props.flightList.map((flight) => {
                let airline = allData(flight?.airline?.code);
                let report = flight?.reports && flight?.reports?.find((report) => report.type === props.searchFields.contract_type);
                let manifest = flight?.org == station ? flight?.out_ffm : flight?.in_ffm;
                let data = {
                  report: report,
                  manifest: manifest,
                  type: props.searchFields.contract_type,
                };

                return (
                  <Grid key={flight.id} item xs={12} sm={6} md={4} lg={3}>
                    <div className={`${flightStyles.folderContainer}`}>
                      <div className={flightStyles.iconWrapperOuter}>
                        {!flight.flight_schedule_id ?
                          <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} className={flightStyles.iconWrapper}>
                            <EditIcon onClick={() => { props.handleOpen('adhoc_flight', 'edit', flight); }} />
                            <DeleteIcon onClick={(e) => props.handleDelete(flight.id, e.target)} />
                          </Stack>
                          : <></>}
                      </div>
                      <Card className={flightStyles.folderWrapper} as={Link} to={('/flight_folder/notes?id=' + flight?.id + '&org=' + flight.org + '&dest=' + flight.dest + '&ctype=' + props.searchFields.contract_type )}>
                        <div className={flightStyles.folderNameWrapper}>
                          {
                            // props?.searchFields?.contract_type &&
                            <>
                              <Button
                                onMouseEnter={(e) => props.handlePopover(e.currentTarget, data)}
                                onMouseLeave={props.handlePopoverClose} 
                                disableRipple
                                disableFocusRipple
                                fullWidth
                                disableTouchRipple
                                className={`${styles.positionRelative} ${styles.p0}`}
                                sx={{ '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' } }}
                              >
                                <>
                                  <img src={FolderTop} alt='flight' className={`${statusColor(report, 'className')} ${styles.w100} ${flightStyles.forderTopImg}`} />
                                  <div className={flightStyles.folderName}>
                                    {flight?.airline?.code + flight?.flight_no}/{flight?.flight_date ? moment(flight?.flight_date).format(DateFormat).toUpperCase() : ''}
                                  </div>
                                </>
                              </Button>
                              {flight?.notes?.length > 0 && <span className={flightStyles.folderBadgeGreen}>{flight?.notes?.length}</span>}
                            </>
                          } 
                        </div> 
                        <CardContent className={flightStyles.cardContent}>
                          <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} spacing={1} className={`${flightStyles.folderNameSub}`}>
                            {flight.adhoc ? <Typography component={'span'} color={'primary'} lineHeight='normal' className={flightStyles.adhocFlight}>adhoc</Typography>: <></> }
                            <span>{flight.dest === station ? 'ARR' : 'DEP'}</span></Stack>
                          <Stack spacing={'0.9rem'}>
                            <div className={`${styles.mb2} ${styles.fSpaceBtw}`}>
                              <div>Status: <span className={flightStyles.flightStatus}><b>{(flight.status)}</b></span></div>
                            </div>
                            <div className={styles.fSpaceBtw}>
                              <span>{flight?.reg_no || '-'}</span>
                              <span>{flight?.ac_type}</span>
                              <span>{flight?.org + '-' + flight?.dest}</span>
                              <Stack direction='row' spacing={1} alignItems='center'>
                                <span className={flightStyles.inlineEl}>{
                                  flight.dest === station ? (flight?.act_arr ? 'A' : (flight?.est_arr ? 'E' : 'S')) :
                                    (flight?.act_dept ? 'A' : (flight?.est_dept ? 'E' : 'S'))
                                }</span>
                                <span>{flight.dest === station ? dateTolocale(flight?.act_arr || flight?.est_arr || flight?.schdl_arr, 'HH:mm') : dateTolocale(flight?.act_dept || flight?.est_dept || flight?.schdl_dept, 'HH:mm')}</span>
                              </Stack>
                            </div>
                            <div className={flightStyles.progressWrapper}><BorderLinearProgress variant='determinate' value={getpercentage(flight?.act_dept || flight?.est_dept || flight?.schdl_dept, flight?.act_arr || flight?.est_arr || flight?.schdl_arr)} className={styles.w100} /> <span><Countdown date={getCountdown(flight?.act_arr || flight?.est_arr || flight?.schdl_arr)} startTime={getCountdown(flight?.act_dept || flight?.est_dept || flight?.schdl_dept)} renderer={renderer} /></span></div>
                            <div className={`${styles.mt0} ${styles.fSpaceBtw}`}>
                              <span>{flight.dest === station ?
                                (moment(flight?.schdl_arr).isSame(flight?.act_arr || flight?.est_arr || flight?.schdl_arr) ? 'On Time' :
                                  (moment(flight?.act_arr || flight?.est_arr).isAfter(flight?.schdl_arr) ? 'Delayed' : 'Early')) :
                                (moment(flight?.schdl_dept).isSame(flight?.act_dept || flight?.est_dept || flight?.schdl_dept) ? 'On Time' :
                                  (moment(flight?.act_dept || flight?.est_dept).isAfter(flight?.schdl_dept) ? 'Delayed' : 'Early'))
                              }</span>
                              <span>Gate - {flight.dest === station ? flight?.arr_gate : flight?.dept_gate}</span>
                              {airline && airline.length > 0 ? (<img src={airline[0].logo} alt='img' height={50} />) : 
                                <div className={flightStyles.noLogo}></div>}
                            </div>
                          </Stack>
                        </CardContent>
                      </Card>
                    </div>
                  </Grid>
                );
              })
            }
          </Grid>
        </StyledCard>

        <AddAdhocFlightModal
          modal={props.modal}
          type={props.action}
          handleOpen={props.handleOpen}
          onClose={props.onClose}
          airlines={props.airlines}
          onChange={props.onChange}
          changeDateTime={props.changeDateTime}
          flightFields={props.flightFields}
          flightErrors={props.flightErrors}
          addFlight={props.addFlight}
          editFlight={props.editFlight}
          locationList={props.locationList}
          scheduleOrgDest={props.scheduleOrgDest}
        />

        <Popper 
          id={'id'} 
          open={Boolean(props.anchorEl)}
          anchorEl={props.anchorEl}
          placement='right-start'
        >
          <Card className={flightStyles.statusPopover}>
            <CardHeader
              className={flightStyles.statusPopoverHeader}
              title={<>
                <Typography variant='h6' color={grey[600]} fontSize='1rem' lineHeight='normal'>
                  {props.reportProps?.type}
                </Typography>
                <Typography variant='caption' color={statusColor(props.reportProps?.report, 'color')} fontWeight={700} lineHeight='normal' marginTop={'4px'} display='block'>
                  {props.reportProps?.report?.status ? (props.reportProps?.report?.status.toLowerCase() === 'submitted' ? 'Report not submitted' : props.reportProps?.report?.status) : 'Not Finalized'}
                </Typography>
              </>
              }>
            </CardHeader>
            <Divider  />
            <CardContent className={styles.p3}>
              {!props.reportProps?.report && (<p className={flightStyles.statusDesc}>
                <span className={flightStyles.statusIndicator}>Report Not Finalized</span>
              </p>)}
              {props.reportProps?.report && (<>
                <p className={flightStyles.statusDesc}> 
                  <span>Accounting</span>
                  <span className={flightStyles.statusIndicator}>{props.reportProps?.report.rejected_acc ? 'Correction Requested' : props.reportProps.report.approved_acc ? 'Reviewed' : 'Pending'}</span> 
                </p>
                <p className={flightStyles.statusDesc}> 
                  <span>Airline Rep</span>
                  <span className={flightStyles.statusIndicator}>{props.reportProps?.report.rejected_air ? 'Correction Requested' : props.reportProps.report.approved_air ? 'Reviewed' : 'Pending'}
                  </span> 
                </p>
              </>)}

              <Divider className={styles.mb2} />
              <p className={flightStyles.statusDesc}> 
                <Typography variant='caption' component='span' color={grey[900]} lineHeight='normal'>
                Manifest Upload
                </Typography>
                {props.reportProps?.manifest ? <TickIcon /> : <Cancel color='error' />}
              </p>

              {props.reportProps?.report?.status.toLowerCase() === 'rejected' && props.reportProps?.report?.log[props.reportProps?.report?.log?.length -1]?.remark.length > 0 &&
                <>
                  <Divider className={styles.mb2} />
                  <Typography variant='caption' component='small' color={grey[900]} lineHeight='normal' fontWeight='bold'>
                    Reason: &nbsp;
                  </Typography>
                  <Typography variant='caption' component='small' color={grey[800]} lineHeight='normal'>
                    {`${props.reportProps?.report?.log[props.reportProps?.report?.log?.length -1]?.remark}`} 
                  </Typography>
                </>}
            </CardContent>
          </Card>
        </Popper>
      </Box>
    </>
  );
};

export default MyFlights;
