import React, { forwardRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ConfirmModalContainer from '../common/confirmModal/ConfirmModalContainer';
import adminStyles from '../admin/tabs/admin.module.css';
import styles from '../../../custom/style.module.css';
import billingStyles from './billing.module.css';
import { Grid, IconButton } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import theme from 'custom/theme';
import { CustomTab } from '../../../custom/components';
import ServiceLogContainer from './tabs/serviceLog/ServiceLogContainer';
// import { contractType } from 'custom/constants';
import FlightLoadInfoContainer from './tabs/flightloadinfo/FlightLoadInfoContainer';
import BillingFilterContainer from '../billingFilters/BillingFilterContainer';

const Billing = forwardRef((props, ref) => {
  const { value, /*contractType,*/ } = props;
  function TabPanel(tabPanelProps) {
    const { children, index, ...other } = tabPanelProps;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabPanelProps.value === index && (
          <Box sx={{ pt: 0 }}>
            <Typography component='span'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(path) {
    const index = path.replace('billing/', '');
    return {
      id: `simple-tab-${index}`,
      value: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Grid container spacing={4} className={billingStyles.billingContainer}>
        <Grid item xs={12}>
          <Tabs value={value} onChange={(_, v) => props.handleChange(v)}
            ref={props.elementRef}
            className={`${styles.customTabs} ${styles.dFlex} ${billingStyles.tabs}`}
            // variant='scrollable'
            variant='fullWidth'
            aria-label='Flight tabs'
            indicatorColor='transparent'
            classes={{ scroller: styles.tabFlexContainer }}
            ScrollButtonComponent={(props) => {
              if (
                props.direction === 'left'
              ) {
                return (
                  <IconButton {...props} className={adminStyles.tabArrows} disableRipple size='small'>
                    <ArrowLeftIcon fontSize='inherit' 
                      sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                    />
                  </IconButton>
                );
              } else if (
                props.direction === 'right'
              ) {
                return (
                  <IconButton {...props} className={adminStyles.tabArrows} disableRipple>
                    <ArrowRightIcon
                      sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                    />
                  </IconButton>
                );
              } else {
                return null;
              }
            }}>
            <CustomTab label={'Flight Load Info'} {...a11yProps('flight_load_info')} />
            <CustomTab label={'Service Logs'} {...a11yProps('service_logs')} />
          </Tabs>
        </Grid>
        <Grid item xs={12} className={billingStyles.billingFilterContainer}>
          <BillingFilterContainer 
            onFilterMain={props.onFilterMain}
            // flightFields={props.flightFields}
            searchFields={props.searchFields}
            airlines={props.airlines}
            handleContractType={props.handleContractType}
            contract_Type={props.contract_Type}
            loadInfo={props.loadInfo}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <RadioGroup
            aria-labelledby='contract-type-radio-buttons-group'
            name='contract-type-radio-buttons-group'
            value={props.contract_Type} 
            row
            onChange={handleContractType}
            className={props.userContractTypes.length > 0 ? `${styles.radioTabContainer} ${styles.radioTabContainerJustifty}`: ''}
          >
            {
              Object.entries(contractType).map(([ key, value],index) => {
                if(props.userContractTypes?.includes(value) ){ 
                  return <CustomRadio key={key} id={index} value={key} control={<Radio className={styles.radioTabBtn} classes={{ checked : styles.radioTabBtnChecked }} />} label={value} className={styles.radioTab} />;
                }
              })
            }
          </RadioGroup>
        </Grid> */}

        <Grid item xs={12}>
          <TabPanel value={value} index={'flight_load_info'}>
            <FlightLoadInfoContainer
              userContractTypes={props.userContractTypes}
              contract_Type={props.contract_Type}
              cont_item={props.cont_item}
              searchFields={props.searchFields}
              airlines={props.airlines}
              handleSubToggle={props.handleSubToggle}
              loadInfo={props.loadInfo}
              airlineCode={props.airlineCode}
              ref={ref}
            />
          </TabPanel>
          <TabPanel value={value} index={'service_logs'}>
            <ServiceLogContainer
              userContractTypes={props.userContractTypes}
              contract_Type={props.contract_Type}
              cont_item={props.cont_item}
              searchFields={props.searchFields}
              airlines={props.airlines}
              airlineCode={props.airlineCode}
              ref={ref}
            />
          </TabPanel>
        </Grid>

      </Grid>
      
      <ConfirmModalContainer />
    </>
  );
});

export default Billing;
