import React from 'react';
import { Document, Page, StyleSheet, View, Text } from '@react-pdf/renderer';
import UldDetailsTable from './UldDetailsTable';
import Footer from './Footer';
import moment from 'moment';

const primaryBlue = '#092b4a';

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 30,
    paddingLeft:40,
    paddingRight:40,
    lineHeight: 1.5,
    flexDirection: 'column',
    paddingBottom: 165,
    paddingHorizontal: 35,
  }, 
  titleContainer:{
    flexDirection: 'row',
    justifyContent: 'space-between', 
    alignItems: 'flex-end',
    margin: '-30px -35px 0 -35px',
    padding: '30px 35px 0',
    color: primaryBlue,
    borderBottom: '1px solid #092b4a',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  date: {
    fontSize: 10,
    color: primaryBlue,
    width: 100,
    textAlign: 'right',
  },
  invoiceLabel: {
    fontSize: 14,
    textTransform: 'uppercase',
    lineHeight: 'normal',
    color: primaryBlue,
    paddingBottom: 5,
    fontFamily: 'NunitoRegular',
  }, 
  subTitle: { fontSize: 10, }, 
});


const MyDocument = (props) => {
  const { uld, flight, accessories } = props;
  return (
    <Document>
      <Page size='A4' style={styles.page} wrap={true}>
        <View style={styles.titleContainer}>
          <Text style={styles.invoiceLabel}>ULD Loading details</Text>
          <View>
            <Text style={styles.subTitle}>ULD Number : {uld.uld_no}</Text>
            <Text style={styles.subTitle}>Flight Number : {flight?.airline?.code}{flight.flight_no}/{moment(flight.flight_date).format('DDMMYY')}</Text>
          </View>
        </View>

        <UldDetailsTable awbs={uld.buildup_awb} />
        <Footer uld={uld} accessories={accessories} />
  
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
  
      </Page>
    </Document>
  );
};

export default MyDocument;
