import React from 'react';
import { StyledCard, StyledCardTitle, StyledDataGrid, DataGridProps, CustomDatePicker, InputProps, CustomRadio, CustomButton, CustomInputLabel, CustomSelectRounded, SelectInputProps } from '../../../../../custom/components';
import approvalStyles from '../../approval.module.css';
import styles from '../../../../../custom/style.module.css';
import { DateFormat, MonthFormat } from 'custom/constants';
import moment from 'moment';
import { Box, Checkbox, FormControl, FormLabel, Grid, IconButton, MenuItem, Radio, RadioGroup, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { OfflinePin } from '@mui/icons-material';
import ReviewModal from '../../../common/ReviewModal';
// import FlightReportModal from '../../../common/FlightReportModal';
import cookies from 'utils/cookies';

const STACKFILTER = {
  direction:{
    xs: 'column',
    lg: 'row',
  }, 
  align:{
    xs: 'flex-start',
    lg: 'center',
  }
};

const ServiceLog = (props) => {

  const columns = [
    {
      field: 'status',
      headerName: <></>,
      width: 50,
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: () => {
        {/* <span className={`${styles.dotLarge} ${styles.approved}`}></span> >> for green dot
           <span className={`${styles.dotLarge} ${styles.rejected}`}></span>  >> for red dot */}
        return <span className={`${styles.dotLarge} ${styles.pending}`}></span>;
      }
    },
    {
      field: 'id',
      headerName: <>
        <Checkbox
          onClick={(e) => props.selectAll(e.target.checked)}
          inputProps={{ 'aria-label': 'Select all' }}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
          className={styles.mr2}
          name='log'
        /></>,
      // minWidth: 100,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => {
        return <>
          <Checkbox
            onClick={(e) => props.selectServiceLog(row.id, e.target.checked)}
            inputProps={{ 'aria-label': 'Select' }}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
            className={styles.mr2}
            checked={props.ids.includes(row.id)}
          />
        </>;
      }
    },
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <>
            {row.type == 'DAY' ? moment(row.date).format(DateFormat).toUpperCase() : moment(row.date).format(MonthFormat).toUpperCase()}
          </>
        );
      }
    },
    ...props.logColumnHead.map((col, index) => ({
      field: `${col}_${index}`,
      headerName: col,
      minWidth: 150,
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (value) => {
        let item = value?.row?.data && value?.row?.data.length > 0 && value?.row?.data?.find((d) => d.label === col);
        return <span>{item?.value}</span>;
      },
      sortComparator: (v1, v2) => {
        const value1 = v1?.props?.children || 0;
        const value2 = v2?.props?.children || 0;
        return parseFloat(value1) - parseFloat(value2); 
      },
      renderCell: ({ row }) => {
        let item = row?.data && row?.data.length > 0 && row.data.find((d) => d.label === col);
        return <span>{item?.value ? item.value : '-'}</span>;
      },
    })),
    //need to generate columns base on contract
    // {
    //   field: 'type1',
    //   headerName: 'Contract Employees (Daily) Units (Hourly) | Manual',
    //   minWidth: 100,
    //   flex: 1,
    // },
    // {
    //   field: 'type2',
    //   headerName: 'Stationary Units (Pcs) | Manual',
    //   minWidth: 100,
    //   flex: 1,
    // },
    // {
    //   field: 'type3',
    //   headerName: 'Test item Day | Manual',
    //   minWidth: 100,
    //   flex: 1,
    // },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   minWidth: 100,
    //   flex: 1,
    //   // renderCell: ({ row }) => {
    //   //   let lastlog = row.log && row.log.length > 0 ? row.log[row.log.length - 1] : null;
    //   //   return (
    //   //     <>
    //   //       {lastlog ? (`${lastlog.status} By ${lastlog.user.role} `) : ''}
    //   //     </>
    //   //   );
    //   // }
    // },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <IconButton onClick={(e) => props.handleReview(e.currentTarget, row )} className={`${styles.p0} ${styles.iconMid}`}> 
            <OfflinePin color='primary' fontSize='20px' />
          </IconButton>
        );
      }
    },
  ];


  return (
    <div>
      <div 
        className={`${styles.wAuto} ${approvalStyles.headerWraper}`}>
        <StyledCard className={`${approvalStyles.filterCard}`}>
          <StyledCardTitle gutterBottom className={`${approvalStyles.filterTitle}`}>Flight Info</StyledCardTitle>
          <Grid container columnSpacing={{
            xs: 4,
            lg: 2,
            xl: '2%'
          }} rowSpacing={{
            xs: 2,
            lg: 0,
          }} justifyContent={{
            xs: 'flex-start',
            xl: 'center',
          }}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Stack direction={{
                xs: 'column',
                lg: 'row',
              }}
              align={{
                xs: 'flex-start',
                lg: 'center',
              }}>
                <CustomInputLabel id='airline-label' aria-label='Enter-airline' className={styles.ipLabel}>Airline</CustomInputLabel>
                <FormControl variant='standard' fullWidth>
                  <CustomSelectRounded
                    {...SelectInputProps}
                    id='airline'
                    labelId='airline-label'
                    fullWidth
                    autoFocus
                    name='airline'
                    className={`${styles.brInput}`}
                    onChange={props.handleAirlineChange}
                    value={!props?.airline ? 'placeholder' : props?.airline}
                  >
                    <MenuItem value='placeholder' disabled>Select an airline</MenuItem>
                    {props.airlines.map((al, i) => (
                      <MenuItem key={i} value={al.id}>
                        {al.code}
                      </MenuItem>
                    ))}
                  </CustomSelectRounded>
                </FormControl>
              </Stack>
            </Grid>
            {props.loadInfo !== 'discrepancy' ? 
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Stack {...STACKFILTER}>
                  <CustomInputLabel id='contract_label' aria-label='Enter-contract' className={styles.ipLabel}>Department</CustomInputLabel>
                  <FormControl variant='standard' fullWidth>
                    <CustomSelectRounded
                      {...SelectInputProps}
                      id='airline'
                      labelId='contract-label'
                      fullWidth
                      autoFocus
                      className={`${styles.brInput}`}
                      onChange={props.handleContractType}
                      value={props.contract_Type} 
                    >
                      <MenuItem value='placeholder' disabled>Select a Department</MenuItem>
                      {cookies.get('contract_types')?.map((type, index) => (
                        <MenuItem key={index} value={type}>{type}</MenuItem>
                      ))}
                    </CustomSelectRounded>
                  </FormControl>
                </Stack>
              </Grid> : ''
            }

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Stack {...STACKFILTER}>
                <CustomInputLabel htmlFor='' aria-label='Enter month' className={`${styles.ipLabel}`}>Month</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      placeholder: 'Enter Month',
                      fullWidth: true
                    },
                  }}
                  views={['month', 'year']}
                  className={`${styles.brInput} ${styles.w100} ${styles.minWidth125}`}
                  openTo='month'
                  onChange={e =>
                    props.onChangeSearch({
                      target: {
                        name: 'month',
                        value: moment(e.$d).format('YYYY-MM-DD')
                      }
                    })
                  }
                  value={ props.search.from ? dayjs(props.search.from) : null }
                />
              </Stack>
            </Grid>
            {
              cookies.get('role') === 'System Admin' &&
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <Stack {...STACKFILTER}>
                    <CustomInputLabel htmlFor='' aria-label='Enter period' className={`${styles.ipLabel}`}>Role</CustomInputLabel>
                    <FormControl variant='standard' fullWidth>
                      <CustomSelectRounded
                        {...SelectInputProps}
                        id='role'
                        labelId='role-label'
                        fullWidth
                        name = 'role'
                        value = {props.role}
                        onChange = {props.handleRoleChange}
                      >
                        <MenuItem value='placeholder' disabled>Select Role</MenuItem>
                        <MenuItem value='accountant'>Filter by Accountant</MenuItem>
                        <MenuItem value='air_rep'>Filter by Airline Rep</MenuItem>
                      </CustomSelectRounded>
                    </FormControl>
                  </Stack>
                </Grid>
            }
          </Grid>
        </StyledCard>
      </div>

      <StyledCard  className={styles.mb4}>
        <Stack direction={'row'} spacing={5} alignItems='center' justifyContent={'space-between'} marginBottom={2}>
          <StyledCardTitle className={styles.pb0}>Service Log</StyledCardTitle>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <FormLabel component='small' className={`${styles.xsW100} ${styles.pr2}`}><small>Filter:</small> </FormLabel>
            <RadioGroup aria-labelledby='log-status' name='log' row className={`${styles.pr5}`}
              value={props.logType}
              onChange={(e) => props.handleLogTypeChange(e)}
            >
              <Stack direction='row' spacing={3} className={`${styles.fMiddle}`} flexWrap='wrap' sx={{ marginLeft: '-10px' }}>
                <CustomRadio control={<Radio size='small' />} value='DAY' label='Daily Service Log' />
                <CustomRadio control={<Radio size='small' />} value='MONTH' label='Monthly Service Log' />
              </Stack>
            </RadioGroup>
          </Stack>
        </Stack>

        <div className={styles.tableWrapper}>
          <Box className={`${styles.tableOuter} ${approvalStyles.logReportTableOuter}`}>
            <StyledDataGrid
              {...DataGridProps}
              rows={props.list}
              columns={columns}
              classes={{ columnHeaderTitle: styles.colEllipse }}
              param={props.list?.length}
            />
          </Box>
        </div>
      </StyledCard>

      <div>
        <CustomButton variant='contained' className={`${styles.mlauto} ${styles.dFlex} ${styles.w100} ${styles.maxWidth200}`}
          onClick={(e) => props.handleReview(e.currentTarget, null)}
          name='approve'>Bulk Review</CustomButton> 
      </div>

      <ReviewModal anchorEl={props.anchorEl} handleReview={props.handleReview} 
        modalflight={props.modalslog} 
        submitFunc={props.UpdateServiceLog} 
        remark={props.remark} 
        setRemark={props.setRemark}
        selectedOption={props.selectedOption}
        selectOption={props.selectOption}
        handleClose={props.handleReviewClose}
      />
      {/* <FlightReportModal 
        showModal={props.modal} closeModal={props.onClose} iframeRef={props.iframeRef}
        handleModalOpen={props.handleModalOpen}
        modalOpen={props.modalOpen}
        handleModalClose={props.handleModalClose}
        flight={props.flightReportData}
      /> */}
    </div>
  );
};

export default ServiceLog;