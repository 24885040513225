import React from 'react';
// import ReactDOM from 'react-dom';
// import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import styles from '../../../../custom/style.module.css';
import MyDocument from './Doc';
import { BlobProvider, Font, PDFDownloadLink } from '@react-pdf/renderer';
import { CustomButton } from 'custom/components';
import { DownloadIcon } from 'custom/constants';
import NunitoRegular from '../../../../assets/fonts/Nunito-Regular.ttf';
import NunitoMedium from '../../../../assets/fonts/Nunito-Medium.ttf';

Font.register({
  family: 'NunitoRegular',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 400,
    },
  ]
});
Font.register({
  family: 'NunitoMedium',
  fontStyle: 'normal',
  fontWeight: 600,
  fonts: [
    {
      src: NunitoMedium,
      fontWeight: 600,
    },
  ]
});

const Download = (props) => {
  return (
    <>
      <PDFDownloadLink document={<MyDocument
        {...props}
      />} fileName={ props.filename || 'invoice.pdf'}
      className={styles.linkNoUnderline}>
        
        <BlobProvider document={<MyDocument
          {...props}
        />}>
          {({ blob, url, loading, error }) => {
            console.log(blob, url, loading, error);
            return <div>{
              <><CustomButton variant='contained' size='small' className={`${styles.dBlock} ${styles.mr2}`} disabled={loading}>
                <DownloadIcon className={`${styles.whiteSvg} ${styles.mr1} ${styles.btnIcons}`} /> {props.title}</CustomButton></>
            }</div>;
          }}
        </BlobProvider>
        
      </PDFDownloadLink>
    </>
  );
};

export default Download;