import React from 'react';
import Developer from './Developer';
import http from 'utils/http';
import cookies from 'utils/cookies';
import moment from 'moment';
import dayjs from 'dayjs';

class DeveloperContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configFields: {},
      config: [],
      stations: [],
      userList: [],
      progress: {},
      search: { date: moment().subtract(1, 'days').format('YYYY-MM-DD'), },
    };
  }
  importKey = 'twoH6t19pjjr38j5FMa9AE6tF26lfd64';

  componentDidMount() {
    // Add the necessary logic here
    this.getConfig();
    this.getStation();
    this.fetchUsers();
  }

  getConfig = () => {
    http.get('/settings').then(({ data }) => {
      this.setState({ config: data.data });
    }
    );
  };

  getStation = () => {
    http.get('/station/all').then(({ data }) => {
      let stations = data.data?.map((s) => s.code);
      stations = stations.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      this.setState({ stations });
    }
    );
  };

  fetchUsers = () => {
    http.get('/user').then((response) => {
      if (response.data.status === 'success') {
        let userList = response.data.data.filter((user) => user.ismanagec === true);
        this.setState({ userList });
      }
    });
  };

  onChange = (event) => {
    const { name, checked } = event.target;
    http.put('/setting/' + name, { value: checked });
  };

  onChangeStation = (event) => {
    const { name, checked } = event.target;
    http.post('/setting/flight_api', {
      name,
      value: checked
    });
  };

  importAirlines = () => {
    this.setState({ progress: { airlines: 'loading' } });
    http.get('/import/airlines?company=tristar&station=' + cookies.get('station_code'), { headers: { 'importkey': this.importKey } }).then(() => {
      this.setState({ progress: { airlines: 'success' } });
    });
  };

  //products
  importProducts = () => {
    this.setState({ progress: { products: 'loading' } });
    http.get('/import/products?company=tristar&station=' + cookies.get('station_code'), { headers: { 'importkey': this.importKey } }).then(() => {
      this.setState({ progress: { products: 'success' } });
    });
  };

  //checklists
  importChecklists = () => {
    this.setState({ progress: { checklists: 'loading' } });
    http.get('/import/checklists?company=tristar&station=' + cookies.get('station_code'), { headers: { 'importkey': this.importKey } }).then(() => {
      this.setState({ progress: { checklists: 'success' } });
    });
  };

  // contracts
  importContracts = () => {
    this.setState({ progress: { contracts: 'loading' } });
    http.get('/import/contracts?company=tristar&station=' + cookies.get('station_code'), { headers: { 'importkey': this.importKey } }).then(() => {
      this.setState({ progress: { contracts: 'success' } });
    });
  };

  //flights
  importFlights = () => {
    if (!this.state.search.date) {
      return;
    }
    this.setState({ progress: { flights: 'loading' } });
    http.get('/import/flights?company=tristar&station=' + cookies.get('station_code')+ '&date=' + dayjs(this.state.search.date).format('YYYY-MM-DD'), { headers: { 'importkey': this.importKey } }).then(() => {
      this.setState({ progress: { flights: 'success' } });
    });
  };


  handleChange = (event, type) => {
    let value = event.target ? event.target.value : event;
    // const name = event.target ? event.target.name : event;

    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [type]: value
      }
    }));
  };

  render() {
    return (
      <Developer
        config={this.state.config}
        onChange={this.onChange}
        stations={this.state.stations}
        onChangeStation={this.onChangeStation}
        userList={this.state.userList}
        importAirlines={this.importAirlines}
        importProducts={this.importProducts}
        importChecklists={this.importChecklists}
        importContracts={this.importContracts}
        handleChange={this.handleChange}
        importFlights={this.importFlights}
        searchOptions={this.state.search}
        progress={this.state.progress}
      />
    );
  }
}

export default DeveloperContainer;