import { grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#092b4a',
      contrastText: '#fff',
    },
    secondary: {
      main: '#59b362',
      contrastText: '#fff',
    },
    error: { main: red['700'], },
    disabled: {
      dark: grey,
      // light: lightGreen
    },
    common: {
      black: 'rgba(0, 0, 0, 0.85)',
      white: '#fff',
    },
  },
  typography: { fontFamily: 'Nunito, sans-serif', },
});

export default theme;
