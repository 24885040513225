import React from 'react';
import { Box, Dialog, Grid, Stack, Typography } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import buildupStyles from './buildup.module.css';
import { CustomButton, CustomDatePicker, CustomDialogContent, CustomDialogTitle, CustomInputLabel, CustomTextField, DataGridProps, InputProps, StyledCard, StyledCardTitle, StyledDataGrid } from 'custom/components';
import { ClearXIcon, CloseIconBtn, DeleteIcon, EditIcon, SaveTickIcon } from 'custom/constants';
import moment from 'moment';
import dayjs from 'dayjs';

const AwbDetailModal = (props) => {

  const awbDetailsColumns = [
    {
      field: 'uld_no',
      headerName: 'ULD#',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.buildup_uld?.uld_no;
      }
    },
    {
      field: 'pcs',
      headerName: 'Pcs',
      align: 'center',
      headerAlign: 'center',
      minWidth: 60,
      flex: 1,
      renderCell: ({ row }) => {
        return props.editLoadAwb == row.id ? (
          <CustomTextField
            {...InputProps}
            variant='outlined'
            id='pcs'
            fullWidth
            value={row.pcs}
            name='pcs'
            onChange={props.onChangeLoadAwb}
          />
        ) : (
          row.pcs
        );
      }
    },
    {
      field: 'wgt',
      headerName: 'Wgt',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return props.editLoadAwb == row.id ? (
          <CustomTextField
            {...InputProps}
            variant='outlined'
            id='wgt'
            fullWidth
            value={row.wgt}
            name='wgt'
            onChange={props.onChangeLoadAwb}
          />
        ) : (
          row.wgt
        );
      }
    },

    {
      field: 'flight_no',
      headerName: 'Flight#',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.flight?.airline?.code + row?.flight?.flight_no + '/' + moment(row?.flight?.flight_date).format('DDMMMYY');
      }
    },
    {
      field: 'remark',
      headerName: 'Remarks',
      minWidth: 100,
      flex: 1,
    },

    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        let disabled = false;
        if(row.flight && row.flight?.schdl_dept && moment(row.flight?.schdl_dept).isBefore(moment().utc())) {
          disabled = true;
        }
        return props.editLoadAwb == row.id ?
          <Stack direction='row' spacing={1} justifyContent='center' className={styles.actionIcons}>
            <SaveTickIcon onClick={() => props.onSaveLoadAwb()} />
            <ClearXIcon onClick={() => props.onLoadEditAwb(false)} />
          </Stack>
          :
          <Stack direction='row' spacing={1} justifyContent='center' className={styles.actionIcons}>
            <EditIcon disabled={disabled} onClick={() => props.onLoadEditAwb(row.id)} />
            <DeleteIcon disabled={disabled} onClick={(e) => props.handleDelete('load_awb', row.id, e.target)} />
          </Stack >;
      }
    },
  ];

  let awbDetails = [];
  for (let i = 0; i < 5; i++) {
    awbDetails.push({
      id: i,
      uld_no: 'PMC71867LH',
      pcs: '4PCS',
      wgt: '400.0K',
      flight_no: 'LH461/21JUL24',
      remarks: 'Remark',
      action: '',
    });
  }

  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal == 'awb_details'} onClose={props.onClose} maxWidth='md' fullWidth>
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          Airwaybill Details
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <CustomDialogContent>

          <Grid container spacing={3}>
            <Grid item container rowSpacing={3}>
              {!props.searchAwb?.id && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='body2' fontWeight={500} color={'white'} textAlign={'center'} bgcolor={'var(--redText)'} p={0.4} mx={'-24px'} >Awb not found. Please add a awb manually.</Typography>
              </Grid>}
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Typography variant='body1' fontWeight={700} color={'primary'}>{props.searchAwb?.code}-{props.searchAwb?.number}</Typography>
                {/* textfield for - awb not found */}
                {/* <>
                  <CustomInputLabel htmlFor='awb' aria-label='Enter pieces'>Awb#</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    variant='outlined'
                    id='awb'
                    fullWidth
                    value={''}
                  />
                </> */}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                {!props.searchAwb.edit && <Typography variant='body1' fontWeight={700} color={'primary'}>{props.searchAwb?.pcs} PCS</Typography>}
                {props.searchAwb.edit &&
                  <>
                    <CustomInputLabel htmlFor='pcs' aria-label='Enter pieces'>Pcs</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      variant='outlined'
                      id='pcs'
                      fullWidth
                      name='pcs'
                      value={props.searchAwb?.pcs}
                      onChange={props.onChnageSearchAwb}
                    />
                  </>}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                {!props.searchAwb.edit && <Typography variant='body1' fontWeight={700} color={'primary'}>{props.searchAwb?.wgt} KG</Typography>}
                {props.searchAwb.edit &&
                  <>
                    <CustomInputLabel htmlFor='wgt' aria-label='Enter weight'>Wgt</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      variant='outlined'
                      id='wgt'
                      fullWidth
                      name='wgt'
                      value={props.searchAwb?.wgt}
                      onChange={props.onChnageSearchAwb}
                    />
                  </>}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                {!props.searchAwb.edit && <Typography variant='body1' fontWeight={700} color={'primary'}>{props.searchAwb?.org}-{props.searchAwb?.dest}</Typography>}
                {props.searchAwb.edit &&
                  <>
                    <CustomInputLabel htmlFor='org' aria-label='Enter org'>Org</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      variant='outlined'
                      id='org'
                      fullWidth
                      name='org'
                      value={props.searchAwb?.org}
                      onChange={props.onChnageSearchAwb}
                    />
                  </>}
                {props.searchAwb.edit &&
                  <>
                    <CustomInputLabel htmlFor='dest' aria-label='Enter dest'>Dest</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      variant='outlined'
                      id='dest'
                      fullWidth
                      name='dest'
                      value={props.searchAwb?.dest}
                      onChange={props.onChnageSearchAwb}
                    />
                  </>}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                {!props.searchAwb.edit && <Typography variant='body1' fontWeight={700} color={'primary'}>{props.searchAwb?.flight_no}/{moment(props.searchAwb?.flight_date).format('DDMMMYY')?.toUpperCase()}</Typography>}
                {props.searchAwb.edit &&
                  <>
                    <CustomInputLabel htmlFor='flight_no' aria-label='Enter flight_no'>Flight No</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      variant='outlined'
                      id='flight_no'
                      fullWidth
                      name='flight_no'
                      value={props.searchAwb?.flight_no}
                      onChange={props.onChnageSearchAwb}
                    />
                  </>}
                {props.searchAwb.edit &&
                  <>
                    <CustomInputLabel htmlFor='flight_date' aria-label='Enter flight_date'>Flight Date</CustomInputLabel>
                    <CustomDatePicker
                      {...InputProps}
                      variant='outlined'
                      id='flight_date'
                      fullWidth
                      name='flight_date'
                      format='DD MMM YYYY'
                      value={dayjs(moment(props.searchAwb?.flight_date).format('YYYY-MM-DD'))}
                      onChange={(v) => props.onChnageSearchAwb({
                        target: {
                          name: 'flight_date',
                          value: moment(v.$d).format('YYYY-MM-DD')
                        }
                      })}
                    />
                  </>}
              </Grid>
              {/* textfield for - awb not found */}
              <Grid item xs={1} >
                {!props.searchAwb?.edit && <EditIcon title='Edit Awb' onClick={() => props.onChnageSearchAwb({
                  target: {
                    name: 'edit',
                    value: true
                  }
                })} />}
                {props.searchAwb?.edit && <SaveTickIcon title='Save Awb' onClick={props.onSaveSearchAwb} />}
                {props.searchAwb?.edit && <ClearXIcon title='Cancel Edit' onClick={() => props.onChnageSearchAwb({
                  target: {
                    name: 'edit',
                    value: false
                  }
                })} />}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StyledCard className={styles.h100}>
                <StyledCardTitle component='div'>Loading History</StyledCardTitle>

                <div className={styles.tableWrapper}>
                  <Box className={`${styles.tableOuter} ${buildupStyles.taxTableOuter}`}>
                    <StyledDataGrid
                      {...DataGridProps}
                      rows={props.searchAwb?.buildup_awbs || []}
                      columns={awbDetailsColumns}
                      classes={{
                        root: buildupStyles.dataGridRoot,
                        columnHeaders: buildupStyles.hideDataColumnHeaders,
                      }}
                    // param={props.taxList?.length}
                    />
                  </Box>
                </div>
              </StyledCard>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Stack direction='row' spacing={2}>
                <Typography variant='body1' fontWeight={700} color={'primary'}>Remaining to load:</Typography>
                <Typography variant='body1' fontWeight={700} color={'primary'}>{props.searchAwb?.pcs - props.searchAwb?.buildup_awbs?.reduce((a, b) => a + b.pcs, 0)} PCS</Typography>
                <Typography variant='body1' fontWeight={700} color={'primary'}>{props.searchAwb?.wgt - props.searchAwb?.buildup_awbs?.reduce((a, b) => a + b.wgt, 0)} KG</Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StyledCard className={styles.h100}>
                <StyledCardTitle>Load to PMC12345LH</StyledCardTitle>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <CustomInputLabel htmlFor='pcs' aria-label='Enter pieces'>Pcs</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id='pcs'
                      fullWidth
                      value={props?.awbFields?.pcs || ''}
                      name='pcs'
                      onChange={props.onChangeAwb}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <CustomInputLabel htmlFor='wgt' aria-label='Enter weight'>Wgt (Optional)</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id='wgt'
                      fullWidth
                      value={props?.awbFields?.wgt || ''}
                      name='wgt'
                      onChange={props.onChangeAwb}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={styles.mtauto}>
                    <CustomInputLabel htmlFor='spls' aria-label='Enter remarks'>SPLs</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id='spls'
                      fullWidth
                      value={props?.awbFields?.spl_code || ''}
                      name='spl_code'
                      onChange={props.onChangeAwb}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={10} lg={10} xl={10}>
                    <CustomInputLabel htmlFor='remarks' aria-label='Enter remarks'>Remarks</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id='remark'
                      fullWidth
                      value={props?.awbFields?.remark || ''}
                      name='remark'
                      onChange={props.onChangeAwb}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} lg={2} xl={2} className={styles.mtauto}>
                    <CustomButton variant='contained' color='primary' onClick={props.onLoadAwb}>Load</CustomButton>
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>

          </Grid>

        </CustomDialogContent>

      </Dialog>
    </div>
  );
};
export default AwbDetailModal;