import React, { Fragment } from 'react';
import { StyledCard, StyledCardTitle, StyledBlueCard, StyledDataGrid, DataGridProps, CustomButton } from 'custom/components';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from '@mui/material';
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import styles from '../../../../../custom/style.module.css';
import flightInfoStyles from '../flightloadinfo/fltloadinfo.module.css';
// import    from './fltloadinfo.module.css';
import theme from 'custom/theme';
// import BillingFilterContainer from '../../../billingFilters/BillingFilterContainer';
import { ArrowDropDown } from '@mui/icons-material';
// import { BinoIcon } from 'custom/constants';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Invoice from '../flightloadinfo/invoice/Invoice';
import cookies from 'utils/cookies';
import { ExcelIcon, PDFIcon, DollarRateIcon } from 'custom/constants';
import { CsvToExcel } from 'custom/export';
import ProgressModal from 'components/pages/common/ProgressModal';
import EncryptionContainer from '../../../common/EncryptionContainer';
import Status from 'components/pages/common/Status';
import moment from 'moment';

const timezone = cookies.get('timezone') || null;
const ServiceLog = (props) => {

  const apiRef = React.useRef(null);

  function formatNumber(num) {
    return num % 1 === 0 ? Math.round(num) : num.toFixed(2);
  }
  
  const columns = [
    // {
    //   field: 'description',
    //   headerName: 'Description',
    //   flex: 2,
    // },
    // {
    //   field: 'value',
    //   headerName: 'Value',
    //   flex: 0.5,
    // },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   flex: 1,
    // },
    // {
    //   field: 'approved_by',
    //   headerName: 'Approved By',
    //   flex: 1,
    // },
    // {
    //   field: 'remark',
    //   headerName: 'Remarks',
    //   flex: 1,
    // },
    // {
    //   field: 'action',
    //   flex: 0.5,
    //   headerName: 'Action',
    //   renderCell: () => {
    //     return (
    //       <div className={styles.actionIcons}>
    //         <BinoIcon title='view log history' 
    //           // onClick={() => props.onViewHistory(dataRow.logs) } 
    //         />
    //       </div>
    //     );
    //   }
    // },

    {
      field: 'item',
      headerName: 'Line Item',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'driver',
      headerName: 'Driver',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        let value = row.quantity && typeof row.quantity === 'number' ? formatNumber(Math.round(row.quantity*100)/100) : 0;
        return value === 0 ? 'Nil' : value;
      }
    },
    {
      field: 'source',
      headerName: 'Source',
      minWidth: 100,
      flex: 1,
    },
  ];

  let data = [];
  for (let i = 0; i < 5; i++) {
    data.push({
      id: i + 1,
      // type: 'Type ' + (i + 1),
      // airline: 'Airline ' + (i + 1),
      description: 'Contract Employees (Daily) Day | Auto',
      value: '150',
      status: 'Approved ',
      approved_by: 'FO Rep',
      remark: 'Remarks',
      action: 'Action ',
    });
  }

  const getInvoice_No = (invoice_info, searchFields) => {
    let month = null;
    if (searchFields.period === 'first') {
      month = '-01';
    } else if (searchFields.period === 'second') {
      month = '-02';
    }
    let invoice_no = `${invoice_info?.file_name}`;
    if(month){
      invoice_no = `${invoice_info?.file_name}${month}`;
    }
    return invoice_no;
  };

  const handleExport = async () => {
    const csv = await apiRef.current.getDataAsCsv();
    CsvToExcel(csv, 'ServiceLogs');
  };

  const invoiceDownload = 
  (cookies.get('role') === 'Accounting' || cookies.get('role') === 'System Admin' || cookies.get('role') === 'Manager') ?
    <div className={flightInfoStyles.downloadInvoice}>
      <Invoice
        invoice_no={getInvoice_No(props.invoice_info, props.searchFields)}
        flightdata={[]}
        daylogdata={[]}
        monthlogdata={[]}
        // invoiceData={props.contractData}
        invoiceData={props.alcontractsData}
        dayContractData={props.dayContractData}
        monthContractData={props.monthContractData}

        invoice_info = {props.invoice_info}
        airlineCode={props.airlineCode}
        title='Invoice (Gross)'
        cont_item={props.cont_item}
        searchFields={props.searchFields}
        filename={`${props.airlineCode}-${props.cont_item}-${cookies.get('station_code')}-Invoice`}
        progress={props.progress}
      />
      <Invoice
        invoice_no={getInvoice_No(props.invoice_info, props.searchFields)}
        flightdata={props.flightdata.filter(flight => flight.pending !== true)}
        daylogdata={props.daylogdata.filter(item => item.pending !== true)}
        monthlogdata={props.monthlogdata.filter(item => item.pending !== true)}
        // invoiceData={props.contractData}
        invoiceData={props.alcontractsData}
        dayContractData={props.dayContractData}
        monthContractData={props.monthContractData}
        invoice_info = {props.invoice_info}
        airlineCode={props.airlineCode}
        title='Invoice (Itemized)'
        cont_item={props.cont_item}
        searchFields={props.searchFields}
        filename={`${props.airlineCode}-${props.cont_item}-${cookies.get('station_code')}-Invoice`}
        progress={props.progress}
      />
    </div> : null;

  return <>
    {/* <BillingFilterContainer 
      onFilterMain={props.onFilterMain}
      // flightFields={props.flightFields}
      searchFields={props.searchFields}
      airlines={props.airlines}
      handleContractType={props.handleContractType}
      contract_Type={props.contract_Type}
    /> */}

    <StyledCard>
      <Grid container columnSpacing={1} rowSpacing={{
        xs: 2,
        xl: 0
      }} className={styles.mb3}>
        <Grid item xs={12} order={{ xs: 1 }}>
          <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center' className={`${styles.mb2} ${styles.pb3}`} borderBottom={'1px solid #dfdfdf'} minHeight={'52px'}>
            <StyledCardTitle className={styles.pb0}>Service Logs</StyledCardTitle>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              {
                cookies.get('role') === 'System Admin' &&
                  <>
                    <CustomButton variant='contained' color='primary' size='small' sx={{ py: props.invoice_info?.lastUpdatedDt ? '2px' : '6px' }}
                      className={`${styles.tableBtn}`} onClick={() => props.triggerNewBilling('true')} disabled={cookies.get('role') === 'System Admin' ? false : true} >
                      <Stack direction='column'>
                        <Typography component={'div'} variant='body2' fontSize={'0.85rem'}>Regenerate</Typography>
                        {props.invoice_info?.lastUpdatedDt &&
                        <Typography component={'span'} variant='caption' fontSize={'9px'} color='#c8c8c8' lineHeight='normal'>
                          Last updated { props.invoice_info?.lastUpdatedDt ? moment.utc(props.invoice_info?.lastUpdatedDt).tz(timezone).format('DD MMM YYYY | HH:mm A') : '' }
                        </Typography>}
                      </Stack>
                    </CustomButton>
                  </>
              }
              <ExcelIcon title='Download File' onClick={handleExport} className={styles.tableIcon} /> 
              {
                cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && !cookies.get('managecdt') ?
                  <Box sx={{
                    [`& .${styles.squareIcon}`]: {
                      height: '32px',
                      width: '28px',
                      marginTop: '-1px',
                    },
                  }}>
                    <DollarRateIcon title='Secure Billing Access' 
                      onClick={() => props.handleOpen('encryption', null, null, null)}  /> 
                  </Box>
                  :
                  cookies.get('role') === 'System Admin' || cookies.get('role') === 'Accounting' ?
                    <PDFIcon title='Download Invoice' onClick={(event) => {
                      if (!props.genInvProgress && props.invoice_info) {
                        props.handleOpen('downloadProgress', event);
                      }
                    }} className={`${styles.tableIcon} ${styles.mr2}`} disabled={props.genInvProgress || !props.invoice_info} />
                    : ''}

              {/* <Popover
                open={Boolean(props.anchorEl)}
                anchorEl={props.anchorEl}
                onClose={props.closePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {
                  (cookies.get('role') === 'Accounting' || cookies.get('role') === 'System Admin' || cookies.get('role') === 'Manager') ?
                    <div className={flightInfoStyles.downloadInvoice}>
                      <Invoice
                        invoice_no={getInvoice_No(props.invoice_info, props.searchFields)}
                        flightdata={[]}
                        daylogdata={[]}
                        monthlogdata={[]}
                        // invoiceData={props.contractData}
                        invoiceData={props.alcontractsData}
                        dayContractData={props.dayContractData}
                        monthContractData={props.monthContractData}

                        invoice_info = {props.invoice_info}
                        airlineCode={props.airlineCode}
                        title='Invoice (Gross)'
                        cont_item={props.cont_item}
                        searchFields={props.searchFields}
                        filename={`${props.airlineCode}-${props.cont_item}-${cookies.get('station_code')}-Invoice`}
                      />
                      <Invoice
                        invoice_no={getInvoice_No(props.invoice_info, props.searchFields)}
                        flightdata={props.flightdata.filter(flight => flight.pending !== true)}
                        daylogdata={props.daylogdata.filter(item => item.pending !== true)}
                        monthlogdata={props.monthlogdata.filter(item => item.pending !== true)}
                        // invoiceData={props.contractData}
                        invoiceData={props.alcontractsData}
                        dayContractData={props.dayContractData}
                        monthContractData={props.monthContractData}
                        invoice_info = {props.invoice_info}
                        airlineCode={props.airlineCode}
                        title='Invoice (Itemized)'
                        cont_item={props.cont_item}
                        searchFields={props.searchFields}
                        filename={`${props.airlineCode}-${props.cont_item}-${cookies.get('station_code')}-Invoice`}
                      />
                    </div> : null
                } 
              </Popover>*/}
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={3} className={styles.mb3}>
        <Grid item className={flightInfoStyles.flightInfo} order={{ xs: 1 }}>
          <StyledBlueCard className={flightInfoStyles.fltPaper}>

            <Typography variant='caption' component='p'
              lineHeight='normal' color={theme.palette.common.white} fontSize={'14px'} fontWeight={700} marginBottom={'14px'}>
              Drill Down
            </Typography>
            {
              props.billingData && props.billingData.length > 0 && (props.billingData.map((data, i) => {
                let totalPendingLogs = 0;
                let monthlyPendingLogs = 0;
                let dailyPendingLogs = 0;
                let pendingText = 'Not Finalized';

                monthlyPendingLogs = data?.monthlog.monthlogs.reduce((count, log) => log.pending ? count + 1 : count, 0);
                dailyPendingLogs = data?.daylog.alldaylogs.reduce((count, log) => log.pending ? count + 1 : count, 0);
                totalPendingLogs = monthlyPendingLogs + dailyPendingLogs; 

                return (
                  <Accordion disableGutters className={flightInfoStyles.accordionFlight} key={i} >
                    <AccordionSummary
                      className={props.accord_type === 'airline' && props.accord_id === data.id ?
                        `${flightInfoStyles.selectedFlight} ${flightInfoStyles.accordionSummaryFlight}` :
                        flightInfoStyles.accordionSummaryFlight}
                      classes={{
                        content: flightInfoStyles.accordionContentFlight,
                        expanded: flightInfoStyles.accordionFlightExpanded
                      }}
                      expandIcon={<ArrowDropDown />}
                      aria-controls='panel1-content'
                      id='panel1-header'
                      onClick={() => props.setContTBData(data, 'airline')}
                    >
                      {data.sllabel}
                      <Typography component={'span'} fontSize={'inherit'} color={'error'} fontWeight={'600'}>
                        ({totalPendingLogs + ' ' + pendingText})</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={`${flightInfoStyles.accordionDetailsFlightMain} ${flightInfoStyles.fltDetails}`}>

                      <Accordion expanded={props.expanded === 'monthlog'} onChange={props.handleChange('monthlog')}
                        disableGutters className={flightInfoStyles.accordionFlight}>
                        <AccordionSummary
                          className={props.accord_type === 'monthlog' && (props.accord_id === data?.monthlog?.id) ?
                            `${flightInfoStyles.selectedFlight} ${flightInfoStyles.accordionSummaryFlight}` :
                            flightInfoStyles.accordionSummaryFlight}
                          classes={{
                            content: flightInfoStyles.accordionContentFlight,
                            expanded: flightInfoStyles.accordionFlightExpanded
                          }}
                          onClick={() => props.setContTBData(data.monthlog, 'monthlog')}
                          expandIcon={<ArrowDropDown />}
                          aria-controls='panel1-content'
                          id='panel1-header'
                        >
                          {
                            data?.monthlog?.label
                          }
                          <Typography component={'span'} fontSize={'inherit'} color={'error'} fontWeight={'600'}>
                        ({monthlyPendingLogs + ' ' + pendingText})</Typography>
                        </AccordionSummary>

                        {data?.monthlog?.monthlogs?.map((log, i) => (
                          <AccordionDetails key={i} 
                            className={`${flightInfoStyles.accordionDetailsFlightMain} ${flightInfoStyles.fltDetailsInner}`} 
                            onClick={() => props.setContTBData(log, 'monthlogs')} >
                            <div 
                              className={`${(props.accord_type === 'monthlogs' && props.accord_id === log.id) ? flightInfoStyles.selectedFlight : ''} 
                                ${log.pending ? flightInfoStyles.pending : ''} 
                                ${flightInfoStyles.accordionDetailsFlight} ${styles.positionRelative}`}
                            >
                              <div className={flightInfoStyles.flightLabelWrapper}>
                                {log.label}
                                {log?.status === 'Not Finalized' ?
                                  <Typography component={'p'} variant='caption' fontSize={'0.7rem'} color={'black'} pt={0.1}>({log?.status})</Typography> : 
                                  log?.status === 'Rejected' ?
                                    <Typography component={'p'} variant='caption' fontSize={'0.7rem'} color='error' fontWeight={600}>
                                        (Correction Requested by {`${log?.last_log?.user?.first_name} ${log?.last_log?.user?.last_name}`})</Typography>
                                    : ''
                                }
                              </div>
                            </div>
                          </AccordionDetails>
                        ))}
                      </Accordion>

                      <Accordion expanded={props.expanded === 'daylog'} onChange={props.handleChange('daylog')}
                        disableGutters className={flightInfoStyles.accordionFlight}>
                        <AccordionSummary
                          onClick={() => props.setContTBData(data.daylog, 'daylog')}
                          className={props.accord_type === 'daylog' && (props.accord_id === data?.daylog?.id) ?
                            `${flightInfoStyles.selectedFlight} ${flightInfoStyles.accordionSummaryFlight}` :
                            flightInfoStyles.accordionSummaryFlight}
                          classes={{
                            content: flightInfoStyles.accordionContentFlight,
                            expanded: flightInfoStyles.accordionFlightExpanded
                          }}
                          expandIcon={<ArrowDropDown />}
                          aria-controls='panel1-content'
                          id='panel1-header'>
                          {
                            data?.daylog?.label
                          }
                          <Typography component={'span'} fontSize={'inherit'} color={'error'} fontWeight={'600'}>
                        ({dailyPendingLogs + ' ' + pendingText})</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={`${flightInfoStyles.accordionDetailsFlightMain} ${flightInfoStyles.fltDetailsInner}`}>
                          {data?.daylog?.alldaylogs?.map((log, i) => (
                            <Fragment key={i}>
                              <div
                                onClick={() => props.setContTBData(log, 'daylogs')}
                                className={`${(props.accord_type === 'daylogs' && props.accord_id === log.id) ? flightInfoStyles.selectedFlight : ''} 
                                ${log.pending ? flightInfoStyles.pending : ''} 
                                ${flightInfoStyles.accordionDetailsFlight} ${styles.positionRelative}`}
                              >
                                <div className={flightInfoStyles.flightLabelWrapper}>
                                  {log?.label}
                                  {log?.status === 'Not Finalized' ?
                                    <Typography component={'p'} variant='caption' fontSize={'0.7rem'} color={'black'} pt={0.1}>({log?.status})</Typography> : 
                                    log?.status === 'Rejected' ?
                                      <Typography component={'p'} variant='caption' fontSize={'0.7rem'} color='error' fontWeight={600}>
                                        (Correction Requested by {`${log?.last_log?.user?.first_name} ${log?.last_log?.user?.last_name}`})</Typography>
                                      : ''
                                  }
                                </div>
                              </div>
                            </Fragment>
                          ))}
                        </AccordionDetails>
                        {/* <AccordionDetails className={`${flightInfoStyles.accordionDetailsFlightMain} ${flightInfoStyles.fltDetailsInner}`}>
                          <DemoContainer components={['DateCalendar', 'DateCalendar', 'DateCalendar']}>
                            <DemoItem>
                              <DateCalendar 
                                views={['day']} 
                                className={logStyles.datePicker} 
                                sx={{
                                  '& .MuiDayCalendar-weekDayLabel': {
                                    backgroundColor: 'transparent',
                                    color: 'white' 
                                  }, 
                                }} 
                                slotProps={{
                                  '& .MuiDayCalendar-weekDayLabel': { color: 'white', },
                                  '&.MuiButtonBase-root-MuiPickersDay-root': { backgroundColor: 'transparent', },
                                  day: {
                                    sx: {
                                      fontSize: '0.85rem',
                                      color: 'white',
                                    }
                                  },
                                  calendarHeader: {
                                    sx: {
                                      color: '#fff',
                                      '& .MuiIconButton-edgeEnd': { color: '#fff', },
                                      '& .MuiIconButton-edgeStart': { color: '#fff', },
                                    } 
                                  },
                                  desktopTransition: {
                                    sx: {
                                      '& .MuiDayCalendar-header': { 
                                        color: '#fff',
                                        backgroundColor: 'trnsparent ', 
                                      }
                                    }
                                  },
                                }}
                                onChange={(v) => props.handleDay({
                                  target: {
                                    type: 'day',
                                    name: 'day',
                                    value: moment(v.$d).format('YYYY-MM-DD')
                                  }
                                }, 'day')}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </AccordionDetails> */}
                      </Accordion>

                    </AccordionDetails>
                  </Accordion>
                );
              }))
            }
          </StyledBlueCard>
        </Grid>

        <Grid item xs className={flightInfoStyles.flightsLoadInfo} order={{
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
        }}>
          <div className={styles.tableWrapper}>
            <Box className={`${styles.tableOuter} ${flightInfoStyles.cdtableOuter}`}>
              <StyledDataGrid
                {...DataGridProps}
                rows={props.contractData || []}
                columns={columns}
                apiRef={apiRef}
                param={props.contractData?.length}
              />
            </Box>
          </div>
        </Grid>

      </Grid>
    </StyledCard>

    <ProgressModal 
      handleOpen={props.handleOpen}
      onClose={props.onClose}
      modal={props.modal}
      progress={props.progress}
      invoiceDownload={invoiceDownload}
    />

    <EncryptionContainer
      startTimer={props.startTimer}
      stopInterval={props.stopInterval}
      handleOpen={props.handleOpen}
      modal={props.modal}
      onClose={props.onClose}
    />
    <Status
      statusModal={props.sessionExpired}
      onClose={props.onClose}
      statusMessage= {{
        type: 'warning',
        size: 'md',
        title: 'Session Expired',
        mainMessage:'Session Expired',
        firstMessage:'Please authenticate again to restart the session.',
        secondMessage:''
      }}
    />
    
  </>;

};

export default ServiceLog;