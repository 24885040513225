import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const textColor = '#393939';
const borderColor = 'lightgrey';
const primaryBlue = '#092b4a';

const cellProperties = {
  padding: 2,
  paddingLeft: 4,
  fontSize: 8,
  color: textColor,
  height: '100%',
  whiteSpace: 'break-spaces',
  fontFamily: 'NunitoRegular',

};
const headProperties = {
  paddingRight: 5,
  paddingLeft: 5,
  fontSize: 8,
  color: primaryBlue,
  textTransform: 'uppercase',
  whiteSpace: 'break-spaces',
  fontFamily: 'NunitoMedium',
};

const styles = StyleSheet.create({
  headerContainer: { marginTop: 24, },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: primaryBlue,
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
  },
  title: {
    fontSize: 9,
    textTransform: 'uppercase',
    backgroundColor: '#f0f0f0',
    color: primaryBlue,
    padding: '4px 35px',
    textAlign: 'center',
    marginBottom: 5,
    lineHeight: 'normal',
    fontFamily: 'NunitoMedium',
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 2
  },
  tableRowOuter: {
    flexDirection: 'column',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
  },
  header10: {
    ...headProperties,
    width: '10%',
  },
  cell10: {
    ...cellProperties,
    width: '10%',
  },
  header12: {
    ...headProperties,
    width: '13%',
  },
  cell12: {
    ...cellProperties,
    width: '13%',
  },
  header18: {
    ...headProperties,
    width: '18%',
  },
  cell18: {
    ...cellProperties,
    width: '18%',
  },
  header15: {
    ...headProperties,
    width: '15%',
  },
  cell15: {
    ...cellProperties,
    width: '15%',
  },
  header20: {
    ...headProperties,
    width: '20%',
  },
  cell20: {
    ...cellProperties,
    width: '20%',
  },
  header40: {
    ...headProperties,
    width: '40%',
  },
  cell40: {
    ...cellProperties,
    width: '40%',
  },
  noDataRow: {
    fontSize: 9,
    width: '100%',
    textAlign: 'center',
    padding: 4,
    color: textColor,
    fontFamily: 'NunitoRegular',
  },
  remarks: {
    width: '100%',
    padding: 2,
    paddingTop: 2,
    fontSize: 8,
    color: textColor,
    borderRightColor: borderColor,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTop: '1px dashed lightgrey',
    fontFamily: 'NunitoRegular',
  },
  noBr: { borderRightWidth: 0, }
});


const Discrepancy = (props) => {
  const { discrepancy } = props;

  return (
    <View style={styles.headerContainer}>
      <Text style={styles.title}>Discrepancy</Text>
      <View style={styles.tableContainer}>

        <View style={styles.container}>
          <Text style={styles.header15}>Type</Text>
          <Text style={styles.header15}>ULD</Text>
          <Text style={styles.header10}>ULD Type</Text>
          <Text style={styles.header10}>Handled As</Text>
          <Text style={styles.header18}>Airwaybill</Text>
          <Text style={styles.header10}>Prod</Text>
          <Text style={styles.header12}>Org-Dest</Text>
          <Text style={styles.header10}>Pcs</Text>
          <Text style={styles.header10}>Wgt</Text>
          <Text style={styles.header10}>Dsc Pcs</Text>
          <Text style={styles.header10}>Dsc Wgt</Text>
        </View>

        {discrepancy && discrepancy.length > 0 ? 
          discrepancy?.map( item =>
            <View style={styles.tableRowOuter} key={item.id}>
              <View style={styles.tableRow} >
                <Text style={styles.cell15}>{item.type}</Text>
                <Text style={styles.cell15}>{item.uld_no}</Text>
                <Text style={styles.cell10}>{item.uld_type}</Text>
                <Text style={styles.cell10}>{item.handled_as}</Text>
                <Text style={styles.cell18}>{item.awb_no}</Text>
                <Text style={styles.cell10}>{item.prod_code}</Text>
                <Text style={styles.cell12}>{item.org} - {item.dest}</Text>
                <Text style={styles.cell10}>{item.t_pcs}</Text>
                <Text style={styles.cell10}>{item.t_wgt}</Text>
                <Text style={styles.cell10}>{item.d_pcs}</Text>
                <Text style={[styles.cell10, styles.noBr]}>{item.d_wgt}</Text>
              </View>
              <Text style={styles.remarks}>Remarks: {item?.remark || '-'}</Text>
            </View>

          ) : <View  style={styles.noDataRow}>
            <Text>No Data</Text>
          </View>}

      </View>
    </View>
  );
};
  
export default Discrepancy;