import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import ghaLogo from '../../../../../../assets/images/tristarLogo.png';
import lhLogo from '../../../../../../assets/images/lufthansaCargo.png';
import cookies from 'utils/cookies';

const textColor = '#393939';
const borderColor = 'lightgrey';
const primaryBlue = '#092b4a';

const cellProperties = {
  paddingLeft: 2,
  fontSize: 10,
  color: textColor,
  height: '30px',
  whiteSpace: 'break-spaces',
  fontFamily: 'NunitoRegular',
  border: '1px solid #000',
  borderRight: 'none',
  borderBottom: 'none',
};
const headProperties = {
  paddingRight: 5,
  paddingLeft: 5,
  fontSize: 10,
  color: primaryBlue,
  textTransform: 'uppercase',
  whiteSpace: 'break-spaces',
  fontFamily: 'NunitoMedium',
  textAlign: 'left',
};

const companyName = (company) => {
  switch (company?.toLowerCase()) {
    case 'tristar ground handling services':
      return <Image style={styles.logo} src={ghaLogo} />;
    case 'lufthansa cargo ag':
      return <Image style={styles.logo2} src={lhLogo} />;
    default:
      return '';
  }
};

const styles = StyleSheet.create({
  headerContainer: { marginTop: 24 },
  title: {
    fontSize: 9,
    textTransform: 'uppercase',
    backgroundColor: '#f0f0f0',
    color: primaryBlue,
    padding: '4px 35px',
    textAlign: 'center',
    marginBottom: 5,
    lineHeight: 'normal',
    fontFamily: 'NunitoMedium',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 20,
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: primaryBlue,
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    padding: '4px 2px',
  },
  header20: {
    ...headProperties,
    width: '20%',
  },
  cell20: {
    ...cellProperties,
    width: '20%',
  },
  header30: {
    ...headProperties,
    width: '30%',
  },
  cell30: {
    ...cellProperties,
    width: '30%',
  },
  w50: {
    ...cellProperties,
    width: '50%',
  },
  w33: {
    ...cellProperties,
    width: '33.33%',
  },
  w100: {
    ...cellProperties,
    width: '100%',
  },
  noDataRow: {
    fontSize: 9,
    width: '100%',
    textAlign: 'center',
    padding: 4,
    color: textColor,
    fontFamily: 'NunitoRegular',
  },

  rowContainer:{
    flexDirection: 'row',
    justifyContent: 'space-between', 
    // alignItems: 'flex-end',
    // margin: '-30px -35px 0 -35px',
    // padding: '30px 35px 0',
    // color: primaryBlue,
    // borderBottom: '1px solid #092b4a',
  },

  rowContainerMain:{
    flexDirection: 'row',
    justifyContent: 'space-between', 
    alignItems: 'center',
    padding: '4px',
  },
  logoContainer: {
    width: '50%',
    textAlign: 'left',
  },
  logoImg: {
    width: '80%',
    height: 'auto',
  },
  blockTitle: {
    fontSize: 12,
    color: primaryBlue,
    // fontFamily: 'NunitoRegular',
    textTransform: 'uppercase',
    alignItems: 'center',
  },
  blockTitleBold: {
    fontSize: 14,
    color: primaryBlue,
    // fontFamily: 'NunitoMedium',
    textTransform: 'uppercase',
    alignItems: 'center',
    // backgroundColor: '#d9e1f3',
  },
  bkgTitle: { backgroundColor: '#d9e1f3', },
  bkgTitleStyleBold: {
    backgroundColor: '#d9e1f3',
    fontSize: 13,
    color: primaryBlue,
    // fontFamily: 'NunitoBold',
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  titleStyleRegular: {
    fontSize: 12,
    color: primaryBlue,
    // fontFamily: 'NunitoRegular',
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  titleItalic: {
    fontSize: 8,
    backgroundColor: '#d9e1f3',
    color: primaryBlue,
    // fontFamily: 'NunitoItalic',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px'
  },  
  cellHeight: { height: '20px', },
  smallTitle: {
    fontSize: 8,
    color: primaryBlue,
    // fontFamily: 'NunitoRegular',
    textDecoration: 'underline',
  },
  titleStyleBold: {
    fontSize: 12,
    color: primaryBlue,
    // fontFamily: 'NunitoBold',
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleStyleMedium: {
    fontSize: 12,
    color: primaryBlue,
    // fontFamily: 'NunitoMedium',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  blockSPLTitle: {
    fontSize: 8,
    color: primaryBlue,
    // fontFamily: 'NunitoMedium',
    textTransform: 'uppercase',
    textAlign: 'center',
    borderBottom: '1px solid #092b4a',
    borderRight: '1px solid #092b4a',
    display:'inline-block',
    width: '80px'
  },
  tagContainer: { border: '1px solid #000', },
  mainTitle: {
    fontSize: 16,
    color: primaryBlue,
    // fontFamily: 'NunitoBold',
    textTransform: 'uppercase',
  },
  SPLContainer: { height: '30px', }
});

const UldTagDetails = (props) => {

  return (
    <View style={styles.tagContainer}>
      <View style={styles.rowContainerMain}>
        <View style={styles.logoContainer}>
          <View style={styles.logoImg}>
            {companyName(cookies.get('company_name'))}
          </View>
        </View>
        <Text style={styles.mainTitle}>Unit Load Device</Text>
      </View>
      <View style={styles.rowContainer}>
        <View style={[styles.w50, styles.bkgTitleStyleBold]}>
          <Text style={styles.blockTitleBold}>ID Code</Text>
        </View>
        <View style={styles.w50}>
          <Text style={styles.block}>{props.uld?.uld_no}</Text>
        </View>
      </View>
      <View style={styles.rowContainer}>
        <View style={[styles.w50, styles.titleStyleRegular]}>
          <Text style={styles.blockTitle}>Destination</Text>
        </View>
        <View style={styles.w50}>
          <Text style={styles.block}>{props.uld?.dest}</Text>
        </View>
      </View>
      <View style={styles.rowContainer}>
        <View style={[styles.w50, styles.titleStyleRegular]}>
          <Text style={styles.blockTitle}>Net Weight (kg)</Text>
        </View>
        <View style={styles.w50}>
          <Text style={styles.block}></Text>
        </View>
      </View>
      <View style={styles.rowContainer}>
        <View style={[styles.w50, styles.titleStyleRegular]}>
          <Text style={styles.blockTitle}>Tare Weight (kg)</Text>
        </View>
        <View style={styles.w50}>
          <Text style={styles.block}>{props.uld?.tare_wgt}</Text>
        </View>
      </View>
      <View style={styles.rowContainer}>
        <View style={[styles.w50, styles.bkgTitleStyleBold]}>
          <Text style={styles.blockTitleBold}>Total Weight (kg)</Text>
        </View>
        <View style={styles.w50}>
          <Text style={styles.block}></Text>
        </View>
      </View>
      <View style={styles.rowContainer}>
        <View style={[styles.w50, styles.titleItalic]}>
          <Text style={styles.smallText}>Weight Correctly Established - Signature</Text>
        </View>
        <View style={[styles.w50, styles.cellHeight]}>
          <Text style={styles.block}></Text>
        </View>
      </View>
      
      <View style={styles.rowContainer}>
        <View style={styles.w33}>
          <Text style={[styles.block, styles.smallTitle]}>Loaded at</Text>
        </View>
        <View style={styles.w33}>
          <Text style={[styles.block, styles.smallTitle]}>Flight No.</Text>
        </View>
        <View style={styles.w33}>
          <Text style={[styles.block, styles.smallTitle]}>Position on a/c</Text>
        </View>
      </View>

      <View style={styles.rowContainer}>
        <View style={styles.w33}>
          <Text style={[styles.block, styles.smallTitle]}>1st Transfer at</Text>
        </View>
        <View style={styles.w33}>
          <Text style={[styles.block, styles.smallTitle]}>Flight No.</Text>
        </View>
        <View style={styles.w33}>
          <Text style={[styles.block, styles.smallTitle]}>Position on a/c</Text>
        </View>
      </View>

      <View style={styles.rowContainer}>
        <View style={styles.w33}>
          <Text style={[styles.block, styles.smallTitle]}>2nd Transfer at</Text>
        </View>
        <View style={styles.w33}>
          <Text style={[styles.block, styles.smallTitle]}>Flight No.</Text>
        </View>
        <View style={styles.w33}>
          <Text style={[styles.block, styles.smallTitle]}>Position on a/c</Text>
        </View>
      </View>

      <View style={styles.rowContainer}>
        <View style={[styles.w50, styles.titleItalic]}>
          <Text style={styles.block}>ULD Correctly Built-Up Name & Signature</Text>
        </View>
        <View style={[styles.w50, styles.cellHeight]}>
          <Text style={styles.block}></Text>
        </View>
      </View>

      <View style={styles.rowContainer}>
        <View style={[styles.w33, {
          alignItems:'center',
          justifyContent:'center' 
        }]}>
          <Text style={[styles.block, styles.titleStyleMedium]}>Contents</Text>
        </View>
        <View style={styles.w33}>
          <View style={[styles.rowContainer, { alignItems:'center' }]}>
            <Text style={styles.block}>Cargo</Text>
            {/* <Svg viewBox='0 0 260 80' >
              <Rect
                width='80'
                height='40'
                strokeWidth={1}
                stroke='rgb(57, 57, 57)'
              />
            </Svg> */}
          </View>
        </View>
        <View style={styles.w33}>
          <Text style={styles.block}>Mail</Text>
        </View>
      </View>

      <View style={[styles.w100, styles.SPLContainer]}>
        <Text style={styles.blockSPLTitle}>RMK & SPL</Text>
      </View>
    </View>
    
  );
};
  
export default UldTagDetails;