import React from 'react';
import Buildup from './Buildup';
import http from 'utils/http';
import evemts from 'utils/evemts';
import { connect } from 'react-redux';
import { setDeleteAction } from 'store/actions/deleteAction';
import moment from 'moment';
import { pdf } from '@react-pdf/renderer';
import MyDocument from './uldDetailsPDF/Doc';
import TagDocument from './uldTagPDF/Doc';

class BuildUpContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: '',
      anchorEl: null,
      flightId: null,
      flight: {},
      ulds: [],
      search: {},
      searchAwb: {},
      addUldFields: {},
      history: [],
      editUld: false,
      editUldFields: {},
      flightFields: {},
      awbFields: {},
      accessories: [],
      airlines: [],
      flights: [],
      editLoadAwb: false
    };
    this.eventSource = null;
  }

  componentDidMount() {
    if (this.props.flight && this.props.flight.id) {
      {
        this.setState({
          flightId: this.props.flight.id,
          flight: this.props.flight
        }, () => { this.fetchUld(); this.startEvent(); this.fetchAccessories(); this.fetchAirlioines(); });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.flight.id !== this.props.flight.id && this.props.flight.id) {
      this.eventSource && this.eventSource.close();
      this.setState({
        flightId: this.props.flight.id,
        flight: this.props.flight
      }, () => { this.fetchUld(); this.startEvent(); this.fetchAccessories(); this.fetchAirlioines(); });
    }
  }

  componentWillUnmount() {
    this.eventSource && this.eventSource.close();
  }

  fetchUld = () => {
    http.get(`buildup_uld/${this.state.flightId}`).then((res) => {
      this.setState({ ulds: res.data?.data || [] });
    });
  };

  fetchAccessories = () => {
    http.get(`loading_accessories/${this.state.flight?.airlineId}`).then((res) => {
      this.setState({ accessories: res.data?.data || [] });
    }
    );
  };

  fetchAirlioines = () => {
    http.get('airline?active=true').then((res) => {
      this.setState({ airlines: res.data?.data || [] });
    }
    );
  };

  fetchHistory = () => {
    http.get(`change_log/buildup_uld/${this.state.editUldFields.id}`).then((res) => {
      this.setState({ history: res.data?.data || [] });
    }
    );
  };


  startEvent = () => {
    this.eventSource = evemts('buildup', this.state.flightId);
    this.eventSource.onmessage = (event) => {
      let ulds = [...this.state.ulds || []];
      const msg = JSON.parse(event.data || '{}');
      if (msg.data && msg.data.flightId == this.state.flightId) {
        if (msg.action === 'update') {
          let index = ulds.findIndex(d => d.id == parseInt(msg.data?.id));
          if (index != -1) {
            ulds[index] = {
              ...ulds[index],
              ...msg.data
            };
            this.setState({ ulds: [...ulds] });
            if (msg?.data?.id == this.state.editUldFields.id) {
              this.setActiveUld(ulds[index]);
            }
          }
        }

        if (msg.action === 'accessorie') {
          let index = ulds.findIndex(d => d.id == parseInt(msg.data?.buildup_uldId));
          if (index != -1) {
            let uld_loadingaccessories = ulds[index].uld_loadingaccessories || [];
            let index2 = uld_loadingaccessories.findIndex(d => d.id == parseInt(msg.data?.id));
            if (index2 != -1) {
              uld_loadingaccessories[index2] = {
                ...uld_loadingaccessories[index2],
                ...msg.data
              };
            } else {
              uld_loadingaccessories.push(msg.data);
            }
            ulds[index] = {
              ...ulds[index],
              uld_loadingaccessories: uld_loadingaccessories
            };
            this.setState({ ulds: [...ulds] });
            if (msg?.data?.id == this.state.editUldFields.id) {
              this.setActiveUld(ulds[index]);
            }
          }
        }

        if (msg.action === 'create_awb') {
          let index = ulds.findIndex(d => d.id == parseInt(msg.data?.buildup_uldId));
          if (index != -1) {
            let buildup_awb = [...ulds[index].buildup_awb || []];
            buildup_awb.push(msg.data);
            ulds[index] = {
              ...ulds[index],
              buildup_awb: buildup_awb
            };
            this.setState({ ulds: [...ulds] });
            if (msg?.data?.buildup_uldId == this.state.editUldFields.id) {
              this.setActiveUld(ulds[index]);
            }
          }
        }
        if (msg.action === 'update_awb') {
          let index = ulds.findIndex(d => d.id == parseInt(msg.data?.buildup_uldId));
          if (index != -1) {
            let buildup_awb = [...ulds[index].buildup_awb || []];
            let index2 = buildup_awb.findIndex(d => d.id == parseInt(msg.data?.id));
            if (index2 != -1) {
              buildup_awb[index2] = {
                ...buildup_awb[index2],
                ...msg.data
              };
            }
            ulds[index] = {
              ...ulds[index],
              buildup_awb: buildup_awb
            };
            this.setState({ ulds: [...ulds] });
            if (msg?.data?.buildup_uldId == this.state.editUldFields.id) {
              this.setActiveUld(ulds[index]);
            }
          }
        }
        if (msg.action === 'delete_awb') {
          let index = ulds.findIndex(d => d.id == parseInt(msg.data?.buildup_uldId));
          if (index != -1) {
            let buildup_awb = [...ulds[index].buildup_awb || []];
            let index2 = buildup_awb.findIndex(d => d.id == parseInt(msg.data?.id));
            if (index2 != -1) {
              buildup_awb.splice(index2, 1);
            }
            ulds[index] = {
              ...ulds[index],
              buildup_awb: buildup_awb
            };
            this.setState({ ulds: [...ulds] });
            if (msg?.data?.buildup_uldId == this.state.editUldFields.id) {
              this.setActiveUld(ulds[index]);
            }
          }
        }
        if (msg.action === 'create') {
          this.setState({ ulds: [...ulds, msg.data] });
        }
        if (msg.action === 'delete') {
          let index = ulds.findIndex(d => d.id == parseInt(msg.data?.id));
          if (index != -1) {
            ulds.splice(index, 1);
            this.setState({ ulds: ulds });
          }
        }
      }
    };
  };

  handleOpen = (modal, anchorEl = null) => {
    this.setState({
      modal,
      anchorEl
    }, () => {
      if (modal == 'history') {
        this.fetchHistory();
      }
    }
    );
  };
  handleClose = () => {
    this.setState({
      modal: false,
      anchorEl: null,
      history: [] 
    });
  };

  onChangeAddUld = (e) => {
    this.setState({
      addUldFields: {
        ...this.state.addUldFields,
        [e.target.name]: e.target.value
      }
    });
  };

  onChangeEditUld = (e) => {
    let value = e.target.value;
    if (e.target.type == 'checkbox') {
      value = e.target.checked ? true : false;
    }
    this.setState({
      editUldFields: {
        ...this.state.editUldFields,
        [e.target.name]: value
      }
    }, () => {
      if (e.target.name == 'dangerous' || e.target.name == 'closed') {
        this.onEditUld();
      }
    });
  };

  onedit = (e) => {
    this.setState({ editUld: e });
  };

  onAddUld = () => {
    if (!this.state.addUldFields.uld_no) return;
    http.post('buildup_uld/' + this.state.flightId, { ...this.state.addUldFields }).then(() => {
      this.setState({ addUldFields: {} });
    });
  };

  onEditUld = () => {
    http.put('buildup_uld/' + this.state.editUldFields.id, { ...this.state.editUldFields }).then(() => {
      this.setState({ editUld: false });
    });
  };

  setActiveUld = (uld) => {
    this.setState({
      editUldFields: uld,
      search: {},
      searchAwb: {},
      awbFields: {}
    });
  };

  handleUldPdf = (uld) => {
    pdf(<MyDocument uld={uld} flight={this.state.flight} accessories={this.state.accessories} />, { orientation: 'landscape' }).toBlob().then((blob) => {
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    });
  };

  onTag = () => {
    pdf(<TagDocument uld={this.state.editUldFields} />, { orientation: 'portrait' }).toBlob().then((blob) => {
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    });
  };

  onChangeAccessories = (e) => {
    console.log(e.target, e.target.value);
    if (this.state.editUldFields.id) {
      http.put('buildup_uld/accessories/' + this.state.editUldFields.id, {
        loadingaccessoriesId: e.target.name,
        value: e.target.value
      });
    }
  };


  onChangeSearch = (e) => {
    this.setState({
      search: {
        ...this.state.search,
        [e.target.name]: e.target.value
      }
    });
  };


  onSearch = (a = false) => {
    let awb = typeof a == 'string' ? a : false;
    if ((this.state.search?.awb && this.state.search?.awb?.includes('-')) || awb) {
      http.get(`buildup_awb/search?awb=${awb ? awb : this.state.search.awb}`).then((res) => {
        this.handleOpen('awb_details');
        if (res.data?.data && res.data?.data?.awb) {
          this.setState({
            searchAwb: res.data?.data?.awb || {},
            awbFields: {}
          }, () => {
            let buildup_awbs = [...this.state.searchAwb?.buildup_awbs || []];
            let index = buildup_awbs.findIndex(d => d.buildup_uldId == this.state.editUldFields.id);
            if (index != -1) {
              let data = buildup_awbs[index];
              buildup_awbs.splice(index, 1);
              this.setState({
                awbFields: data,
                searchAwb: {
                  ...this.state.searchAwb,
                  buildup_awbs: buildup_awbs || []
                }
              });
            }
          });
        } else {
          this.setState({
            searchAwb: {
              edit: true,
              awb_no: this.state.search.awb,
              code: this.state.search.awb.split('-')[0],
              number: this.state.search.awb.split('-')[1],
            },
            awbFields: {}
          });
        }
      });
    }
  };

  onChnageSearchAwb = (e) => {
    this.setState({
      searchAwb: {
        ...this.state.searchAwb,
        [e.target.name]: e.target.value
      }
    });
  };

  onSaveSearchAwb = () => {
    if (this.state.searchAwb?.id) {
      http.put('awb/' + this.state.searchAwb?.id, {
        pcs: this.state.searchAwb?.pcs,
        wgt: this.state.searchAwb?.wgt,
        org: this.state.searchAwb?.org,
        dest: this.state.searchAwb?.dest,
        flight_no: this.state.searchAwb?.flight_no,
        flight_date: moment(this.state.searchAwb?.flight_date).format('YYYY-MM-DD')
      }).then(() => {
        this.setState({
          searchAwb: {
            ...this.state.searchAwb,
            edit: false
          }
        });
      });
    } else {
      http.post('awb', {
        code: this.state.searchAwb?.code,
        number: this.state.searchAwb?.number,
        pcs: this.state.searchAwb?.pcs,
        wgt: this.state.searchAwb?.wgt,
        org: this.state.searchAwb?.org,
        dest: this.state.searchAwb?.dest,
        flight_no: this.state.searchAwb?.flight_no,
        flight_date: moment(this.state.searchAwb?.flight_date).format('YYYY-MM-DD')
      }).then(() => {
        this.onSearch(this.state.searchAwb?.code + '-' + this.state.searchAwb?.number);
      });
    }
  };

  onChangeAwb = (e) => {
    let data = {};
    let value = e.target.value;
    if (e.target.name == 'pcs') {
      value = parseInt(value) || 0;
      data.wgt = parseFloat(value * (this.state.searchAwb?.wgt / this.state.searchAwb?.pcs)).toFixed(2).replace(/\.00$/, '');
    }
    this.setState({
      awbFields: {
        ...this.state.awbFields,
        [e.target.name]: value,
        ...data
      }
    });
  };

  onLoadEditAwb = (id) => {
    this.setState({ editLoadAwb: id });
  };

  onChangeLoadAwb = (e) => {
    let data = {};
    let value = e.target.value;
    if (e.target.name == 'pcs') {
      value = parseInt(value) || 0;
      data.wgt = parseFloat(value * (this.state.searchAwb?.wgt / this.state.searchAwb?.pcs)).toFixed(2).replace(/\.00$/, '');
    }
    let searchAwb = this.state.searchAwb;
    let buildup_awbs = [...searchAwb.buildup_awbs || []];
    let index = buildup_awbs.findIndex(d => d.id == this.state.editLoadAwb);
    if (index != -1) {
      buildup_awbs[index] = {
        ...buildup_awbs[index],
        [e.target.name]: value,
        ...data
      };
    }
    this.setState({
      searchAwb: {
        ...searchAwb,
        buildup_awbs: buildup_awbs
      }
    });
  };

  onSaveLoadAwb = () => {
    let searchAwb = this.state.searchAwb;
    let buildup_awbs = [...searchAwb.buildup_awbs || []];
    let index = buildup_awbs.findIndex(d => d.id == this.state.editLoadAwb);
    if (index != -1) {
      http.put('buildup_awb/' + this.state.editLoadAwb, buildup_awbs[index]).then(() => {
        this.setState({ editLoadAwb: false });
      });
    }
  };

  onLoadAwb = () => {
    if (this.state.searchAwb?.pcs) {
      let data = this.state.awbFields;
      data.awb_no = this.state.searchAwb?.code + '-' + this.state.searchAwb?.number;
      data.awbId = this.state.searchAwb?.id;
      data.buildup_uldId = this.state.editUldFields.id;
      data.flightId = this.state.flightId;
      data.org = this.state.searchAwb?.org;
      data.dest = this.state.searchAwb?.dest;
      if (data.id) {
        http.put('buildup_awb/' + data.id, data).then(() => {
          this.setState({
            awbFields: {},
            searchAwb: {},
            modal: false
          });
        });
      } else {
        http.post('buildup_awb', data).then(() => {
          this.setState({
            awbFields: {},
            searchAwb: {},
            modal: false
          });
        });
      }
    }
  };

  deleteUld = (id) => {
    http.delete('buildup_uld/' + id).then(() => {
      this.setState({ editUldFields: {} });
      this.props.setDelete();
    });
  };

  deleteAwb = (id) => {
    http.delete('buildup_awb/' + id).then(() => {
      this.setState({ awbFields: {} });
      this.props.setDelete();
    });
  };

  deleteLoadAwb = (id) => {
    http.delete('buildup_awb/' + id).then(() => {
      this.props.setDelete();
      if (this.state.searchAwb) {
        let index = this.state.searchAwb.buildup_awbs.findIndex(d => d.id == id);
        if (index != -1) {
          let buildup_awbs = [...this.state.searchAwb.buildup_awbs || []];
          buildup_awbs.splice(index, 1);
          this.setState({
            searchAwb: {
              ...this.state.searchAwb,
              buildup_awbs: buildup_awbs
            }
          });
        }
      }
    });
  };

  handleDelete = (type, id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => {
        switch (type) {
          case 'uld':
            this.deleteUld(id);
            break;
          case 'awb':
            this.deleteAwb(id);
            break;
          case 'load_awb':
            this.deleteLoadAwb(id);
            break;
          default:
            break;
        }
      },
    });
  };

  onChangeFlight = (e) => {
    this.setState({
      flightFields: {
        ...this.state.flightFields,
        [e.target.name]: e.target.value
      }
    }, () => {
      if (e.target.name == 'airline') {
        let airlineId = this.state.airlines.find(d => d.code == e.target.value)?.id;
        if (airlineId) {
          this.fetchFlights(airlineId);
        }
      }
    }
    );
  };

  fetchFlights = (airline) => {
    http.get(`buildup/flights?airlineId=${airline}`).then((res) => {
      this.setState({ flights: res.data?.data || [] });
    });
  };

  onSaveFlight = () => {
    console.log(this.state.flightFields);
    if (this.state.flightFields.airline && this.state.flightFields.flight_no && this.state.flightFields.flight_date) {
      let flightId = this.state.flights.find(d => d.flight_no == this.state.flightFields.flight_no && d.flight_date == this.state.flightFields.flight_date)?.id;
      const old_flight = this.state.flight?.airline?.code + this.state.flight?.flight_no + '/' + moment(this.state.flight?.flight_date).format('DDMMYYYY')?.toUpperCase();
      const new_flight = this.state.flightFields.airline + this.state.flightFields.flight_no + '/' + moment(this.state.flightFields.flight_date).format('DDMMYYYY')?.toUpperCase();
      http.put('buildup_uld/move/' + this.state.editUldFields.id, {
        flightId,
        old_flight,
        new_flight
      }).then(() => {
        this.setState({
          flightFields: {},
          flights: [],
          modal: false
        });
      });
    }
  };

  render() {
    return (
      <>
        <Buildup
          editUld={this.state.editUld}
          onedit={this.onedit}
          flight={this.state.flight}
          ulds={this.state.ulds}
          accessories={this.state.accessories}
          addUldFields={this.state.addUldFields}
          editUldFields={this.state.editUldFields}
          search={this.state.search}
          searchAwb={this.state.searchAwb}
          awbFields={this.state.awbFields}
          onChangeAwb={this.onChangeAwb}
          onLoadAwb={this.onLoadAwb}
          onChangeSearch={this.onChangeSearch}
          onSearch={this.onSearch}
          onChangeAddUld={this.onChangeAddUld}
          onChangeEditUld={this.onChangeEditUld}
          onChangeAccessories={this.onChangeAccessories}
          setActiveUld={this.setActiveUld}
          onAddUld={this.onAddUld}
          onEditUld={this.onEditUld}
          handleClose={this.handleClose}
          handleOpen={this.handleOpen}
          modal={this.state.modal}
          anchorEl={this.state.anchorEl}
          handleDelete={this.handleDelete}
          onChnageSearchAwb={this.onChnageSearchAwb}
          onSaveSearchAwb={this.onSaveSearchAwb}
          editLoadAwb={this.state.editLoadAwb}
          onLoadEditAwb={this.onLoadEditAwb}
          onChangeLoadAwb={this.onChangeLoadAwb}
          onSaveLoadAwb={this.onSaveLoadAwb}
          handleUldPdf={this.handleUldPdf}
          airlines={this.state.airlines}
          flights={this.state.flights}
          flightFields={this.state.flightFields}
          onChangeFlight={this.onChangeFlight}
          onSaveFlight={this.onSaveFlight}
          history={this.state.history}
          onTag={this.onTag}
        />
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return { flight: state.flight, };
};
const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)), };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildUpContainer);
