import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { dateTolocale } from 'utils/converter';
import { DateFormat } from 'custom/constants';

const textColor = '#393939';
const borderColor = 'lightgrey';
const primaryBlue = '#092b4a';

const cellProperties = {
  padding: 2,
  paddingLeft: 4,
  fontSize: 8,
  color: textColor,
  height: '100%',
  whiteSpace: 'break-spaces',
  fontFamily: 'NunitoRegular',
};
const headProperties = {
  paddingRight: 5,
  paddingLeft: 5,
  fontSize: 8,
  color: primaryBlue,
  textTransform: 'uppercase',
  whiteSpace: 'break-spaces',
  fontFamily: 'NunitoMedium',
};

const styles = StyleSheet.create({
  headerContainer: { marginTop: 24 },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 20,
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: primaryBlue,
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
  },
  title: {
    fontSize: 9,
    textTransform: 'uppercase',
    backgroundColor: '#f0f0f0',
    color: primaryBlue,
    padding: '4px 35px',
    textAlign: 'center',
    marginBottom: 5,
    lineHeight: 'normal',
    fontFamily: 'NunitoMedium',
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    padding: 2,
  },
  header20: {
    ...headProperties,
    width: '20%',
  },
  cell20: {
    ...cellProperties,
    width: '20%',
  },
  header30: {
    ...headProperties,
    width: '30%',
  },
  cell30: {
    ...cellProperties,
    width: '30%',
  },
  header40: {
    ...headProperties,
    width: '40%',
  },
  cell40: {
    ...cellProperties,
    width: '40%',
  },
  noDataRow: {
    fontSize: 9,
    width: '100%',
    textAlign: 'center',
    padding: 4,
    color: textColor,
    fontFamily: 'NunitoRegular',
  },
  noBorderRight: { borderRight: 'none', },
});

const getPerformedBy = (item) => {
  let length =  item?.flight_checklist?.length - 1;
  let checklist_data = item?.flight_checklist[length]; 
  let user = checklist_data?.user;
  let userDetails = `${user?.first_name} ${user?.last_name} | ${dateTolocale(checklist_data?.created, DateFormat + ' ' + 'HH:mm')?.toUpperCase()}`;
  item.performed_by = user ? userDetails : '';
  return <Text>{user ? userDetails : ''}</Text>;
};

const FLightReport = (props) => {
  const { report, statistics } = props;

  return (
    <>
      <View style={styles.headerContainer}>
        <Text style={styles.title}>Flight Report</Text>
    
        <View style={styles.tableContainer} wrap={true}>
          <View style={styles.container}>
            <Text style={styles.header40}>Line Item</Text>
            <Text style={styles.header30}>Driver</Text>
            <Text style={styles.header30}>Quantity</Text>
          </View>

          {statistics && statistics.length > 0 ?
            statistics?.map( item =>
              <View style={styles.tableRow} key={item.id}>
                <Text style={styles.cell40}>{item.description}</Text>
                <Text style={styles.cell30}>{item.applied_on}</Text>
                <Text style={[styles.cell30, styles.noBorderRight]}>{item.quantity ?( item.applied_on.includes('KG') ? Math.round(item.quantity*100)/100 : item.quantity ): ''}</Text>           
              </View>
            ): <View  style={styles.noDataRow}>
              <Text>No Data</Text>
            </View>}
        </View>

        <View style={styles.tableContainer} wrap={false}>
          <View style={styles.container}>
            <Text style={styles.header30}>Checks</Text>
            <Text style={styles.header20}>Roles</Text>
            <Text style={styles.header20}>Entered By</Text>
            <Text style={styles.header30}>Remarks</Text>
          </View>

          {report && report.length > 0 ?
            report?.map( item =>
              <View style={styles.tableRow} key={item.id}>
                <Text style={styles.cell30}>{item.description}</Text>
                <Text style={styles.cell20}>{item.role}</Text>
                <Text style={styles.cell20}>{getPerformedBy(item)}</Text>
                <Text style={styles.cell30}>{item.remark}</Text>
              </View>
            ): <View style={styles.noDataRow}>
              <Text>No Data</Text>
            </View>}
        </View>

      </View>

    </>
  );
};
  
export default FLightReport;