import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import styles from 'custom/style.module.css';
import commonStyles from './common.module.css';
import { CloseIconBtn } from 'custom/constants';
import { CheckCircle } from '@mui/icons-material';

const Status = (props) => {
  return (
    <div>
      <Dialog
        open={props.statusModal}  
        onClose={props.onClose}
        aria-labelledby='Flight Report'
        aria-describedby='Flight Report'
        maxWidth={props.statusMessage.size}
        fullWidth={true}
      >
        <DialogTitle className={styles.fSpaceBtw} sx={{ borderBottom: '1px solid #f0f0f0' }}>
          <Typography variant='h6' component={'h6'} color={'primary'}>{props.statusMessage.title}</Typography> 
          <CloseIconBtn onClick={() => props.onClose()} />
        </DialogTitle>
        <DialogContent>
          <div className={commonStyles.downloadWrapper}>
            <div className={`${styles.textCenter}`}>
              <>
                <CheckCircle sx={{ fontSize: 80 }} color='secondary' />
                <Typography variant='h6' component={'h6'} color={'primary'} textAlign={'center'} mb={3} mt={3}>
                  {props.statusMessage.mainMessage} 
                </Typography>
              </>
            </div>
            <DialogContentText fontSize={'0.9rem'} textAlign={'center'} mt={3}>
              {props.statusMessage.firstMessage}<br />
              {props.statusMessage.secondMessage}
            </DialogContentText>
          </div>
        </DialogContent>

      </Dialog>
    </div>
  );
};

export default Status;
