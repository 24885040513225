import React from 'react';
import { Document, Page, StyleSheet, Text } from '@react-pdf/renderer';
import UldTagDetails from './uldTagDetails';

const primaryBlue = '#092b4a';

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 30,
    paddingLeft:40,
    paddingRight:40,
    lineHeight: 1.5,
    flexDirection: 'column',
    paddingBottom: 165,
    paddingHorizontal: 35,
  }, 
  titleContainer:{
    flexDirection: 'row',
    justifyContent: 'space-between', 
    alignItems: 'flex-end',
    margin: '-30px -35px 0 -35px',
    padding: '30px 35px 0',
    color: primaryBlue,
    borderBottom: '1px solid #092b4a',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  date: {
    fontSize: 10,
    color: primaryBlue,
    width: 100,
    textAlign: 'right',
  },
  invoiceLabel: {
    fontSize: 14,
    textTransform: 'uppercase',
    lineHeight: 'normal',
    color: primaryBlue,
    paddingBottom: 5,
    // fontFamily: 'NunitoRegular',
  }, 
  subTitle: { fontSize: 10, }, 
});


const TagDocument = (props) => {
  return (
    <Document>
      <Page orientation='portrait'
        size={{
          width: 6*72, //6 inch
          height: 8*72 //8 inch
        }} style={styles.page} wrap={true}>
        <UldTagDetails uld={props.uld} />
  
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
  
      </Page>
    </Document>
  );
};

export default TagDocument;
