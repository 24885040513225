import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Admin from '../assets/images/menuIcons/settings.svg';
import Docs from '../assets/images/menuIcons/docs.svg';
import DocArchive from '../assets/images/menuIcons/docArchive.svg';
// import Reports from '../assets/images/menuIcons/reports.svg';
import Flights from '../assets/images/menuIcons/flights.svg';
import Add from '../assets/images/actionIcons/add.svg';
import Lock from '../assets/images/actionIcons/lock.svg';
import UnLock from '../assets/images/actionIcons/unlock.svg';
import Key from '../assets/images/actionIcons/key.svg';
import Location from '../assets/images/actionIcons/globe.svg';
import Edit from '../assets/images/actionIcons/edit.svg';
import Delete from '../assets/images/actionIcons/delete.svg';
import SaveTick from '../assets/images/actionIcons/tick.svg';
import Tick from '../assets/images/actionIcons/greentick.svg';
import UnTick from '../assets/images/actionIcons/roundeduntick.svg';
import Doc from '../assets/images/actionIcons/doc.svg';
import ViewGreen from '../assets/images/actionIcons/show.svg';
import View from '../assets/images/actionIcons/showBlue.svg';
import ArrowTop from '../assets/images/actionIcons/arrowTop.svg';
import WhiteCamera from '../assets/images/actionIcons/whiteCamera.svg';
import WhiteEdit from '../assets/images/actionIcons/whiteEdit.svg';
import WhiteDoc from '../assets/images/actionIcons/whiteDoc.svg';
import Bell from '../assets/images/actionIcons/bell.svg';
import Page from '../assets/images/actionIcons/page.svg';
import Camera from '../assets/images/actionIcons/camera.svg';
import Eco from '../assets/images/actionIcons/eco.svg';
import StickyNote from '../assets/images/actionIcons/stickyNote.svg';
import Excel from '../assets/images/excel.svg';
import PDF from '../assets/images/pdf.svg';
import Download from '../assets/images/actionIcons/download.svg';
import Upload from '../assets/images/actionIcons/upload.svg';
import Bino from '../assets/images/actionIcons/bino.svg';
import DollarRate from '../assets/images/actionIcons/dollar.svg';
import Exclamation from '../assets/images/exclamation.svg';
import { CloseIconProps } from './components';
import CloseIcon from '@mui/icons-material/Close';
import http from '../utils/http';
import cookies from '../utils/cookies';

import styles from './style.module.css';
import { AttachFile, MoreHoriz, Receipt, Verified, WebStories } from '@mui/icons-material';

export const appName = 'CABA';

// country code reference >> https://gist.github.com/anubhavshrimal/75f6183458db8c453306f93521e93d37
export const countryCode = [
  {
    'name': 'Afghanistan',
    'dial_code': '+93',
    'code': 'AF'
  },
  {
    'name': 'Aland Islands',
    'dial_code': '+358',
    'code': 'AX'
  },
  {
    'name': 'Albania',
    'dial_code': '+355',
    'code': 'AL'
  },
  {
    'name': 'Algeria',
    'dial_code': '+213',
    'code': 'DZ'
  },
  {
    'name': 'AmericanSamoa',
    'dial_code': '+1684',
    'code': 'AS'
  },
  {
    'name': 'Andorra',
    'dial_code': '+376',
    'code': 'AD'
  },
  {
    'name': 'Angola',
    'dial_code': '+244',
    'code': 'AO'
  },
  {
    'name': 'Anguilla',
    'dial_code': '+1264',
    'code': 'AI'
  },
  {
    'name': 'Antarctica',
    'dial_code': '+672',
    'code': 'AQ'
  },
  {
    'name': 'Antigua and Barbuda',
    'dial_code': '+1268',
    'code': 'AG'
  },
  {
    'name': 'Argentina',
    'dial_code': '+54',
    'code': 'AR'
  },
  {
    'name': 'Armenia',
    'dial_code': '+374',
    'code': 'AM'
  },
  {
    'name': 'Aruba',
    'dial_code': '+297',
    'code': 'AW'
  },
  {
    'name': 'Australia',
    'dial_code': '+61',
    'code': 'AU'
  },
  {
    'name': 'Austria',
    'dial_code': '+43',
    'code': 'AT'
  },
  {
    'name': 'Azerbaijan',
    'dial_code': '+994',
    'code': 'AZ'
  },
  {
    'name': 'Bahamas',
    'dial_code': '+1242',
    'code': 'BS'
  },
  {
    'name': 'Bahrain',
    'dial_code': '+973',
    'code': 'BH'
  },
  {
    'name': 'Bangladesh',
    'dial_code': '+880',
    'code': 'BD'
  },
  {
    'name': 'Barbados',
    'dial_code': '+1246',
    'code': 'BB'
  },
  {
    'name': 'Belarus',
    'dial_code': '+375',
    'code': 'BY'
  },
  {
    'name': 'Belgium',
    'dial_code': '+32',
    'code': 'BE'
  },
  {
    'name': 'Belize',
    'dial_code': '+501',
    'code': 'BZ'
  },
  {
    'name': 'Benin',
    'dial_code': '+229',
    'code': 'BJ'
  },
  {
    'name': 'Bermuda',
    'dial_code': '+1441',
    'code': 'BM'
  },
  {
    'name': 'Bhutan',
    'dial_code': '+975',
    'code': 'BT'
  },
  {
    'name': 'Bolivia, Plurinational State of',
    'dial_code': '+591',
    'code': 'BO'
  },
  {
    'name': 'Bosnia and Herzegovina',
    'dial_code': '+387',
    'code': 'BA'
  },
  {
    'name': 'Botswana',
    'dial_code': '+267',
    'code': 'BW'
  },
  {
    'name': 'Brazil',
    'dial_code': '+55',
    'code': 'BR'
  },
  {
    'name': 'British Indian Ocean Territory',
    'dial_code': '+246',
    'code': 'IO'
  },
  {
    'name': 'Brunei Darussalam',
    'dial_code': '+673',
    'code': 'BN'
  },
  {
    'name': 'Bulgaria',
    'dial_code': '+359',
    'code': 'BG'
  },
  {
    'name': 'Burkina Faso',
    'dial_code': '+226',
    'code': 'BF'
  },
  {
    'name': 'Burundi',
    'dial_code': '+257',
    'code': 'BI'
  },
  {
    'name': 'Cambodia',
    'dial_code': '+855',
    'code': 'KH'
  },
  {
    'name': 'Cameroon',
    'dial_code': '+237',
    'code': 'CM'
  },
  {
    'name': 'Canada',
    'dial_code': '+1',
    'code': 'CA'
  },
  {
    'name': 'Cape Verde',
    'dial_code': '+238',
    'code': 'CV'
  },
  {
    'name': 'Cayman Islands',
    'dial_code': '+ 345',
    'code': 'KY'
  },
  {
    'name': 'Central African Republic',
    'dial_code': '+236',
    'code': 'CF'
  },
  {
    'name': 'Chad',
    'dial_code': '+235',
    'code': 'TD'
  },
  {
    'name': 'Chile',
    'dial_code': '+56',
    'code': 'CL'
  },
  {
    'name': 'China',
    'dial_code': '+86',
    'code': 'CN'
  },
  {
    'name': 'Christmas Island',
    'dial_code': '+61',
    'code': 'CX'
  },
  {
    'name': 'Cocos (Keeling) Islands',
    'dial_code': '+61',
    'code': 'CC'
  },
  {
    'name': 'Colombia',
    'dial_code': '+57',
    'code': 'CO'
  },
  {
    'name': 'Comoros',
    'dial_code': '+269',
    'code': 'KM'
  },
  {
    'name': 'Congo',
    'dial_code': '+242',
    'code': 'CG'
  },
  {
    'name': 'Congo, The Democratic Republic of the Congo',
    'dial_code': '+243',
    'code': 'CD'
  },
  {
    'name': 'Cook Islands',
    'dial_code': '+682',
    'code': 'CK'
  },
  {
    'name': 'Costa Rica',
    'dial_code': '+506',
    'code': 'CR'
  },
  {
    'name': 'Cote d\'Ivoire',
    'dial_code': '+225',
    'code': 'CI'
  },
  {
    'name': 'Croatia',
    'dial_code': '+385',
    'code': 'HR'
  },
  {
    'name': 'Cuba',
    'dial_code': '+53',
    'code': 'CU'
  },
  {
    'name': 'Cyprus',
    'dial_code': '+357',
    'code': 'CY'
  },
  {
    'name': 'Czech Republic',
    'dial_code': '+420',
    'code': 'CZ'
  },
  {
    'name': 'Denmark',
    'dial_code': '+45',
    'code': 'DK'
  },
  {
    'name': 'Djibouti',
    'dial_code': '+253',
    'code': 'DJ'
  },
  {
    'name': 'Dominica',
    'dial_code': '+1767',
    'code': 'DM'
  },
  {
    'name': 'Dominican Republic',
    'dial_code': '+1849',
    'code': 'DO'
  },
  {
    'name': 'Ecuador',
    'dial_code': '+593',
    'code': 'EC'
  },
  {
    'name': 'Egypt',
    'dial_code': '+20',
    'code': 'EG'
  },
  {
    'name': 'El Salvador',
    'dial_code': '+503',
    'code': 'SV'
  },
  {
    'name': 'Equatorial Guinea',
    'dial_code': '+240',
    'code': 'GQ'
  },
  {
    'name': 'Eritrea',
    'dial_code': '+291',
    'code': 'ER'
  },
  {
    'name': 'Estonia',
    'dial_code': '+372',
    'code': 'EE'
  },
  {
    'name': 'Ethiopia',
    'dial_code': '+251',
    'code': 'ET'
  },
  {
    'name': 'Falkland Islands (Malvinas)',
    'dial_code': '+500',
    'code': 'FK'
  },
  {
    'name': 'Faroe Islands',
    'dial_code': '+298',
    'code': 'FO'
  },
  {
    'name': 'Fiji',
    'dial_code': '+679',
    'code': 'FJ'
  },
  {
    'name': 'Finland',
    'dial_code': '+358',
    'code': 'FI'
  },
  {
    'name': 'France',
    'dial_code': '+33',
    'code': 'FR'
  },
  {
    'name': 'French Guiana',
    'dial_code': '+594',
    'code': 'GF'
  },
  {
    'name': 'French Polynesia',
    'dial_code': '+689',
    'code': 'PF'
  },
  {
    'name': 'Gabon',
    'dial_code': '+241',
    'code': 'GA'
  },
  {
    'name': 'Gambia',
    'dial_code': '+220',
    'code': 'GM'
  },
  {
    'name': 'Georgia',
    'dial_code': '+995',
    'code': 'GE'
  },
  {
    'name': 'Germany',
    'dial_code': '+49',
    'code': 'DE'
  },
  {
    'name': 'Ghana',
    'dial_code': '+233',
    'code': 'GH'
  },
  {
    'name': 'Gibraltar',
    'dial_code': '+350',
    'code': 'GI'
  },
  {
    'name': 'Greece',
    'dial_code': '+30',
    'code': 'GR'
  },
  {
    'name': 'Greenland',
    'dial_code': '+299',
    'code': 'GL'
  },
  {
    'name': 'Grenada',
    'dial_code': '+1473',
    'code': 'GD'
  },
  {
    'name': 'Guadeloupe',
    'dial_code': '+590',
    'code': 'GP'
  },
  {
    'name': 'Guam',
    'dial_code': '+1671',
    'code': 'GU'
  },
  {
    'name': 'Guatemala',
    'dial_code': '+502',
    'code': 'GT'
  },
  {
    'name': 'Guernsey',
    'dial_code': '+44',
    'code': 'GG'
  },
  {
    'name': 'Guinea',
    'dial_code': '+224',
    'code': 'GN'
  },
  {
    'name': 'Guinea-Bissau',
    'dial_code': '+245',
    'code': 'GW'
  },
  {
    'name': 'Guyana',
    'dial_code': '+595',
    'code': 'GY'
  },
  {
    'name': 'Haiti',
    'dial_code': '+509',
    'code': 'HT'
  },
  {
    'name': 'Holy See (Vatican City State)',
    'dial_code': '+379',
    'code': 'VA'
  },
  {
    'name': 'Honduras',
    'dial_code': '+504',
    'code': 'HN'
  },
  {
    'name': 'Hong Kong',
    'dial_code': '+852',
    'code': 'HK'
  },
  {
    'name': 'Hungary',
    'dial_code': '+36',
    'code': 'HU'
  },
  {
    'name': 'Iceland',
    'dial_code': '+354',
    'code': 'IS'
  },
  {
    'name': 'India',
    'dial_code': '+91',
    'code': 'IN'
  },
  {
    'name': 'Indonesia',
    'dial_code': '+62',
    'code': 'ID'
  },
  {
    'name': 'Iran, Islamic Republic of Persian Gulf',
    'dial_code': '+98',
    'code': 'IR'
  },
  {
    'name': 'Iraq',
    'dial_code': '+964',
    'code': 'IQ'
  },
  {
    'name': 'Ireland',
    'dial_code': '+353',
    'code': 'IE'
  },
  {
    'name': 'Isle of Man',
    'dial_code': '+44',
    'code': 'IM'
  },
  {
    'name': 'Israel',
    'dial_code': '+972',
    'code': 'IL'
  },
  {
    'name': 'Italy',
    'dial_code': '+39',
    'code': 'IT'
  },
  {
    'name': 'Jamaica',
    'dial_code': '+1876',
    'code': 'JM'
  },
  {
    'name': 'Japan',
    'dial_code': '+81',
    'code': 'JP'
  },
  {
    'name': 'Jersey',
    'dial_code': '+44',
    'code': 'JE'
  },
  {
    'name': 'Jordan',
    'dial_code': '+962',
    'code': 'JO'
  },
  {
    'name': 'Kazakhstan',
    'dial_code': '+77',
    'code': 'KZ'
  },
  {
    'name': 'Kenya',
    'dial_code': '+254',
    'code': 'KE'
  },
  {
    'name': 'Kiribati',
    'dial_code': '+686',
    'code': 'KI'
  },
  {
    'name': 'Korea, Democratic People\'s Republic of Korea',
    'dial_code': '+850',
    'code': 'KP'
  },
  {
    'name': 'Korea, Republic of South Korea',
    'dial_code': '+82',
    'code': 'KR'
  },
  {
    'name': 'Kuwait',
    'dial_code': '+965',
    'code': 'KW'
  },
  {
    'name': 'Kyrgyzstan',
    'dial_code': '+996',
    'code': 'KG'
  },
  {
    'name': 'Laos',
    'dial_code': '+856',
    'code': 'LA'
  },
  {
    'name': 'Latvia',
    'dial_code': '+371',
    'code': 'LV'
  },
  {
    'name': 'Lebanon',
    'dial_code': '+961',
    'code': 'LB'
  },
  {
    'name': 'Lesotho',
    'dial_code': '+266',
    'code': 'LS'
  },
  {
    'name': 'Liberia',
    'dial_code': '+231',
    'code': 'LR'
  },
  {
    'name': 'Libyan Arab Jamahiriya',
    'dial_code': '+218',
    'code': 'LY'
  },
  {
    'name': 'Liechtenstein',
    'dial_code': '+423',
    'code': 'LI'
  },
  {
    'name': 'Lithuania',
    'dial_code': '+370',
    'code': 'LT'
  },
  {
    'name': 'Luxembourg',
    'dial_code': '+352',
    'code': 'LU'
  },
  {
    'name': 'Macao',
    'dial_code': '+853',
    'code': 'MO'
  },
  {
    'name': 'Macedonia',
    'dial_code': '+389',
    'code': 'MK'
  },
  {
    'name': 'Madagascar',
    'dial_code': '+261',
    'code': 'MG'
  },
  {
    'name': 'Malawi',
    'dial_code': '+265',
    'code': 'MW'
  },
  {
    'name': 'Malaysia',
    'dial_code': '+60',
    'code': 'MY'
  },
  {
    'name': 'Maldives',
    'dial_code': '+960',
    'code': 'MV'
  },
  {
    'name': 'Mali',
    'dial_code': '+223',
    'code': 'ML'
  },
  {
    'name': 'Malta',
    'dial_code': '+356',
    'code': 'MT'
  },
  {
    'name': 'Marshall Islands',
    'dial_code': '+692',
    'code': 'MH'
  },
  {
    'name': 'Martinique',
    'dial_code': '+596',
    'code': 'MQ'
  },
  {
    'name': 'Mauritania',
    'dial_code': '+222',
    'code': 'MR'
  },
  {
    'name': 'Mauritius',
    'dial_code': '+230',
    'code': 'MU'
  },
  {
    'name': 'Mayotte',
    'dial_code': '+262',
    'code': 'YT'
  },
  {
    'name': 'Mexico',
    'dial_code': '+52',
    'code': 'MX'
  },
  {
    'name': 'Micronesia, Federated States of Micronesia',
    'dial_code': '+691',
    'code': 'FM'
  },
  {
    'name': 'Moldova',
    'dial_code': '+373',
    'code': 'MD'
  },
  {
    'name': 'Monaco',
    'dial_code': '+377',
    'code': 'MC'
  },
  {
    'name': 'Mongolia',
    'dial_code': '+976',
    'code': 'MN'
  },
  {
    'name': 'Montenegro',
    'dial_code': '+382',
    'code': 'ME'
  },
  {
    'name': 'Montserrat',
    'dial_code': '+1664',
    'code': 'MS'
  },
  {
    'name': 'Morocco',
    'dial_code': '+212',
    'code': 'MA'
  },
  {
    'name': 'Mozambique',
    'dial_code': '+258',
    'code': 'MZ'
  },
  {
    'name': 'Myanmar',
    'dial_code': '+95',
    'code': 'MM'
  },
  {
    'name': 'Namibia',
    'dial_code': '+264',
    'code': 'NA'
  },
  {
    'name': 'Nauru',
    'dial_code': '+674',
    'code': 'NR'
  },
  {
    'name': 'Nepal',
    'dial_code': '+977',
    'code': 'NP'
  },
  {
    'name': 'Netherlands',
    'dial_code': '+31',
    'code': 'NL'
  },
  {
    'name': 'Netherlands Antilles',
    'dial_code': '+599',
    'code': 'AN'
  },
  {
    'name': 'New Caledonia',
    'dial_code': '+687',
    'code': 'NC'
  },
  {
    'name': 'New Zealand',
    'dial_code': '+64',
    'code': 'NZ'
  },
  {
    'name': 'Nicaragua',
    'dial_code': '+505',
    'code': 'NI'
  },
  {
    'name': 'Niger',
    'dial_code': '+227',
    'code': 'NE'
  },
  {
    'name': 'Nigeria',
    'dial_code': '+234',
    'code': 'NG'
  },
  {
    'name': 'Niue',
    'dial_code': '+683',
    'code': 'NU'
  },
  {
    'name': 'Norfolk Island',
    'dial_code': '+672',
    'code': 'NF'
  },
  {
    'name': 'Northern Mariana Islands',
    'dial_code': '+1670',
    'code': 'MP'
  },
  {
    'name': 'Norway',
    'dial_code': '+47',
    'code': 'NO'
  },
  {
    'name': 'Oman',
    'dial_code': '+968',
    'code': 'OM'
  },
  {
    'name': 'Pakistan',
    'dial_code': '+92',
    'code': 'PK'
  },
  {
    'name': 'Palau',
    'dial_code': '+680',
    'code': 'PW'
  },
  {
    'name': 'Palestinian Territory, Occupied',
    'dial_code': '+970',
    'code': 'PS'
  },
  {
    'name': 'Panama',
    'dial_code': '+507',
    'code': 'PA'
  },
  {
    'name': 'Papua New Guinea',
    'dial_code': '+675',
    'code': 'PG'
  },
  {
    'name': 'Paraguay',
    'dial_code': '+595',
    'code': 'PY'
  },
  {
    'name': 'Peru',
    'dial_code': '+51',
    'code': 'PE'
  },
  {
    'name': 'Philippines',
    'dial_code': '+63',
    'code': 'PH'
  },
  {
    'name': 'Pitcairn',
    'dial_code': '+872',
    'code': 'PN'
  },
  {
    'name': 'Poland',
    'dial_code': '+48',
    'code': 'PL'
  },
  {
    'name': 'Portugal',
    'dial_code': '+351',
    'code': 'PT'
  },
  {
    'name': 'Puerto Rico',
    'dial_code': '+1939',
    'code': 'PR'
  },
  {
    'name': 'Qatar',
    'dial_code': '+974',
    'code': 'QA'
  },
  {
    'name': 'Romania',
    'dial_code': '+40',
    'code': 'RO'
  },
  {
    'name': 'Russia',
    'dial_code': '+7',
    'code': 'RU'
  },
  {
    'name': 'Rwanda',
    'dial_code': '+250',
    'code': 'RW'
  },
  {
    'name': 'Reunion',
    'dial_code': '+262',
    'code': 'RE'
  },
  {
    'name': 'Saint Barthelemy',
    'dial_code': '+590',
    'code': 'BL'
  },
  {
    'name': 'Saint Helena, Ascension and Tristan Da Cunha',
    'dial_code': '+290',
    'code': 'SH'
  },
  {
    'name': 'Saint Kitts and Nevis',
    'dial_code': '+1869',
    'code': 'KN'
  },
  {
    'name': 'Saint Lucia',
    'dial_code': '+1758',
    'code': 'LC'
  },
  {
    'name': 'Saint Martin',
    'dial_code': '+590',
    'code': 'MF'
  },
  {
    'name': 'Saint Pierre and Miquelon',
    'dial_code': '+508',
    'code': 'PM'
  },
  {
    'name': 'Saint Vincent and the Grenadines',
    'dial_code': '+1784',
    'code': 'VC'
  },
  {
    'name': 'Samoa',
    'dial_code': '+685',
    'code': 'WS'
  },
  {
    'name': 'San Marino',
    'dial_code': '+378',
    'code': 'SM'
  },
  {
    'name': 'Sao Tome and Principe',
    'dial_code': '+239',
    'code': 'ST'
  },
  {
    'name': 'Saudi Arabia',
    'dial_code': '+966',
    'code': 'SA'
  },
  {
    'name': 'Senegal',
    'dial_code': '+221',
    'code': 'SN'
  },
  {
    'name': 'Serbia',
    'dial_code': '+381',
    'code': 'RS'
  },
  {
    'name': 'Seychelles',
    'dial_code': '+248',
    'code': 'SC'
  },
  {
    'name': 'Sierra Leone',
    'dial_code': '+232',
    'code': 'SL'
  },
  {
    'name': 'Singapore',
    'dial_code': '+65',
    'code': 'SG'
  },
  {
    'name': 'Slovakia',
    'dial_code': '+421',
    'code': 'SK'
  },
  {
    'name': 'Slovenia',
    'dial_code': '+386',
    'code': 'SI'
  },
  {
    'name': 'Solomon Islands',
    'dial_code': '+677',
    'code': 'SB'
  },
  {
    'name': 'Somalia',
    'dial_code': '+252',
    'code': 'SO'
  },
  {
    'name': 'South Africa',
    'dial_code': '+27',
    'code': 'ZA'
  },
  {
    'name': 'South Sudan',
    'dial_code': '+211',
    'code': 'SS'
  },
  {
    'name': 'South Georgia and the South Sandwich Islands',
    'dial_code': '+500',
    'code': 'GS'
  },
  {
    'name': 'Spain',
    'dial_code': '+34',
    'code': 'ES'
  },
  {
    'name': 'Sri Lanka',
    'dial_code': '+94',
    'code': 'LK'
  },
  {
    'name': 'Sudan',
    'dial_code': '+249',
    'code': 'SD'
  },
  {
    'name': 'Suriname',
    'dial_code': '+597',
    'code': 'SR'
  },
  {
    'name': 'Svalbard and Jan Mayen',
    'dial_code': '+47',
    'code': 'SJ'
  },
  {
    'name': 'Swaziland',
    'dial_code': '+268',
    'code': 'SZ'
  },
  {
    'name': 'Sweden',
    'dial_code': '+46',
    'code': 'SE'
  },
  {
    'name': 'Switzerland',
    'dial_code': '+41',
    'code': 'CH'
  },
  {
    'name': 'Syrian Arab Republic',
    'dial_code': '+963',
    'code': 'SY'
  },
  {
    'name': 'Taiwan',
    'dial_code': '+886',
    'code': 'TW'
  },
  {
    'name': 'Tajikistan',
    'dial_code': '+992',
    'code': 'TJ'
  },
  {
    'name': 'Tanzania, United Republic of Tanzania',
    'dial_code': '+255',
    'code': 'TZ'
  },
  {
    'name': 'Thailand',
    'dial_code': '+66',
    'code': 'TH'
  },
  {
    'name': 'Timor-Leste',
    'dial_code': '+670',
    'code': 'TL'
  },
  {
    'name': 'Togo',
    'dial_code': '+228',
    'code': 'TG'
  },
  {
    'name': 'Tokelau',
    'dial_code': '+690',
    'code': 'TK'
  },
  {
    'name': 'Tonga',
    'dial_code': '+676',
    'code': 'TO'
  },
  {
    'name': 'Trinidad and Tobago',
    'dial_code': '+1868',
    'code': 'TT'
  },
  {
    'name': 'Tunisia',
    'dial_code': '+216',
    'code': 'TN'
  },
  {
    'name': 'Turkey',
    'dial_code': '+90',
    'code': 'TR'
  },
  {
    'name': 'Turkmenistan',
    'dial_code': '+993',
    'code': 'TM'
  },
  {
    'name': 'Turks and Caicos Islands',
    'dial_code': '+1649',
    'code': 'TC'
  },
  {
    'name': 'Tuvalu',
    'dial_code': '+688',
    'code': 'TV'
  },
  {
    'name': 'Uganda',
    'dial_code': '+256',
    'code': 'UG'
  },
  {
    'name': 'Ukraine',
    'dial_code': '+380',
    'code': 'UA'
  },
  {
    'name': 'United Arab Emirates',
    'dial_code': '+971',
    'code': 'AE'
  },
  {
    'name': 'United Kingdom',
    'dial_code': '+44',
    'code': 'GB'
  },
  {
    'name': 'United States',
    'dial_code': '+1',
    'code': 'US'
  },
  {
    'name': 'Uruguay',
    'dial_code': '+598',
    'code': 'UY'
  },
  {
    'name': 'Uzbekistan',
    'dial_code': '+998',
    'code': 'UZ'
  },
  {
    'name': 'Vanuatu',
    'dial_code': '+678',
    'code': 'VU'
  },
  {
    'name': 'Venezuela, Bolivarian Republic of Venezuela',
    'dial_code': '+58',
    'code': 'VE'
  },
  {
    'name': 'Vietnam',
    'dial_code': '+84',
    'code': 'VN'
  },
  {
    'name': 'Virgin Islands, British',
    'dial_code': '+1284',
    'code': 'VG'
  },
  {
    'name': 'Virgin Islands, U.S.',
    'dial_code': '+1340',
    'code': 'VI'
  },
  {
    'name': 'Wallis and Futuna',
    'dial_code': '+681',
    'code': 'WF'
  },
  {
    'name': 'Yemen',
    'dial_code': '+967',
    'code': 'YE'
  },
  {
    'name': 'Zambia',
    'dial_code': '+260',
    'code': 'ZM'
  },
  {
    'name': 'Zimbabwe',
    'dial_code': '+263',
    'code': 'ZW'
  }
];

export const roles = [
  {
    id: 0,
    value: 'Office Agent',
    defaulturl: '/docs_stream',
    //defaulturl: '/admin/manage_users',
  },
  {
    id: 1,
    value: 'Senior Office Agent',
    defaulturl: '/docs_stream',
    //defaulturl: '/admin/manage_users',
  },
  {
    id: 2,
    value: 'Warehouse Agent',
    defaulturl: '/my_flights',
  },
  {
    id: 3,
    value: 'Senior Warehouse Agent',
    defaulturl: '/my_flights',
  },
  {
    id: 4,
    value: 'Supervisor',
    defaulturl: '/admin/manage_users',
  },
  {
    id: 5,
    value: 'Manager',
    defaulturl: '/admin/manage_users',
  },
  {
    id: 6,
    value: 'System Admin',
    defaulturl: '/admin/manage_users',
  },
  {
    id: 7,
    value: 'Accounting',
    defaulturl: '/my_flights',
    //defaulturl: '/admin/manage_users',
  },
  {
    id: 8,
    value: 'Airline Rep',
    defaulturl: '/my_flights',
    //defaulturl: '/admin/manage_users',
  },
  {
    id: 9,
    value: 'Senior Accounting',
    defaulturl: '/my_flights',
  },
];

export const checklistRoles = [
  {
    id: 0,
    value: 'Office Agent',
  },
  {
    id: 1,
    value: 'Senior Office Agent',
  },
  {
    id: 2,
    value: 'Supervisor',
  },
  {
    id: 3,
    value: 'Manager',
  },
  {
    id: 4,
    value: 'System Admin',
  },
  {
    id: 5,
    value: 'Accounting',
  },
  {
    id: 6,
    value: 'Airline Rep',
  },
];

export const statisticsRoles = [
  {
    id: 0,
    role: 'System Admin',
    access: ['System Admin']
  },
  {
    id: 1,
    role: 'Manager',
    access: ['Manager', 'System Admin']
  },
  {
    id: 2,
    role: 'Supervisor',
    access: ['Supervisor', 'Manager', 'System Admin']
  },
  {
    id: 3,
    role: 'Senior Office Agent',
    access: ['Senior Office Agent', 'Supervisor', 'Manager', 'System Admin']
  },
  {
    id: 4,
    role: 'Office Agent',
    access: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin']
  },
  {
    id: 5,
    role: 'Senior Warehouse Agent',
    access: ['Senior Warehouse Agent', 'Supervisor', 'Manager', 'System Admin']
  },
  {
    id: 6,
    role: 'Warehouse Agent',
    access: ['Warehouse Agent', 'Senior Warehouse Agent', 'Supervisor', 'Manager', 'System Admin']
  },
  {
    id: 7,
    role: 'Senior Accounting',
    access: ['Senior Accounting', 'Manager', 'System Admin']
  },
  {
    id: 8,
    role: 'Accounting',
    access: ['Accounting', 'Senior Accounting', 'Manager', 'System Admin']
  },
  {
    id: 9,
    role: 'Airline Rep',
    access: ['Airline Rep', 'Manager', 'System Admin']
  },
  {
    id: 10,
    role: 'N/A',
    access: ['Senior Office Agent', 'Office Agent', 'Senior Warehouse Agent', 'Warehouse Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Senior Accounting', 'Airline Rep']
  }
];

export const menulist = [
  {
    link: '/admin/manage_users',
    title: 'Admin',
    icon: <img src={Admin} alt='admin' className={`${styles.whiteSvg}`} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['Senior Office Agent', 'System Admin', 'Manager', 'Supervisor'],
  },
  {
    link: '/my_flights',
    title: 'My Flights',
    icon: <img src={Flights} alt='Flights' className={`${styles.whiteSvg}`} />,
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Airline Rep', 'Accounting', 'Senior Accounting'],
  },
  {
    link: '/flight_folder/notes',
    title: 'Flight Folder',
    icon: <img src={Flights} alt='Flights' className={`${styles.whiteSvg}`} />,
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Airline Rep', 'Accounting', 'Senior Accounting'],
  },
  {
    link: '/service_log',
    title: 'Service Logs',
    icon: <WebStories className={styles.whiteIcon} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/billing/flight_load_info',
    title: 'Billing Summary',
    icon: <Receipt className={styles.whiteIcon} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/bulk_approvals/flight_report',
    title: 'Approvals',
    icon: <Verified className={styles.whiteIcon} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  // {
  //   key: '6',
  //   link: '/screening_reports',
  //   title: 'Reports',
  //   icon: <img src={Reports} alt='reports' className={`${styles.whiteSvg}`} />,
  //   className: 'sider-menu-item',
  //   env: ['development', 'stage', 'production'],
  //   roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent']
  // },
  {
    link: '/docs_stream',
    title: 'Docs Stream',
    icon: <img src={Docs} alt='Docs Stream' className={`${styles.whiteSvg}`} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent']
  },
  {
    link: '/doc_archive/airwaybills',
    title: 'Doc Archive',
    icon: <img src={DocArchive} alt='doc archive' className={`${styles.whiteSvg}`} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/awb_search',
    title: 'AWB Search',
    icon: <img src={Docs} alt='Docs Stream' className={`${styles.whiteSvg}`} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/gatekeeper',
    title: 'Gatekeeper',
    icon: <img src={DocArchive} alt='gatekeeper' className={`${styles.whiteSvg}`} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  }
].map((item, index) => ({
  ...item,
  key: index
}));

export const statusList = {
  not_finalized: {
    label: 'Not Finalized',
    color: 'grey',
  },
  in_progress: {
    label: 'In Progress',
    color: 'amber',
  },
  finalized: {
    label: 'Finalized',
    color: 'green',
  },
  approved: {
    label: 'Approved',
    color: 'green',
  },
  rejected: {
    label: 'Rejected',
    color: 'red',
  },
};

export const pagelist = [
  {
    link: '/admin/manage_users',
    title: 'Admin | Manage Users',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor']
  },
  {
    link: '/admin/handled_airlines',
    title: 'Admin | Handled Airlines',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor']
  },
  {
    link: '/admin/flight_schedule',
    title: 'Admin | Flight Schedule',
    //Roles Done
    roles: ['Senior Office Agent', 'System Admin', 'Manager', 'Supervisor']
  },
  {
    link: '/admin/white_list',
    title: 'Admin | White List - Docs Stream',
    //Roles Done
    roles: ['System Admin', 'Supervisor', 'Manager']
  },
  {
    link: '/admin/spl_mapping',
    title: 'Admin | SPL Mapping',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor']
  },
  {
    link: '/admin/reports_checklist',
    title: 'Admin | Reports Checklist',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor']
  },
  {
    link: 'admin/loading_accessories',
    title: 'Admin | Loading Accessories',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor']
  },
  {
    link: '/admin/manage_contract',
    title: 'Admin | Manage Contract',
    //Roles Done
    roles: ['System Admin', 'Manager']
  },
  {
    link: '/admin/invoicing',
    title: 'Admin | Invoicing',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Accounting']
  },
  {
    link: '/my_flights',
    title: 'My Flights',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep']
  },
  {
    link: '/docs_stream',
    title: 'Docs Stream',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent']
  },
  // {
  //   key: '11',
  //   link: '/doc_archive',
  //   title: 'Doc Archive',
  //   //Roles Done
  //   roles: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Airline Rep']
  // },
  // {
  //   key: '12',
  //   link: '/screening_reports',
  //   title: 'Screening Reports',
  //   roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent']
  // },
  {
    link: '/flight_folder/notes',
    title: 'My Flights | Notes',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/flight_folder/buildup',
    title: 'My Flights | Buildup',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/flight_folder/manifest',
    title: 'My Flights | Manifest',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/flight_folder/discrepancies',
    title: 'My Flights | Discrepancies',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Airline Rep']
  },
  {
    link: '/flight_folder/flt_report',
    title: 'My Flights | Flight Report',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  // {
  //   key: '16',
  //   link: '/flight_folder/review_status',
  //   title: 'Flight Folder | Review Status',
  //   roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  // },
  {
    link: '/billing/flight_load_info',
    title: 'Billing | Flight Load Info',
    //Roles Done
    roles: ['System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/billing/service_logs',
    title: 'Billing | Service Logs',
    //Roles Done
    roles: ['System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/awb_search',
    title: 'AWB Search',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Warehouse Agent', 'Airline Rep', 'Accounting', 'Senior Accounting']
  },
  {
    link: '/doc_archive/airwaybills',
    title: 'Doc Archive | Airwaybills',
    //Roles Done
    roles: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/doc_archive/flight_manifest',
    title: 'Doc Archive | Flight Manifest',
    //Roles Done
    roles: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/doc_archive/user_training_records',
    title: 'Doc Archive | User Training Records',
    //Roles Done
    roles: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/doc_archive/other_documents',
    title: 'Doc Archive | Other Documents',
    //Roles Done
    roles: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/doc_archive/service_logs',
    title: 'Doc Archive | Service Logs',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/service_log',
    title: 'Service Logs',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/bulk_approvals/flight_report',
    title: 'Approval | Flight Report',
    //Roles Done
    roles: ['System Admin', 'Airline Rep', 'Accounting', 'Senior Accounting']
  },
  {
    link: '/bulk_approvals/service_logs',
    title: 'Approval | Service Logs',
    //Roles Done
    roles: ['System Admin', 'Airline Rep', 'Accounting', 'Senior Accounting']
  },
  {
    link: '/product_config',
    title: 'Product Configuration',
    roles: ['System Admin']
  },
  {
    link: '/gatekeeper',
    title: 'Gatekeeper',
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  }
].map((item, index) => ({
  ...item,
  key: index
}));

export const pageHandler = (url, role) => {
  // console.log('pageHandler', url, role);
  if (!role) {
    //logout()
    // window.location.href = '/dashboard';
    signout();
  } else {
    let row = pagelist.find((item) => (item?.link === url || item?.link?.includes(url)));
    if (row && row.roles.includes(role)) {
      if (row.roles.indexOf(role) > '-1') {
        //do nothing
      } else {
        let url = roles.find((item) => item?.value === role);
        if (url) {
          window.location.href = url.defaulturl;
        } else {
          signout();
        }
      }
    } else {
      let url = roles.find((item) => item?.value === role);
      if (url) {
        window.location.href = url.defaulturl;
      } else {
        signout();
      }
    }
  }
};

export const signout = () => {
  http.get('/user/logout').then(() => {
    cookies.remove('company_name');
    cookies.remove('company_mail');
    cookies.remove('login');
    cookies.remove('timezone');
    cookies.remove('stations');
    cookies.remove('role');
    cookies.remove('station');
    cookies.remove('company');
    cookies.remove('station_code');
    cookies.remove('user');
    cookies.remove('name');
    cookies.remove('my_flights');
    cookies.remove('ismanagec');
    cookies.remove('managecdt');
    cookies.remove('contract_types');
    cookies.remove('currency');
    window.location.href = '/login';
  });
};

export const allAirlines = {
  id: -1,
  select: '',
  airlines: 'ALL',
  code: 'ALL'
};

export const contractType = {
  cargo_handling: 'Cargo Handling',
  flight_ops: 'Flight Ops',
  drayage: 'Drayage',
};

export const adminTabs = {
  manage_users: 'Manage Users',
  handled_airlines: 'Handled Airlines',
  flight_schedule: 'Flight Schedule',
  white_list: 'White List - Docs Stream',
  spl_mapping: 'SPL Mapping',
  reports_checklist: 'Reports Checklist',
  manage_contract: 'manage Contract',
  invoicing: 'Invoicing',
  loading_accessories: 'Loading Accessories',
};

export const screeningTabs = {
  screening_details: 'Screening Details',
  consignment_security: 'Consignment Security Declaration',
};

export const docArchiveTabs = {
  awbs: 'Airwaybills',
  flight_manifest: 'Flight Manifest',
  user_training_records: 'User Training Records',
  other_docs: 'Other Documents',
  service_logs: 'Service Logs',
};

export const flightTabs = {
  notes: 'Notes',
  buildup: 'Buildup',
  manifest: 'Manifest',
  discrepancies: 'Discrepancies',
  flt_report: 'Flight Report',
};

export const appliedOnItems = [
  {
    value: 'Loose KGs | Auto',
    label: 'Loose KGs | Auto',
    desc: 'This is the description of Loose KGs | Auto',
    disable_product: true,
    rate_type_options: 'All',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Loose KGs Gen | Auto',
    label: 'Loose KGs Gen | Auto',
    desc: 'This is the description of Loose KGs | Auto',
    disable_product: true,
    rate_type_options: 'All',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Loose KGs SPL | Auto',
    label: 'Loose KGs SPL | Auto',
    desc: 'This is the description of Loose KGs | Auto',
    disable_product: false,
    rate_type_options: 'All',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Total KGs | Auto',
    label: 'Total KGs | Auto',
    desc: 'This is the description of Total KGs | Auto',
    disable_product: true,
    rate_type_options: 'All',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Intact ULD KGs | Auto',
    label: 'Intact ULD KGs | Auto',
    desc: 'This is the description of Intact ULD KGs | Auto',
    disable_product: true,
    rate_type_options: 'All',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Intact ULD LD3 | Auto',
    label: 'Intact ULD LD3 | Auto',
    desc: 'This is the description of Intact ULD LD3 | Auto',
    disable_product: false,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Intact ULD LD7 | Auto',
    label: 'Intact ULD LD7 | Auto',
    desc: 'This is the description of Intact ULD LD7 | Auto',
    disable_product: false,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Intact ULD MD | Auto',
    label: 'Intact ULD MD | Auto',
    desc: 'This is the description of Intact ULD MD | Auto',
    disable_product: false,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Total ULDs | Auto',
    label: 'Total ULDs | Auto',
    desc: 'This is the description of Total ULDs | Auto',
    disable_product: true,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Flight | Auto',
    label: 'Flight | Auto',
    desc: 'This is the description of Flight | Auto',
    disable_product: true,
    rate_type_options: 'Stable',
    segments: [],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  // {
  //   value: 'Flight | Manual',
  //   label: 'Flight | Manual',
  //   desc: 'This is the description of Flight | Manual',
  //   disable_product: true,
  //   rate_type_options: 'Stable',
  //   segments: [],
  //   recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  // },
  // {
  //   value: 'Turnaround | Manual',
  //   label: 'Turnaround | Manual',
  //   desc: 'This is the description of Turnaround | Manual',
  //   disable_product: true,
  //   rate_type_options: 'Stable',
  //   segments: ['Import', 'Export', 'Transit'],
  //   recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  // },
  {
    value: 'Air Waybill | Auto',
    label: 'Air Waybill | Auto',
    desc: 'This is the description of Air Waybill | Auto',
    disable_product: false,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Air Waybill (Pcs) | Auto',
    label: 'Air Waybill (Pcs) | Auto',
    desc: 'This is the description of Air Waybill (Pcs) | Auto',
    disable_product: false,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  // {
  //   value: 'Day | Manual',
  //   label: 'Day | Manual',
  //   desc: 'This is the description of Day | Manual',
  //   disable_product: true,
  //   rate_type_options: 'Stable',
  //   segments: [],
  //   recordOns: ['Day Log', 'Month Log'],
  // },
  // {
  //   value: 'Month | Manual',
  //   label: 'Month | Manual',
  //   desc: 'This is the description of Month | Manual',
  //   disable_product: true,
  //   rate_type_options: 'Stable',
  //   segments: [],
  //   recordOns: ['Day Log', 'Month Log'],
  // },
  // {
  //   value: 'UN Number | Manual',
  //   label: 'UN Number | Manual',
  //   desc: ' This is the description of UN Number | Manual',
  //   disable_product: false,
  //   rate_type_options: 'Stable',
  //   segments: ['Import', 'Export', 'Transit'],
  //   recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  // },
  // {
  //   value: 'Screened KG | Manual',
  //   label: 'Screened KG | Manual',
  //   desc: 'This is the description of Screened KG | Manual',
  //   disable_product: true,
  //   rate_type_options: 'All',
  //   segments: ['Import', 'Export', 'Transit'],
  //   recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  // },
  // {
  //   value: 'Units (Pcs) | Manual',
  //   label: 'Units (Pcs) | Manual',
  //   desc: 'This is the description of Units (Pcs) | Manual',
  //   disable_product: true,
  //   rate_type_options: 'Stable',
  //   segments: [],
  //   recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  // },
  {
    value: 'Units | Manual',
    label: 'Units | Manual',
    desc: 'This is the description of Units (Hourly) | Manual',
    disable_product: true,
    rate_type_options: 'All',
    segments: [],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Rent | Auto',
    label: 'Rent | Auto',
    desc: 'This is the description of Rent | Auto',
    disable_product: true,
    rate_type_options: 'Stable',
    segments: [],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
];

export const discrepancyType = [
  'Missing Cargo',
  'Found Cargo',
  'Missing Airwaybill',
  'Found Airwaybill',
  'Short Shipped',
  'Over Carried',
  'Damaged Cargo',
  'Other'
];

export const jsonTheme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#0000',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

export const timezones = {
  ATL: 'America/New_York',
  LAX: 'America/Los_Angeles',
  BOS: 'America/New_York',
  JFK: 'America/New_York',
  SEA: 'America/Los_Angeles',
  DTW: 'America/New_York',
  MSP: 'America/Chicago',
  SLC: 'America/Denver',
  ORD: 'America/Chicago',
  HNL: 'Pacific/Honolulu',
  SFO: 'America/Los_Angeles',
  FLL: 'America/New_York',
  MIA: 'America/New_York',
  MCO: 'America/New_York',
};

export const uldTypes = [
  {
    id: 'LD3',
    value: 'LD3',
  }, {
    id: '2X LD3',
    value: '2X LD3',
  }, {
    id: 'LD7',
    value: 'LD7',
  }, {
    id: 'MD',
    value: 'MD',
  }, {
    id: '2X MD',
    value: '2X MD',
  }
];
export const handledAs = [
  {
    id: 'Loose',
    value: 'Loose',
  },
  {
    id: 'Intact',
    value: 'Intact',
  }
];

export const AddIcon = (props) => <Tooltip title={props.title} {...props}>
  <span>
    <IconButton disableRipple aria-label='delete' size='small' disabled={props.disabled} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
      <img src={Add} alt={props.title} className={`${styles.pointer} ${styles.addIcon}`} />
    </IconButton>
  </span>
</Tooltip>;
export const DollarRateIcon = (props) => <Tooltip title={props.title} {...props}>
  <span>
    <IconButton disableRipple aria-label='rate-icon' size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.squareIcon} ${styles.mr2}`} classes={{ disabled: styles.disabledImg }}>
      <img src={DollarRate} alt={props.title} className={`${styles.pointer}`} width={21} height={21} />
    </IconButton>
  </span>
</Tooltip>;
export const EditIcon = (props) =>
  <Tooltip title='Edit record' {...props} onClick={() => { }}>
    <span>
      <IconButton disableRipple aria-label='edit' size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
        <img src={Edit} alt='Edit' className={styles.pointer} />
      </IconButton>
    </span>
  </Tooltip>;
export const LockIcon = (props) =>
  <Tooltip title={props.title} {...props} onClick={() => { }}>
    <span>
      <IconButton disableRipple aria-label='Deactivate' size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
        <img src={Lock} alt={props.title} className={styles.pointer} />
      </IconButton>
    </span>
  </Tooltip>;
export const UnlockIcon = (props) =>
  <Tooltip title={props.title} {...props} onClick={() => { }}>
    <span>
      <IconButton disableRipple aria-label='Reactivate' size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
        <img src={UnLock} alt={props.title} className={styles.pointer} />
      </IconButton>
    </span>
  </Tooltip>;
export const KeyIcon = (props) =>
  <Tooltip title={props.title} {...props}>
    <span>
      <IconButton disableRipple aria-label='Password' size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
        <img src={Key} alt={props.title} className={styles.pointer} />
      </IconButton>
    </span>
  </Tooltip>;

export const LocationIcon = (props) =>
  <Tooltip title='Location' {...props}>
    <span>
      <IconButton disableRipple aria-label='Location' size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
        <img src={Location} alt='Location' className={styles.pointer} />
      </IconButton>
    </span>
  </Tooltip>;
export const DeleteIcon = (props) => <Tooltip title='Delete record' {...props} onClick={() => { }}>
  <span>
    <IconButton disableRipple aria-label='delete' size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
      <img src={Delete} alt='Delete' className={`${styles.pointer} ${styles.primarySvg}`} />
    </IconButton>
  </span>
</Tooltip>;
export const BinoIcon = (props) => <Tooltip title={props.title} {...props}>
  <span>
    <IconButton disableRipple aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
      <img src={Bino} alt={props.title} className={`${styles.pointer}`} />
    </IconButton>
  </span>
</Tooltip>;
export const AttachmentIcon = (props) => <Tooltip title={props.title} {...props}>
  <span>
    <IconButton disableRipple aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
      <AttachFile fontSize='small' />
    </IconButton>
  </span>
</Tooltip>;
export const ExcelIcon = (props) => <Tooltip title={props.title} {...props} onClick={() => { }}>
  <span>
    <IconButton disableRipple aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
      <img src={Excel} alt={props.title} className={`${styles.pointer} ${styles.greenSvg}`} />
    </IconButton>
  </span>
</Tooltip>;
export const PDFIcon = (props) => <Tooltip title={props.title} {...props}>
  <span>
    <IconButton disableRipple aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
      <img src={PDF} alt={props.title} className={`${styles.pointer}`} />
    </IconButton>
  </span>
</Tooltip>;
export const StickyNoteIcon = (props) => <Tooltip title={props.title} {...props}>
  <span>
    <IconButton disableRipple aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
      <img src={StickyNote} alt={'sticky note'} className={`${styles.pointer}`} height={'15px'} />
    </IconButton>
  </span>
</Tooltip>;
export const CloseIconBtn = (props) =>
  <IconButton
    aria-label='close'
    onClick={props.onClick}
    sx={{ ...CloseIconProps }}
    size='small'
  >
    <CloseIcon />
  </IconButton>;
export const DocIcon = (props) => <Tooltip title={props.title} {...props}><img src={Doc} alt='doc-icon' className={`${styles.pointer} ${styles.primarySvg}`} /></Tooltip>;
export const ViewGreenIcon = () => <Tooltip title=''><img src={ViewGreen} alt='view-icon' className={`${styles.pointer}`} /></Tooltip>;
export const ViewIcon = () => <Tooltip title=''><img src={View} alt='view-icon' className={`${styles.pointer}`} /></Tooltip>;
export const ArrowTopIcon = (props) => <Tooltip title='' {...props}><img src={ArrowTop} alt='arrow-icon' className={`${styles.pointer}`} /></Tooltip>;
export const SaveTickIcon = (props) => <Tooltip title='' {...props}><img src={SaveTick} alt='save-icon' className={`${styles.pointer}`} /></Tooltip>;
export const ClearXIcon = (props) => <Tooltip title='Clear'{...props}><span className={`${styles.pointer} ${styles.formClear}`}>x</span></Tooltip>;
export const TickIcon = () => <Tooltip title=''><img src={Tick} alt='tick-icon' className={`${styles.pointer}`} /></Tooltip>;
export const UnTickIcon = () => <Tooltip title=''><img src={UnTick} alt='icon' className={`${styles.pointer}`} /></Tooltip>;
export const WhiteCameraIcon = () => <Tooltip title=''><img src={WhiteCamera} alt='camera-icon' className={`${styles.pointer}`} /></Tooltip>;
export const WhiteEditIcon = () => <Tooltip title=''><img src={WhiteEdit} alt='edit-icon' className={`${styles.pointer}`} /></Tooltip>;
export const WhiteDocIcon = () => <Tooltip title=''><img src={WhiteDoc} alt='doc-icon' className={`${styles.pointer}`} /></Tooltip>;
export const BellIcon = () => <Tooltip title=''><img src={Bell} alt='bell-icon' className={`${styles.pointer}`} /></Tooltip>;
export const UploadIcon = () => <Tooltip title='Upload File'><img src={Upload} alt='Upload' className={`${styles.pointer}`} /></Tooltip>; // Do not change to Iconbutton - creates button within button issue on Tabs
export const DownloadIcon = (props) => <Tooltip title='Download' {...props}><img src={Download} alt='download' className={`${styles.pointer}`} /></Tooltip>;
export const PageIcon = () => <Tooltip title=''><img src={Page} alt='page-icon' className={`${styles.pointer}`} /></Tooltip>;
export const CameraIcon = () => <Tooltip title=''><img src={Camera} alt='camera-icon' className={`${styles.pointer}`} /></Tooltip>;
export const EcoIcon = () => <Tooltip title=''><img src={Eco} alt='eco-icon' className={`${styles.pointer}`} /></Tooltip>;
export const BrowseIcon = () => <Tooltip title='Browse file'><MoreHoriz /></Tooltip>;
export const ExclamationIcon = (props) => <Tooltip title={props.title} {...props}><img src={Exclamation} alt='warning-icon' /></Tooltip>;

export const DateTimeFormat = 'DDMMMYY HH:mm';
export const DateTimeSecFormat = 'DDMMMYY HH:mm:ss';
export const DateFormat = 'DDMMMYY';
export const MonthFormat = 'MMM YY';


export const platforms = [
  'Pax',
  'Freighter',
  'Domestic Pax',
  'Domestic Freighter',
  'Truck'
];

export const LOCALE_OBJ = {
  locale: 'en-US',
  // options: {
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // },
};

export const statusColor = (status) => {
  let color = 'var(--not_finalized)';
  if (status) {
    if (status === 'Finalized') {
      color = 'var(--finalized)';
    } else if (status === 'Submitted') { //inprogress
      color = 'var(--in_progress)';
    } else if (status === 'Rejected') {
      color = 'var(--rejected)';
    } else if (status === 'Reopen') {
      color = 'var(--rejected)';
    } else if (status === 'Approved') {
      color = 'var(--approved)';
    }
  }
  return color;
};