import React from 'react';
import { Drawer, Typography, Grid, Stack, Divider, IconButton, InputAdornment, Tooltip } from '@mui/material';
import { PopoverHead, PopoverContent, CustomButton, CustomTextField, InputProps, StyledCard } from '../../../../custom/components';
import { Close as CloseIcon, Error as ErrorIcon, Image } from '@mui/icons-material';
import PDF from '../../../../assets/images/pdf.png';
import { EditIcon, DeleteIcon, AddIcon, DownloadIcon, BrowseIcon, DocIcon } from '../../../../custom/constants';
import theme from '../../../../custom/theme';
import styles from '../../../../custom/style.module.css';
import userStyles from './../../admin/tabs/manageusers/users.module.css';
import docStyles from './doc.module.css';
import { styled } from '@mui/material/styles';
import AddDocModal from './AddDocModal';
import PDFImg from '../../../../assets/images/pdf.svg';
import ExcelImg from '../../../../assets/images/greenExcel.svg';
import environment from '../../../../custom/environments';
import { dateTolocale } from 'utils/converter';

const SectionDivider = styled(Divider)(() => ({
  borderColor: 'white',
  borderBottomWidth: '12px',
  marginLeft: 'calc(var(--splayout) * -1)',
  marginRight: 'calc(var(--splayout) * -1)',
}));

const detailFontSize = '0.7rem';

const DocModal = (props) => {
  const { drawer, closeDrawer, docs, name, docfields, onChangeDoc, docerrors, onDocSubmit } = props;
  return (
    <div>
      <Drawer
        anchor='right'
        open={drawer}
        onClose={closeDrawer}
        className={userStyles.trainingDrawer}
        classes={{ paper: userStyles.trainingPaper }}
      >
        <PopoverHead>
          {drawer == 'user' && (<Typography variant='subtitle2' component='span'>Add/View Training Records</Typography>)}
          {drawer == 'k9' && (<Typography variant='subtitle2' component='span'>Attachments</Typography>)}
          {drawer == 'contract' && (<Typography variant='h6' component='h6' fontSize={'1.15rem'} fontWeight={600}>Contract Attachments</Typography>)}
          {drawer == 'discrepancy' && (<Typography variant='h6' component='h6' fontSize={'1.15rem'} fontWeight={600}>Discrepancy Attachmnets</Typography>)}
          {drawer == 'service_log' && (<Typography variant='h6' component='h6' fontSize={'1.15rem'} fontWeight={600}>Service Log Attachments</Typography>)}
          <CloseIcon fontSize='small' className={styles.pointer} onClick={closeDrawer} />
        </PopoverHead>
        <PopoverContent>
          <Grid container>
            <Grid item xs={12} className={styles.fSpaceBtw}>
              {name}
              {drawer === 'contract' || drawer === 'discrepancy' || drawer == 'service_log'  ? '' :
                <CustomButton variant='contained' size='small' className={styles.minWidth150} onClick={() => props.handleOpen(drawer, 'add')} >{drawer == 'user' && ('Add New Training Record')} {drawer == 'k9' && (<AddIcon />)} </CustomButton>
              }
            </Grid>
            {drawer == 'user' && docs?.map((list) => {
              return <Grid container item xs={12} spacing={2} key={list.id}>
                <Grid item xs={8} className=''>
                  <div className={`${styles.fSpaceBtw} ${styles.mb2}`}>
                    <Typography variant='subtitle2' component='span' color={theme.palette.primary.main} fontWeight='bold'>{list.name}</Typography>
                    <Stack direction='row' spacing={2} className={userStyles.trainingAction}>
                      <EditIcon onClick={() => props.handleOpen('user', 'edit', list)} />
                      <DeleteIcon onClick={(e) => props.handleDelete(list.id, e.target)} />
                    </Stack>
                  </div>
                  <div>
                    <Typography variant='caption' component='span' className={userStyles.trainingTitle}>Training Date:</Typography>
                    <Typography variant='caption' component='span' color={theme.palette.primary.main} fontWeight='bold'>{list.data.training_date}</Typography>
                  </div>
                  <div>
                    <Typography variant='caption' component='span' className={userStyles.trainingTitle}>Expiry:</Typography>
                    <Typography variant='caption' component='span' color={theme.palette.primary.main} fontWeight='bold' className={styles.dInlineFlex}>
                      {list.data.expiry_date}
                      {list.data.expiry_date && new Date(list.data.expiry_date) < new Date() ? <ErrorIcon color='error' fontSize='small' className={userStyles.mr2} /> : ''} </Typography>
                  </div>
                  <div>
                    <Typography variant='caption' component='span' className={userStyles.trainingTitle}>Record added by:</Typography>
                    <Typography variant='caption' component='span' color={theme.palette.primary.main} fontWeight='bold'>{list.added_by?.first_name + ' ' + list.added_by?.last_name}</Typography>
                  </div>
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs={3}>
                  {list.src.includes('.pdf') && (<img src={PDF} alt='pdf' className={styles.w100} onClick={() => { window.open(environment.apiService + 'document?key=' + list.src); }} />)}
                  {!list.src.includes('.pdf') && (<img src={list.src} alt={'attachment'} className={styles.w100} />)}
                </Grid>
                <Divider light />
              </Grid>;
            })}
            {drawer == 'k9' && docs?.map((list) => {
              return <Grid container item xs={12} spacing={2} key={list.id}>
                <Grid item xs={8} className=''>
                  <div className={`${styles.fSpaceBtw} ${styles.mb2}`}>
                    <Typography variant='subtitle2' component='span' color={theme.palette.primary.main} fontWeight='bold'>{list.name}</Typography>
                    <Stack direction='row' spacing={2} className={userStyles.trainingAction}>
                      <EditIcon onClick={() => props.handleOpen('k9', 'edit', list)} />
                      <DeleteIcon onClick={(e) => props.handleDelete(list.id, e.target)} />
                    </Stack>
                  </div>
                  <div>
                    <Typography variant='caption' component='span' className={userStyles.trainingTitle}>Date Added:</Typography>
                    <Typography variant='caption' component='span' color={theme.palette.primary.main} fontWeight='bold'>{dateTolocale(list.created, 'DD MMM YYYY')}</Typography>
                  </div>
                  <div>
                    <Typography variant='caption' component='span' className={userStyles.trainingTitle}>Added By:</Typography>
                    <Typography variant='caption' component='span' color={theme.palette.primary.main} fontWeight='bold'>{list.added_by.first_name} {list.added_by.last_name}</Typography>
                  </div>
                  <div>
                    <Typography variant='caption' component='span' className={userStyles.trainingTitle}>Remarks:</Typography>
                    <Typography variant='caption' component='span' color={theme.palette.primary.main} fontWeight='bold'>{list.data.remark}</Typography>
                  </div>
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs={3}>
                  {list.src.includes('.pdf') && (<img src={PDF} alt='pdf' className={styles.w100} onClick={() => { window.open(environment.apiService + 'document?key=' + list.src); }} />)}
                  {!list.src.includes('.pdf') && (<img src={list.src} alt={'attachment'} className={styles.w100} />)}
                </Grid>
                <Divider light />
              </Grid>;
            })}
            {(drawer == 'contract' || drawer == 'discrepancy' || drawer == 'service_log') && <Grid container item xs={12}>
              <Stack direction={'column'} spacing={3}>
                <StyledCard className={styles.p3}>
                  <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
                    <div>
                      <Typography htmlFor='attachment' variant='subtitle1' component='label' fontWeight={600} fontSize={'0.9rem'} color={theme.palette.primary.main} marginBottom={2}>Attach New Document</Typography>
                      <CustomTextField
                        {...InputProps}
                        id='attachment'
                        fullWidth
                        autoFocus
                        placeholder='Enter Document Name'
                        type='text'
                        // onChange={props.onChange}
                        name='name'
                        onChange={onChangeDoc}
                        value={docfields.name}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Tooltip title={docfields?.file?.name}>
                                <span className={`${docStyles.fileName} ${docStyles.drawerFileName}`}>{docfields?.file?.name}</span>
                              </Tooltip>
                              <IconButton size='small'>
                                <input
                                  accept={drawer === 'discrepancy' ? '.xlsx, .xls, .pdf' : '.xlsx, .xls, .pdf, image/*'}
                                  style={{ display: 'none' }}
                                  id='file'
                                  type='file'
                                  name='file'
                                  onChange={onChangeDoc}
                                />
                                <label htmlFor='file' className={`${styles.fs0} ${styles.pointer}`}>
                                  <BrowseIcon />
                                </label>
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        // value={props.userFields.first_name ?? ''}
                        // error={props.userErrors.first_name}
                        // helperText={props.userErrors.first_name}
                        error={docerrors.name}
                        helperText={docerrors.name}
                      />
                    </div>
                    <AddIcon className={`${styles.addIcon} ${styles.mt4} ${styles.pt1}`} title='Add Doc'
                      // onClick={(e) => drawer == 'discrepancy' ? addDiscrepancyDoc(e) : onSubmitDoc(e) } 
                      onClick={(e) => onDocSubmit(e)}
                    />
                  </Stack>
                </StyledCard>
                <StyledCard className={styles.p3}>
                  <Typography variant='subtitle1' component='div' fontWeight={600} fontSize={'0.9rem'} color={theme.palette.primary.main} marginBottom={2} lineHeight={'normal'}>List of Attachments</Typography>
                  <SectionDivider className={styles.mb3} />
                  <Stack direction={'column'} spacing={3} className={docStyles.attListWrapper}>
                    {props?.docs && props?.docs?.map((row, i, self) => (
                      <>
                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} className={styles.pl4}>
                          <Stack direction={'column'} spacing={1}>
                            <Stack direction={'column'} spacing={0}>
                              <Typography variant='caption' component='span' fontSize={detailFontSize} className={userStyles.trainingTitle}>Document Name</Typography>
                              <Typography variant='caption' component='span' fontSize={detailFontSize} color={theme.palette.primary.main} fontWeight='bold'>{row?.name}</Typography>
                            </Stack>
                            <Stack direction={'column'} spacing={0}>
                              <Typography variant='caption' component='span' fontSize={detailFontSize} className={userStyles.trainingTitle}>Attached By</Typography>
                              <Typography variant='caption' component='span' fontSize={detailFontSize} color={theme.palette.primary.main} fontWeight='bold' className={styles.dInlineFlex}>
                                {row?.added_by ? `${row?.added_by?.first_name} ${row?.added_by?.last_name}` : '-'}
                              </Typography>
                            </Stack>
                            <Stack direction={'column'} spacing={0}>
                              <Typography variant='caption' component='span' fontSize={detailFontSize} className={userStyles.trainingTitle}>Date Attached</Typography>
                              <Typography variant='caption' component='span' fontSize={detailFontSize} color={theme.palette.primary.main} fontWeight='bold'>
                                {row?.created ? dateTolocale(row?.created, 'DD MMM YYYY HH:mm') : ''}
                              </Typography>
                            </Stack>
                          </Stack>
                          <div className={`${docStyles.pdfWrapper} ${styles.mb3}`}>
                            {
                              row.src.endsWith('pdf') ? <img src={PDFImg} alt='pdf' /> :
                                row.src.endsWith('xls') || row.src.endsWith('xlsx') ? <img src={ExcelImg} alt='excel' className={docStyles.ExcelImg} /> : <Image color='disabled' className={docStyles.ExcelImg} />
                            }
                          </div>
                          <Stack direction='row' spacing={2} className={docStyles.trainingAction} alignItems={'flex-start'} justifyContent={'space-between'}>
                            <DownloadIcon onClick={() => { props.downloadFile(environment.apiService + 'document?key=' + row.src, (row.name + '.' + (row.src.split('/').pop().split('.').pop()))); }} />
                            <DocIcon title='Print' onClick={() => { window.open(environment.apiService + 'document?key=' + row.src, '_blank'); }} />
                            <DeleteIcon className={styles.dFlex} onClick={(e) => props.handleDelete(row.id, e.target, row)} />
                          </Stack>
                        </Stack>

                        {i === self.length - 1 ? '' : <SectionDivider className={styles.mb0} sx={{
                          marginRight: '-20px !important',
                          marginLeft: '-16px !important'
                        }} />}
                      </>
                    ))}
                  </Stack>
                </StyledCard>
              </Stack>
            </Grid>}
          </Grid>
        </PopoverContent>
        <AddDocModal
          handleOpen={props.handleOpen}
          onClose={props.onClose}
          modal={props.modal}
          action={props.action}

          userFields={props.userFields}
          userErrors={props.userErrors}
          onChangeUser={props.onChangeUser}
          addUser={props.addUser}
          editUser={props.editUser}

          k9Fields={props.k9Fields}
          k9Errors={props.k9Errors}
          onChangeK9={props.onChangeK9}
          addK9={props.addK9}
          editK9={props.editK9}
        />
      </Drawer>
    </div>
  );
};
export default DocModal;