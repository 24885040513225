import cookies from './cookies';
import moment from 'moment-timezone';
import airportTimezone from 'airport-timezone';

function getTimeZone(code) {
  return airportTimezone?.find(function (airport) {
    return airport.code === code;
  })?.timezone;
}

function getoffset(code = false) {
  const stn_code = code ? code : cookies.get('station_code');
  return airportTimezone?.find(function (airport) {
    return airport.code === stn_code;
  })?.offset;
}

function getStationDate(stn_code, date, format = 'YYYY-MM-DD') {
  if (!date || !stn_code) return null;
  const tz = getTimeZone(stn_code);
  return moment.utc(date).tz(tz).format(format).toUpperCase();
}

function getStationDateTime(stn_code, date, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!date || !stn_code) return null;
  const tz = getTimeZone(stn_code);
  return moment.utc(date).tz(tz).format(format).toUpperCase();
}


function getStationUTC(stn_code, date, format = 'YYYY-MM-DD') {
  if (!date || !stn_code) return null;
  const tz = getTimeZone(stn_code);
  return moment.tz(date, tz).utc().format(format);
}

function getStationUTCCurrent(stn_code, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!stn_code) return null;
  const tz = getTimeZone(stn_code);
  return moment().tz(tz).utc().format(format);
}

function dateTolocale(date, format = 'YYYY-MM-DD') {
  if (!date) return null;
  const stn_code = cookies.get('station_code');
  const tz = getTimeZone(stn_code);
  return moment.utc(date).tz(tz).format(format);
}

function dateTimeTolocale(date, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!date) return null;
  const stn_code = cookies.get('station_code');
  const tz = getTimeZone(stn_code);
  return moment.utc(date).tz(tz).format(format);
}

function dateToUTC(date, format = 'YYYY-MM-DD') {
  if (!date) return null;
  const stn_code = cookies.get('station_code');
  const tz = getTimeZone(stn_code);
  return moment.tz(date, tz).utc().format(format);
}


function timeToLocal(time, format = 'HH:mm:ss') {
  if (!time) return null;
  const stn_code = cookies.get('station_code');
  const tz = getTimeZone(stn_code);
  return moment.tz(time, format, tz).format(format);
}

function timeToUTC(time, format = 'HH:mm:ss') {
  if (!time) return null;
  const stn_code = cookies.get('station_code');
  const tz = getTimeZone(stn_code);
  return moment(time, format).tz(tz).utc().format(format);
}

function getLocatDate(formate = 'YYYY-MM-DD') {
  const stn_code = cookies.get('station_code');
  const tz = getTimeZone(stn_code);
  if(tz){
    return moment.utc().tz(tz).format(formate);
  } else {
    return moment().format(formate);
    // To do : logout();
  }
}

export {
  dateTolocale,
  dateTimeTolocale,
  dateToUTC,
  timeToLocal,
  timeToUTC,
  getLocatDate,
  getStationDate,
  getStationDateTime,
  getStationUTC,
  getoffset,
  getStationUTCCurrent
};