import React from 'react';
import FfmDashboard from './FfmDashboard';
import http from 'utils/http';
import allData from 'airline-iata-code';
import { getStationDateTime, getStationUTCCurrent } from 'utils/converter';
import MessageModal from '../flights/tabs/manifest/MessageModal';
import AttachmentContainer from '../docArchive/tabs/attachment/AttachmentContainer';
import moment from 'moment';


class FfmDashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ffm: [],
      pdf: [],
      value: 'dashbaord',
      msgs: []
    };
  }

  componentDidMount() {
    this.getFFM();
    this.getPDF();
  }

  getFFM = () => {
    http.get('/ffm/txt').then(({ data }) => {
      this.setState({
        ffm: data.data,
        msgs: data.data
      }, () => {
        this.filterData();
      });
    }
    );
  };

  getPDF = () => {
    http.get('/gatekeeper/docs').then(({ data }) => {
      this.setState({ pdf: data.data }, () => {
        this.filterData();
      });
    }
    );
  };

  filterData = () => {
    let data = this.state.ffm;
    let filteredData = [];
    data.forEach((item) => {
      let msg = item.msg.split('\n').reverse();
      let parsedData = {};
      msg.pop();
      let flight_parse = msg.pop();
      parsedData.flight_no = flight_parse.split('/')[1];
      parsedData.flight_date = moment(flight_parse.split('/')[2], 'DDMMM').format('YYYY-MM-DD') + ' 00:00:00';
      parsedData.org = flight_parse.split('/')[3];
      parsedData.dest = msg.pop()?.split('/')[0];
      let name = parsedData.flight_no + '-' + parsedData.org + '-' + parsedData.dest;
      let temp = {
        id: item.id,
        name: name,
        airline: item.airline,
        flight: parsedData.flight_no,
        seg: parsedData.org + '-' + parsedData.dest,
        org: parsedData.org,
        dest: parsedData.dest,
        flight_date: parsedData.flight_date,
        source: item.source,
        created: item.created
      };
      filteredData.push(temp);
    });

    // remove duplicates by name
    filteredData = filteredData.filter((thing, index, self) =>
      index === self.findIndex((t) => (
        t.name === thing.name
      ))
    );

    filteredData = filteredData.map((item) => {
      let airline = allData(item.flight.trim().substring(0, 2));
      if (item.flight.trim().substring(0, 2) === 'Z0') {
        airline = allData('Z4');
      }
      let notRecvd = false;
      if(item.org)
      {
        const stnTime = getStationUTCCurrent(item.org);
        if (moment(stnTime).subtract(48, 'hours') > moment(item.flight_date)) {
          notRecvd = true;
        }
      }
      return {
        ...item,
        airline: airline[0]?.Airline,
        notRecvd: notRecvd,
        created: getStationDateTime('GOI', item.created, 'YYYY-MM-DD HH:mm:ss') + ' IST'
      };
    }
    );
    //sort by airline
    filteredData.sort((a, b) => a.airline.localeCompare(b.airline));
    this.setState({ data: filteredData });
  };

  handleTabChange = (newValue) => {
    this.setState({ value: newValue });
  };

  handleOpen = (data) => {
    if (data.src) {
      this.setState({
        modal: 'attached_docs',
        modal_data: [data]
      });
    } else {
      this.setState({
        modal: 'msg',
        modal_data: data
      });
    }
  };

  handleClose = () => {
    this.setState({
      modal: '',
      modal_data: []
    });
  };

  onSearch = () => {
    let value = document.getElementById('flight-search').value;
    console.log(value);
  };


  render() {
    return (
      <>
        <FfmDashboard data={this.state.data} handleTabChange={this.handleTabChange} value={this.state.value} msgs={[...this.state.msgs, ...this.state.pdf]} handleOpen={this.handleOpen} onSearch={this.onSearch} />
        <MessageModal
          modal={this.state.modal}
          onClose={this.handleClose}
          modal_data={this.state.modal_data}
        />
        <AttachmentContainer
          modal={this.state.modal}
          handlePopover={this.handleClose}
          docs={this.state.modal_data}
        />
      </>
    );
  }
}

export default FfmDashboardContainer;