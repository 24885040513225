import React from 'react';
import FlightNotes from './FlightNotes';
import http from 'utils/http';
import { setDeleteAction } from 'store/actions/deleteAction';
import { connect } from 'react-redux';
import events from '../../../../../utils/evemts';
import cloneDeep from 'lodash/cloneDeep';
import ConfirmModalContainer from '../../../common/confirmModal/ConfirmModalContainer';
import cookies from 'utils/cookies';

class FlightNotesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flightId: null,
      notes: {},
      notesData: [],
      reply: {},
      showInput: false,
      type: null,
      noteId: null,
      replyTo: null,
      showEdit: false,
      viewPreview: false,
    };
    this.eventSource = null;
  }

  componentDidMount() {
    if (this.props.flight && this.props.flight.id) {
      {
        this.setState({ flightId: this.props.flight.id }, () => { this.fetchNotes(); this.startEvent(); });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.flight.id !== this.props.flight.id && this.props.flight.id) {
      this.eventSource && this.eventSource.close();
      this.setState({ flightId: this.props.flight.id }, () => { this.fetchNotes(); this.startEvent(); });
    }
  }

  componentWillUnmount() {
    this.eventSource && this.eventSource.close();
  }

  startEvent = () => {
    this.eventSource = events('flight', this.state.flightId);
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.action == 'add_note') {

        this.setState({ notesData: [...this.state.notesData, msg.data] }, () => { });
      }
      if (msg.action == 'add_reply') {
        let notesData = cloneDeep(this.state.notesData);
        let noteIndex = notesData.findIndex(n => n.id == msg.data.noteId);
        let reply = {};
        reply.id = msg.data.id;
        reply.note = msg.data.note;
        reply.user = msg.data.user;
        reply.created = msg.data.created;
        reply.userId = msg.data.userId;
        if (noteIndex > -1) {
          notesData[noteIndex] = {
            ...msg.data,
            ...notesData[noteIndex],
            replys: [...notesData[noteIndex].replys, reply]
          };
        }
        this.setState({ notesData: notesData }, () => { });
      }
      if (msg.action == 'update_note') {
        let notesData = cloneDeep(this.state.notesData);
        const index = notesData.findIndex(c => msg.data && c.id == msg.data.id);
        if (index > -1) {
          notesData[index] = { ...msg.data, };

          this.setState({
            notesData,
            noteId: null
          });
        }
      }
      if (msg.action == 'update_reply') {
        let notesData = cloneDeep(this.state.notesData);
        notesData.findIndex((obj, outerIndex) => {
          const innerIndex = obj.replys.findIndex(n => n.id == msg.data.id);
          if (innerIndex !== -1) {
            let reply = {
              id: msg.data.id,
              note: msg.data.note,
              userId: msg.data.userId,
            };

            let newReplys = [...notesData[outerIndex].replys];
            newReplys[innerIndex] = {
              ...reply,
              user: obj.replys[innerIndex].user
            };

            notesData[outerIndex] = {
              ...notesData[outerIndex],
              replys: newReplys,
            };
          }
        });
        this.setState({ notesData: notesData }, () => { });
      }
      if (msg.action == 'delete_note') {
        let notesData = cloneDeep(this.state.notesData);
        const index = notesData.findIndex(c => msg.data && c.id == msg.data.id);
        if (index > -1) {
          notesData.splice(index, 1);
          this.setState({ notesData });
        }
      }
      if (msg.action == 'delete_reply') {
        let notesData = cloneDeep(this.state.notesData);
        notesData.findIndex((obj) => {
          const innerIndex = obj.replys.findIndex(n => n.id == msg.data.id);
          if (innerIndex !== -1) {
            obj.replys.splice(innerIndex, 1);
          }
        });
        this.setState({ notesData });

      }
      if (msg.action == 'delete_doc') {
        let contract = cloneDeep(this.state.contract);
        const index = contract.findIndex(c => msg.data && c.id == msg.data.contractId);
        if (index > -1) {
          const index2 = contract[index].docs.findIndex(cr => msg.data && cr.id == msg.data.id);
          if (index2 > -1) {
            contract[index].docs.splice(index2, 1);
            this.setState({ contract });
          }
        }
      }
      if (msg.action == 'add_like') {
        let notesData = cloneDeep(this.state.notesData);
        let noteIndex = notesData.findIndex(n => n.id == msg.data.noteId);
        let like = {};
        like.id = msg.data.id;
        like.userId = msg.data.userId;
        if (noteIndex > -1) {
          notesData[noteIndex] = {
            ...msg.data,
            ...notesData[noteIndex],
            likes: [...notesData[noteIndex].likes, like]
          };
        }
        this.setState({ notesData: notesData }, () => {});
      }
      if (msg.action == 'delete_like') {
        let notesData = cloneDeep(this.state.notesData);
        let noteIndex = notesData.findIndex(n => n.id == msg.data.noteId);
        const innerIndex = notesData[noteIndex].likes.findIndex(n => n.id == msg.data.id);
        if (innerIndex !== -1) {
          notesData[noteIndex].likes.splice(innerIndex, 1);
        }
        this.setState({ notesData: notesData }, () => {});
      }
    };
  };

  onChangeNotes = (e, type) => {
    this.setState((prev) => ({
      [type]: {
        ...prev[type],
        [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
      }
    }));
  };

  onChangeLikes = (e, list, id) => {
    list.likedNote = e.target.checked;
    http.get(`/like/${id}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          //success
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  addNotes = async () => {
    // e.preventDefault();
    let formdata = { ...this.state.notes, };
    http.post(`/note/${this.state.flightId}`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ notes: { note: '' }, });
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  editNotes = async (id) => {
    // e.preventDefault();
    let formdata = { ...this.state.notes, };
    http.put(`/note/${id}`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ notes: { note: '' }, });
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  fetchNotes = () => {
    http.get(`/note/${this.state.flightId}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          if (data.data && data.data.length > 0) {
            this.setState({ notesData: data.data }, () => { 
              if(this.props.printFunction) {
                this.props.printFunction(this.state.notesData, 'note');
              }
              this.state.notesData.forEach((note) => {
                note.likedNote = note?.likes?.find((x) => (x?.user?.first_name + ' ' + x?.user?.last_name) == cookies.get('name')) ? true : false;
              });
              this.setState({ notesData: this.state.notesData  });
            });
           
          } else {
            this.setState({ notesData: [] });
          }
        }
      })
      .catch(error => {
        this.setState({ products: [] });
        console.error('Error fetching data:', error);
      });
  };

  addReply = async (id) => {
    let formdata = { ...this.state.reply, };
    http.post(`/reply/${id}`, formdata).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          noteId: 0,
          note: {},
          reply: { note: '' },
          replyTo: null,
        });
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  editReply = async (id) => {
    // e.preventDefault();
    let formdata = { note: this.state.reply.note };
    http.put(`/reply/${id}`, formdata).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          noteId: null,
          notes: {}
        });
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  handleOpen = (type, data = {}) => {
    this.setState({
      type,
      noteId: data.id,
      replyTo: data,
      notes: { ...data, },
      reply: { ...data, }
    });
  };

  deleteNote = (id) => {
    http.delete('/note/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };
  deleteReply = (id) => {
    http.delete('/reply/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };
  handleDelete = (type, id, anchorEl) => {
    type === 'note' ?
      this.props.setDelete({
        modal: true,
        id,
        anchorEl,
        onConfirm: () => this.deleteNote(id)
      }) :
      this.props.setDelete({
        modal: true,
        id,
        anchorEl,
        onConfirm: this.deleteReply(id)
      });
  };

  viewAttachment = (src = false) => {
    this.setState({ viewPreview: src, });
  };


  render() {
    return (
      <>
        <FlightNotes
          notes={this.state.notes}
          onChangeNotes={this.onChangeNotes}
          addNotes={this.addNotes}
          notesData={this.state.notesData}
          handleOpen={this.handleOpen}
          reply={this.state.reply}
          showInput={this.state.showInput}
          addReply={this.addReply}
          onChangeLikes={this.onChangeLikes}
          // type={this.state.type}
          noteId={this.state.noteId}
          editNotes={this.editNotes}
          replyTo={this.state.replyTo}
          editReply={this.editReply}
          showEdit={this.state.showEdit}
          handleDelete={this.handleDelete}
          viewPreview={this.state.viewPreview}
          viewAttachment={this.viewAttachment}
          viewAttach={this.state.viewAttachment}
          showReportModal={this.props.showReportModal} 
        />

        <ConfirmModalContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { flight: state.flight };
};

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightNotesContainer);
