import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { getStationDate } from 'utils/converter';

import moment from 'moment/moment';

const primaryBlue = '#092b4a';

const styles = StyleSheet.create({
  headerContainer: { marginTop: 10, },
  label: {
    fontSize: 8,
    paddingBottom: 5,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    fontFamily: 'NunitoRegular',
    color: primaryBlue,
  },
  flightNumberLabel: {
    fontSize: 12,
    paddingBottom: 3,
    lineHeight: 'normal',
    color: primaryBlue,
    fontFamily: 'NunitoRegular',
  },
  flightNumberData: {
    fontSize: 12,
    paddingBottom: 3,
    lineHeight: 'normal',
    color: primaryBlue,
    fontFamily: 'NunitoRegular',
  },
  data: {
    fontSize: 10,
    lineHeight: 'normal',
    color: '#808080',
    fontFamily: 'NunitoRegular',
    fontWeight: 'normal',
  },
  fSpaceBtw:{
    flexDirection: 'row',
    justifyContent: 'space-between', 
  },
  row:{
    flexDirection: 'row',
    backgroundColor: '#f0f0f0', 
    padding: 6,
  },
  directionRow:{ flexDirection: 'row', },
  info:{
    width: '15%',
    padding: 4,
  },
  infoLg:{
    width: '20%',
    padding: 4,
  },
  pb10: { paddingBottom: 10 }
});


const FlightInfo = (props) => {
  const { info, flight_gate, ctype } = props;

  return (
    <View style={styles.headerContainer}>
      <View style={[styles.fSpaceBtw, styles.pb10]}>
        <Text style={styles.flightNumberData}>{
          info?.airline?.code + info?.flight_no + '/' + moment(info?.flight_date).format('DDMMMYY')?.toUpperCase()}
        </Text>
        <Text style={styles.flightNumberLabel}>{ctype}</Text>
      </View>
      <View style={styles.row}>
        <View style={styles.info}>
          <Text style={styles.label}>AC Type</Text>
          <Text style={styles.data}>{info?.ac_type}</Text>
        </View>     
        <View style={styles.info}>
          <Text style={styles.label}>Registration</Text>
          <Text style={styles.data}>{info?.reg_no}</Text>
        </View>
        <View style={styles.info}>
          <Text style={styles.label}>Routing</Text>
          <Text style={styles.data}>{info?.org + '-' + info?.dest}</Text>
        </View>
        <View style={styles.infoLg}>
          <Text style={styles.label}>Departure</Text>
          <View style={styles.directionRow}>
            <Text style={styles.data}>{info?.act_dept ? 
              getStationDate(info?.org, info?.act_dept, 'DDMMMYY HHmm') : (info?.est_dept ? 
                getStationDate(info?.org, info?.est_dept, 'DDMMMYY HHmm') : (info?.schdl_dept ? 
                  getStationDate(info?.org, info?.schdl_dept, 'DDMMMYY HHmm') : null))}</Text>
            <Text style={styles.data}>{info?.act_dept ? ' (A)' : (info?.est_dept ? ' (E)' : ' (S)')}</Text>
          </View>
        </View>     
        <View style={styles.infoLg}>
          <Text style={styles.label}>Arrival</Text>
          <View style={styles.directionRow}>
            <Text style={styles.data}>{info?.act_arr ? 
              getStationDate(info?.dest, info?.act_arr, 'DDMMMYY HHmm') : (info?.est_arr ? 
                getStationDate(info?.dest, info?.est_arr, 'DDMMMYY HHmm') : (info?.schdl_arr ? 
                  getStationDate(info?.dest, info?.schdl_arr, 'DDMMMYY HHmm') : null))}</Text>
            <Text style={styles.data}>{info?.act_arr ? ' (A)' : (info?.est_arr ? ' (E)' : ' (S)')}</Text>
          </View>
        </View>      
        <View style={styles.info}>
          <Text style={styles.label}>Gate</Text>
          <Text style={styles.data}>{flight_gate}</Text>
        </View>
      </View>
    </View>
  );
};
  
export default FlightInfo;