import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const textColor = '#393939';
const borderColor = 'lightgrey';
const primaryBlue = '#092b4a';

const cellProperties = {
  padding: 2,
  paddingLeft: 4,
  fontSize: 10,
  color: textColor,
  height: '100%',
  whiteSpace: 'break-spaces',
  fontFamily: 'NunitoRegular',
};
const headProperties = {
  paddingRight: 5,
  paddingLeft: 5,
  fontSize: 10,
  color: primaryBlue,
  textTransform: 'uppercase',
  whiteSpace: 'break-spaces',
  fontFamily: 'NunitoMedium',
  textAlign: 'left',
};

const styles = StyleSheet.create({
  headerContainer: { marginTop: 24 },
  title: {
    fontSize: 9,
    textTransform: 'uppercase',
    backgroundColor: '#f0f0f0',
    color: primaryBlue,
    padding: '4px 35px',
    textAlign: 'center',
    marginBottom: 5,
    lineHeight: 'normal',
    fontFamily: 'NunitoMedium',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 20,
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: primaryBlue,
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    padding: '4px 2px',
  },
  header20: {
    ...headProperties,
    width: '20%',
  },
  cell20: {
    ...cellProperties,
    width: '20%',
  },
  header30: {
    ...headProperties,
    width: '30%',
  },
  cell30: {
    ...cellProperties,
    width: '30%',
  },
  noDataRow: {
    fontSize: 9,
    width: '100%',
    textAlign: 'center',
    padding: 4,
    color: textColor,
    fontFamily: 'NunitoRegular',
  },
});

const UldDetailsTable = (props) => {

  const { awbs } = props;

  return (
    <>
      <View style={styles.headerContainer}>
        {/* <Text style={styles.title}>ULD Details</Text> */}
        <View style={styles.tableContainer} wrap={true}>
          <View style={styles.container}>
            <Text style={styles.header30}>AWB Number</Text>
            <Text style={styles.header20}>Pcs</Text>
            <Text style={styles.header20}>Wgt</Text>
            <Text style={styles.header20}>O-D</Text>
            <Text style={styles.header30}>SPLs</Text>
            <Text style={styles.header30}>Remarks</Text>
          </View>

          {awbs.map((awb, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.cell30}>{awb.awb_no}</Text>
              <Text style={styles.cell20}>{awb.pcs}</Text>
              <Text style={styles.cell20}>{awb.wgt}</Text>
              <Text style={styles.cell20}>{awb.org}-{awb.dest}</Text>
              <Text style={styles.cell30}>{awb.spl_code}</Text>
              <Text style={styles.cell30}>{awb.remark}</Text>
            </View>
          ))}
        </View>
      </View>

    </>
  );
};
  
export default UldDetailsTable;