import React from 'react';
import { Avatar, Button, Checkbox, Dialog, DialogContent, Grid, IconButton, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import { StyledCard, StyledCardTitle, CustomTextField, InputProps, CustomDialogTitle, CustomButton, CustomDialogAction } from '../../../../../custom/components';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SendIcon from '../../../../../assets/images/actionIcons/paperPlane.svg';
import styles from '../../../../../custom/style.module.css';
import flightStyles from './flightnotes.module.css';
import flightMainStyles from '../../flightsfolder.module.css';
import theme from 'custom/theme';
import { BrowseIcon, DateFormat, DeleteIcon, EditIcon } from 'custom/constants';
import ReplyIcon from '@mui/icons-material/Reply';
import environment from '../../../../../custom/environments';
import cookies from 'utils/cookies';
import { dateTolocale } from 'utils/converter';
import { Image, ThumbUpOffAlt } from '@mui/icons-material';
import PDFImg from '../../../../../assets/images/pdf.svg';
import ExcelImg from '../../../../../assets/images/greenExcel.svg';
import PDFSlider from 'components/pages/common/PDFSlider';

const FlightNotes = (props) => {
  const { showReportModal } = props;

  return (
    <>
      <Grid container spacing={3}>
        {!showReportModal && 
        <Grid item xs={12}>
          <StyledCard>
            {/* <StyledCard className={flightMainStyles.hidePrint}> */}
            <StyledCardTitle gutterBottom htmlFor='notes' component='div'>Add New Notes</StyledCardTitle>
            <Stack direction='row' spacing={3}>
              <CustomTextField
                {...InputProps}
                fullWidth
                id='notes'
                placeholder='Notes'
                type='text'
                name='note'
                autoFocus
                value={props.noteId ? '' : props.notes.note}
                onChange={(e) => props.onChangeNotes(e, 'notes')}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Tooltip title={props.notes?.file?.name}>
                        <span className={styles.fileName}>{props.notes?.file?.name}</span>
                      </Tooltip>
                      <IconButton size='small'>
                        <input
                          accept='application/pdf,image/*'
                          style={{ display: 'none' }}
                          id='file'
                          type='file'
                          name='file'
                          onChange={(e) => props.onChangeNotes(e, 'notes')}
                        />
                        <label htmlFor='file' className={`${styles.fs0} ${styles.pointer}`}>
                          <BrowseIcon />
                        </label>
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <IconButton className={`${flightStyles.addNotes} ${styles.p0}`} onClick={props.addNotes}>
                <img src={SendIcon} alt='send' />
              </IconButton>
            </Stack>
          </StyledCard>
        </Grid>}
        <Grid item xs={12}>
          <StyledCard>
            <StyledCardTitle gutterBottom component='div'>Notes</StyledCardTitle>
            <Stack spacing={4} className={`${flightStyles.noteWrapper}`}>
              {props.notesData != [] && props.notesData.map((list) => {
                return (
                  <div key={list?.id}>
                    <Stack direction='row' alignItems={'center'}>
                      <div className={`${flightStyles.notesAdded}`}>
                        <div className={styles.fSpaceBtw}>
                          <Stack direction='row' spacing={2} alignItems={'center'}>
                            <Avatar sx={{
                              width: 24,
                              height: 24,
                              bgcolor: theme.palette.grey[200]
                            }}
                            >
                              <Typography variant='caption' component='span' fontWeight={'bold'} color={theme.palette.primary.main}>{list?.user?.first_name?.charAt(0)}</Typography>
                            </Avatar>
                            <Typography variant='caption' component='span' fontSize={'0.72rem'} color={theme.palette.primary.main} fontWeight={'bold'} sx={{ marginLeft: '40px' }}>{list?.user?.first_name} {list?.user?.last_name} | {dateTolocale(list.created, `${DateFormat} HHmm`)?.toUpperCase()}</Typography>
                          </Stack>
                          {!showReportModal &&
                          list?.userId == cookies.get('user') ?
                            <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
                              <EditIcon onClick={() => { props.handleOpen('note_edit', list); }} />
                              <DeleteIcon onClick={(e) => { props.handleDelete('note', list?.id, e.target); }} />
                            </Stack>
                            : null}
                        </div>
                        <div className={flightStyles.ml40}>
                          {list?.id === props.noteId ?
                            <><CustomTextField
                              {...InputProps}
                              variant='standard'
                              fullWidth
                              id='reply'
                              placeholder='Edit note'
                              type='text'
                              name='note'
                              autoFocus
                              value={props.notes.note}
                              onChange={(e) => props.onChangeNotes(e, 'notes')}
                              InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <Tooltip title={props.notes?.file?.name}>
                                      <span className={styles.fileName}>{props.notes?.file?.name}</span>
                                    </Tooltip>
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                      <IconButton size='small' disabled className={styles.disabledImg}>
                                        <input
                                          accept='image/*'
                                          style={{ display: 'none' }}
                                          id='file_edit'
                                          type='file'
                                          name='file'
                                          onChange={(e) => props.onChangeNotes(e, 'notes')}
                                        />
                                        <label htmlFor='file_edit' className={`${styles.fs0} ${styles.pointer}`}>
                                          <BrowseIcon />
                                        </label>
                                      </IconButton>
                                      <>&nbsp; | &nbsp;</>
                                      <IconButton className={flightStyles.smNoteIcons} onClick={() => props.editNotes(list.id)}>
                                        <img src={SendIcon} alt='attachment' />
                                      </IconButton>
                                    </Stack>
                                  </InputAdornment>
                                )
                              }}
                            />
                            </>
                            : <Typography variant='caption' component='p' color={theme.palette.primary.main}>{list?.note}</Typography>
                          }
                        </div>
                      </div>
                    </Stack>
                    <div className={flightStyles.attWrapper}>
                      <div className={styles.positionRelative}>
                        {list?.src &&
                        <Button onClick={() => props.viewAttachment(list?.src)} className={flightStyles.viewAtt}>
                          {list.src.endsWith('pdf') || list.src.endsWith('xls') ?
                            <div className={`${flightStyles.pdfWrapper} ${styles.mt3}`}>
                              {list.src.endsWith('pdf') ? <img src={PDFImg} alt='pdf' /> :  
                                list.src.endsWith('xls') || list.src.endsWith('xlsx') ? <img src={ExcelImg} alt='excel' className={flightStyles.ExcelImg} /> : <Image color='disabled' className={flightStyles.ExcelImg} />}
                            </div>
                            :
                            <img src={environment.apiService + 'document?key=' + list?.src} alt='attachment'  
                              className={`${styles.w100} ${flightStyles.attImg}`} crossOrigin='use-credentials' />
                          }
                        </Button>}
                      </div>
                      <div className={`${showReportModal ? flightMainStyles.hideReport : styles.fSpaceBtw}`}>
                        <div>
                          <Typography variant='caption' component='span' fontSize={'9px'} fontWeight={'bold'} className={flightStyles.likesNumber}>{!list?.likes ? ' ' : list?.likes?.length}</Typography>
                          <Tooltip title='Acknowledge'>
                            <Checkbox
                              inputProps={{ 'aria-label': 'likes' }}
                              icon={<ThumbUpOffAlt color='primary' fontSize='small' />}
                              checkedIcon={<ThumbUpIcon color='primary' fontSize='small' />}
                              onChange={(e) => props.onChangeLikes(e, list, list?.id)}
                              checked={ Boolean(list?.likedNote)}
                            />
                          </Tooltip>
                        </div>
                        <IconButton onClick={()=> props.handleOpen('reply_add', list?.id)}>
                          <Tooltip title='Reply'>
                            <ReplyIcon color='primary' fontSize='small' />
                          </Tooltip>
                        </IconButton>
                      </div>
                    </div>

                    {/* REPLY */}
                    {
                      list?.id === props.replyTo ?
                        <Stack direction='row' spacing={3} marginTop={3}>
                          <div className={`${flightStyles.notesAdded} ${flightStyles.replyWrapper} ${styles.mlauto}`}>
                            <CustomTextField
                              {...InputProps}
                              variant='standard'
                              fullWidth
                              id='reply'
                              placeholder='Reply'
                              type='text'
                              name='note'
                              autoFocus
                              value={props.reply.note}
                              onChange={(e) => props.onChangeNotes(e, 'reply')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end' >
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                      {/* <div className={flightStyles.smNoteIcons}>
                                    <AttachFileIcon fontSize='small' className={`${styles.pointer} ${flightStyles.attInputIcon}`} />
                                  </div>
                                  <>
                                    <input
                                      accept='image/*'
                                      style={{ display: 'none' }}
                                      id='note-file'
                                      type='file'
                                      name='reply_file'
                                      onChange={(e) => props.onChangeNotes(e, 'reply')}
                                    />
                                    <label htmlFor='note-file'>
                                      <div className={flightStyles.smNoteIcons}>
                                        <CameraIcon className={`${styles.pointer} ${flightStyles.attInputIcon} ${flightStyles.cameraIcon}`} />
                                      </div>
                                    </label>
                                  </> */}
                                      {/* <>&nbsp; | &nbsp;</> */}
                                      <IconButton className={flightStyles.smNoteIcons} onClick={() => props.addReply(list.id)}>
                                        <img src={SendIcon} alt='attachment' />
                                      </IconButton>
                                    </Stack>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </div>
                        </Stack>
                        : null
                    }
                    {/* SHOW REPLY */}
                    <Stack spacing={2} alignItems={'flex-end'} className={styles.mt3}>
                      {list?.replys && list?.replys.map((item, index) => (
                        <>
                          <div key={index} className={`${flightStyles.notesAdded} ${flightStyles.replyWrapper}`}>
                            <Stack direction='row' justifyContent='space-between'>
                              <div>
                                <Stack direction='row' spacing={2} alignItems={'center'}>
                                  <Avatar sx={{
                                    width: 24,
                                    height: 24,
                                    bgcolor: theme.palette.grey[200]
                                  }}
                                  >
                                    <Typography variant='caption' component='span' fontWeight={'bold'} color={theme.palette.primary.main}>{item?.user?.first_name?.charAt(0)}</Typography>
                                  </Avatar>
                                  <Typography variant='caption' component='span' fontSize={'0.72rem'} color={theme.palette.primary.main} fontWeight={'bold'} sx={{ marginLeft: '40px' }}>{item.user?.first_name} {item.user?.last_name} | {dateTolocale(item.created, `${DateFormat} HHmm`)?.toUpperCase()}</Typography>
                                </Stack>
                              </div>
                              {!showReportModal &&
                              item?.userId == cookies.get('user') ?
                                <Stack direction='row' spacing={2} justifyContent='center' className={`${styles.actionIcons} ${flightMainStyles.hidePrint}`}>
                                  <EditIcon onClick={() => { props.handleOpen('reply_edit', item); }} />
                                  <DeleteIcon onClick={(e) => { props.handleDelete('reply', item.id, e.target); }} />
                                </Stack>
                                : null}
                            </Stack>
                            <div className={flightStyles.ml40}>
                              {item.id === props.noteId ?
                                <CustomTextField
                                  {...InputProps}
                                  variant='standard'
                                  fullWidth
                                  id='reply'
                                  placeholder='Reply'
                                  type='text'
                                  name='note'
                                  value={props.reply.note}
                                  onChange={(e) => props.onChangeNotes(e, 'reply')}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position='end' >
                                        <Stack direction='row' spacing={1} alignItems='center'>
                                          {/* <div className={flightStyles.smNoteIcons}>
                                            <AttachFileIcon fontSize='small' className={`${styles.pointer} ${flightStyles.attInputIcon}`} />
                                          </div>
                                          <>
                                            <input
                                              accept='image/*'
                                              style={{ display: 'none' }}
                                              id='note-file'
                                              type='file'
                                              name='reply_file'
                                              onChange={(e) => props.onChangeNotes(e, 'reply')}
                                            />
                                            <label htmlFor='note-file'>
                                              <div className={flightStyles.smNoteIcons}>
                                                <CameraIcon className={`${styles.pointer} ${flightStyles.attInputIcon} ${flightStyles.cameraIcon}`} />
                                              </div>
                                            </label>
                                          </> */}
                                          {/* <span>x</span> */}
                                          <>&nbsp; | &nbsp;</>
                                          <IconButton className={flightStyles.smNoteIcons} onClick={() => props.editReply(item.id)}>
                                            <img src={SendIcon} alt='attachment' />
                                          </IconButton>
                                        </Stack>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                                : <Typography variant='caption' component='p' color={theme.palette.primary.main}>{item.note}</Typography>
                              }
                            </div>
                          </div>
                        </>
                      ))}
                    </Stack>
                  </div>
                );
              }
              )
              }
            </Stack>
          </StyledCard>
        </Grid>
      </Grid>

      <Dialog
        open={props.viewPreview}
        onClose={()=>props.viewAttachment(false)}
        maxWidth='sm'
        fullWidth
      >
        <CustomDialogTitle className={styles.mb3}>
          {'Attachment'}
        </CustomDialogTitle>
        <DialogContent>
          { props.viewPreview && props.viewPreview.split('.')[1] === 'pdf' ?
            <PDFSlider file={environment.apiService + 'document?key=' + props.viewPreview } />
            :
            <img src={environment.apiService + 'document?key=' + props.viewPreview} alt='attachment' className={styles.w100} crossOrigin='use-credentials' />} 
        </DialogContent>
        <CustomDialogAction>
          <CustomButton onClick={() => props.viewAttachment(false)} autoFocus variant='contained'>Close</CustomButton>
        </CustomDialogAction>
      </Dialog>
    </>
  );
};

export default FlightNotes;