import React from 'react';
import {
  Grid, Typography, Box,
  Stack,
} from '@mui/material';
import styles from 'custom/style.module.css';
import docStyles from './docsUpload.module.css';
import { CustomButton, StyledCard } from 'custom/components';
import { loaderRef } from 'components/header/loader';
import { Upload, CheckCircle } from '@mui/icons-material';
import { FileUploader } from 'react-drag-drop-files';


const DocUpload = (props) => {
  const fileTypes = ['PDF'];

  return (
    <Box sx={{
      width: 'clamp(200px, 100%, 600px)',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100vh - 48px)',
    }}>
      <div ref={loaderRef}></div>
      <Typography variant='h6' component='h6' color='primary' textAlign='center' fontSize={'2rem'} pb={'2px'} mb={'50px'} borderBottom={'2px solid #59b3629e'}>
        Upload Document
      </Typography>

      <StyledCard className={`${styles.w100}`} sx={{ padding: 'clamp(1rem, 15%, 3rem) 5%' }}>

        {!props.submit && (<Grid container justifyContent={'center'} spacing={4}>
          <Grid item xs={12} sm={10}>
            <Typography variant='subtitle2' component={'div'} color={'primary'} fontSize={'0.875rem'} textAlign={'center'}
            >Please upload the requested <b> {props.type == 'AWB' ?
                'Airway Bill' : (props.type == 'FFM' ? 'Flight Manifest' : 'Other')
              } </b> document.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <div>
              <FileUploader
                classes={docStyles.customFileUploader}
                multiple={false}
                handleChange={props.handleDragAndDrop}
                name='file'
                types={fileTypes}
              >
                <div className='custom-icon-container'>
                  <Upload className={docStyles.uploadSvg} />
                  <Typography variant='caption' component={'div'} color={'text.secondary'} fontSize={'0.75rem'} textAlign={'center'}>
                     Drag & Drop or Click to Upload Files
                  </Typography>
                </div>
              </FileUploader>
              <Stack spacing={1} direction={{
                sm: 'row',
                xs: 'column',
              }} justifyContent={'space-between'} alignItems={'center'} mt={{
                sm: '4px',
                xs: '16px',
              }}>
                <small><b>File name:&nbsp;</b>
                  {props.fields?.file ? `${props.fields?.file.name}` : 'No files uploaded yet'}
                </small>
                <Typography variant='caption' component={'div'} color={'text.secondary'} fontSize={'0.75rem'} textAlign={'center'}>
                  {`Accepted File Type: ${fileTypes}`}
                </Typography>
              </Stack>
            </div>

            <Typography variant='caption' component={'div'} color={'error'} fontSize={'0.75rem'} textAlign={'center'}>
              {props.fieldsErrors['file']}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <CustomButton variant='contained' className={styles.w100} onClick={props.onSubmit}> Submit </CustomButton>
          </Grid>
        </Grid>)}

        {/* on success */}
        {props.submit && (<Stack spacing={3} justifyContent={'center'} alignItems={'center'}>
          <CheckCircle sx={{ fontSize: 60 }} color='secondary' className={styles.mAuto} />
          <Typography variant='h6' component={'h6'} color={'primary'} textAlign={'center'}>
            Successful !!
          </Typography>
          <Typography fontSize={'0.9rem'} textAlign={'center'}>
            Requested document is successfully uploaded.
          </Typography>
        </Stack>)}

      </StyledCard>
    </Box>
  );
};
export default DocUpload;