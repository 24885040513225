import React from 'react';
import { Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Popover, TextField, Typography } from '@mui/material';
import styles from 'custom/style.module.css';
import noteStyles from './notes.module.css';
import SendIcon from '../../../assets/images/actionIcons/paperPlane.svg';
import { PopoverContent } from 'custom/components';
import { DateTimeFormat, statusColor } from 'custom/constants';
import { dateTolocale } from 'utils/converter';

const NOTE_LENGTH = 50;

const AddNotes = (props) => {
  const { popup, anchorEl, closePopup, notes } = props;

  return (
    <div>
      <Popover
        open={popup === 'notes'}
        anchorEl={anchorEl}
        onClose={() => closePopup()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <PopoverContent className={styles.p2} sx={{ width: 300 }}>
          {/* <div className={noteStyles.noteHead}>
            <CloseIconBtn fontSize='small' className={styles.pointer} onClick={() => closePopup()} />
          </div> */}
          {!props.isRemarks && 
          <div className={noteStyles.notesWrapper}>
            <TextField
              placeholder='Add Note'
              multiline
              rows={5}
              variant='standard'
              autoFocus
              name='note'
              fullWidth
              onChange={(e) => props.handleNotes(e)}
              // onBlurCapture={props.handleNoteBlur}
              value={props.noteFields.note}
              InputProps={{
                disableUnderline: true,
                classes: { input: noteStyles.ipFontSize }, 
              }}
            />
            <IconButton className={`${styles.sendIcon} ${styles.p0} ${styles.dBlock} ${styles.mlauto}`} onClick={props.addNote}>
              <img src={SendIcon} alt='send' width={'20px'} height={'auto'} />
            </IconButton>
          </div>
          }
          <div>
            <List sx={{
              maxHeight: 300,
              overflow: 'auto',
              marginRight: '-10px',
              paddingRIght: '10px',
              marginTop: '10px',
              width: '100%',
            }}>
              {/* {Array.from({ length : 3 }).map((_, index) => ( */}
              {notes.length > 0 ? notes.map((note, index) => {
                let notes = props.isRemarks ? note?.remark : note?.note;
                let created = props.isRemarks ? note?.date : note?.created;
                return(
                  <ListItem key={index} className={`${noteStyles.historyListItem} ${noteStyles.noteListItem}`}>
                    <ListItemAvatar className={styles.mr2}>
                      {props.isRemarks?
                        <Typography variant='span' color='white' textTransform={'uppercase'} fontSize='10px' bgcolor={statusColor(note.status)} fontWeight={800} px={0.5} py={0.25} borderRadius={'4px'} lineHeight={'normal'} minWidth={'70px'} display={'block'} textAlign={'center'}>
                          {note.status}
                        </Typography>
                        :                    
                        <Typography variant='span' color='white' textTransform={'uppercase'} fontSize='10px' bgcolor={'rebeccapurple'} fontWeight={800} px={0.5} py={0.25} borderRadius={'4px'} lineHeight={'normal'} minWidth={'70px'} display={'block'} textAlign={'center'}>
                          {note?.user?.role}
                        </Typography>
                      }
                    </ListItemAvatar>
                    <ListItemText
                      className={noteStyles.historyListText}
                      primaryTypographyProps={{
                        fontSize: 12,
                        color: 'primary.main',
                        fontWeight: 500,
                      }}
                      secondaryTypographyProps={{ fontSize: 12 }}
                      primary={<>
                        <span className={styles.overflowBreak}>{props.showMoreNote[index] ? 
                          (notes?.length > NOTE_LENGTH ? notes?.slice(0, NOTE_LENGTH) + '...' : notes) : notes}</span>
                        {notes?.length > NOTE_LENGTH &&
                      <Button onClick={() => props.handleReadMore(index)} size='small' variant='text'>
                        <Typography fontStyle={'italic'} component='span' fontSize={12} color={'text.secondary'} textTransform={'capitalize'} >
                           &nbsp;Read {props.showMoreNote[index] ? 'more' : 'less'}</Typography>
                      </Button>
                        }</>}
                      secondary={`${note?.user?.first_name} ${note?.user?.last_name} | ${dateTolocale(created, DateTimeFormat)?.toUpperCase()}`} />
                    {/* /> */}
                  </ListItem>
                );}) : null}
            </List>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};
export default AddNotes;