import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { dateTolocale } from 'utils/converter';

const textColor = '#393939';
const borderColor = 'lightgrey';
const primaryBlue = '#092b4a';

const cellProperties = {
  padding: 2,
  paddingLeft: 4,
  fontSize: 8,
  color: textColor,
  height: '100%',
  whiteSpace: 'break-spaces',
  fontFamily: 'NunitoRegular',
};
const headProperties = {
  paddingRight: 5,
  paddingLeft: 5,
  fontSize: 8,
  color: primaryBlue,
  textTransform: 'uppercase',
  whiteSpace: 'break-spaces',
  fontFamily: 'NunitoMedium',
};

const styles = StyleSheet.create({
  headerContainer: { marginTop: 24 },
  label: {
    fontSize: 10,
    paddingBottom: 3,
    lineHeight: 'normal',
  },
  title: {
    fontSize: 9,
    textTransform: 'uppercase',
    backgroundColor: '#f0f0f0',
    color: primaryBlue,
    padding: '4px 35px',
    textAlign: 'center',
    marginBottom: 5,
    lineHeight: 'normal',
    fontFamily: 'NunitoMedium',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 20,
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: primaryBlue,
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    padding: 2,
  },
  header20: {
    ...headProperties,
    width: '20%',
  },
  cell20: {
    ...cellProperties,
    width: '20%',
  },
  header30: {
    ...headProperties,
    width: '30%',
  },
  cell30: {
    ...cellProperties,
    width: '30%',
  },
  noDataRow: {
    fontSize: 9,
    width: '100%',
    textAlign: 'center',
    padding: 4,
    color: textColor,
    fontFamily: 'NunitoRegular',
  },
});

const FlightHistory = (props) => {
  const { history } = props;

  return (
    <>
      <View style={styles.headerContainer}>
        <Text style={styles.title}>History</Text>
        
        <View style={styles.tableContainer} wrap={true}>
          <View style={styles.container}>
            <Text style={styles.header20}>Date & Time</Text>
            <Text style={styles.header20}>Type</Text>
            <Text style={styles.header30}>Details</Text>
            <Text style={styles.header30}>User</Text>
          </View>

          {history && history.length > 0 ?
            history?.map( item =>
              <View style={styles.tableRow} key={item.id}>
                <Text style={styles.cell20}>{dateTolocale(item.created, 'DD MMM YYYY HH:mm')?.toUpperCase()}</Text>
                <Text style={styles.cell20}>{item.type}</Text>
                <Text style={styles.cell30}>{item.operation_desc}</Text>
                <Text style={styles.cell30}>{item?.user?.first_name + ' ' + item?.user?.last_name + ` (${item?.user?.role})`}</Text>
              </View>
            ) : <View  style={styles.noDataRow}>
              <Text>No Data</Text>
            </View>}

        </View>
      </View>

    </>
  );
};
  
export default FlightHistory;