import React from 'react';
import { Grid, IconButton, Stack, Dialog } from '@mui/material';
import awbStyle from './attachment.module.css';
import styles from '../../../../../custom/style.module.css';
import { CustomDialogContent, CustomDialogTitle } from '../../../../../custom/components';
import { CloseIconBtn, DocIcon, DownloadIcon } from 'custom/constants';
// import http from 'utils/http';
import env from '../../../../../custom/environments';
import PDFSlider from '../../../common/PDFSlider';
import { dateTolocale } from 'utils/converter';

class AttachmentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docs: [],
      displayedDocIndex: 0
    };
  }

  componentDidMount() {
    this.setState({ docs: this.props.docs });
    // this.fetchDocs();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.docs !== this.props.docs) {
      this.setState({ docs: this.props.docs });
    }
  }

  // fetchDocs = () => {
  //   http.get(`/archive/doc?id=${this.props.id}&type=${this.props.type}`).then(({ data }) => {
  //     if (data.status === 'success') {
  //       this.setState({ docs: data.data });
  //     }
  //   });
  // };


  handleDownloadPDF = (name, src) => {
    // Perform the download logic here
    // For example, you can use the `fetch` API to download the PDF
    fetch(`${env.apiService}document?key=${src}`)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        // Create a temporary anchor element to trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = name || 'UNKNOWN'; // Set the file name for the download
        downloadLink.click();
      })
      .catch((error) => {
        console.error('Error downloading PDF:', error);
      });
  };

  handlePrintPDF = (src) => {
    // Perform the print logic here
    const pdfUrl = `${env.apiService}document?key=${src}`;
    const pdfWindow = window.open(pdfUrl);
    pdfWindow.onload = () => {
      pdfWindow.print();
    };
  };

  render() {
    const { docs } = this.state;
    return (
      <Dialog aria-labelledby='form-dialog-title' open={this.props.modal === 'attached_docs'} onClose={this.props.onClose} fullWidth maxWidth={'lg'}>
        <CustomDialogTitle id='form-dialog-title'>  Attached Documents
          <CloseIconBtn onClick={() => this.props.handlePopover(null, false, null)} />
        </CustomDialogTitle>
        <CustomDialogContent>
          <Grid container spacing={3}>
            {docs && docs?.length > 0 && docs?.map((doc) => {
              const extension = doc?.src?.split('.').pop().toLowerCase();
              const image = ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension);
              return (
                <Grid key={doc.id} item container xs={12}>
                  <Grid item xs={3}>
                    <Stack className={styles.pl5} spacing={0.5}>
                      <div>
                        <small className={awbStyle.docDetails}>Document Type: </small>
                        <small>{doc?.type == 'AWB' ? 'Air Waybill' : (doc?.type == 'FFM' ? 'Flight Manifest' : 'Other')}</small>
                      </div>
                      {doc?.type == 'OTHER' && (<div>
                        <small className={awbStyle.docDetails}>Document Name</small>
                        <small>{doc?.name}</small>
                      </div>)}

                      <div>
                        <small className={awbStyle.docDetails}>{doc?.type == 'OTHER' ? 'Filed By' : 'Verified By'}:</small>
                        <small>{doc?.verified_by ? `${doc.verified_by.first_name} ${doc.verified_by.last_name} ${!doc?.verified_by?.master ? '(' + doc?.verified_by?.app + ')' : ''}` : ''}</small>
                      </div>
                      <div>
                        <small className={awbStyle.docDetails}>{doc?.type == 'OTHER' ? 'Verified' : ''} Date/Time:</small>
                        <small>{doc?.verified_date ? dateTolocale(
                          doc?.verified_date, 'DDMMMYY HH:mm')
                          .toUpperCase() : ''}</small>
                      </div>
                      <div>
                        <small className={awbStyle.docDetails}>Pages:</small>
                        <small>{doc?.numPages}</small>
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item xs={7}>
                    {image ? <img src={env.apiService + 'document?key=' + doc.src} alt='attachment' className={styles.w100} crossOrigin='use-credentials' /> : <PDFSlider file={env.apiService + 'document?key=' + doc.src+ `&trypod=${doc?.trypod? true : false}`} />}
                  </Grid>
                  <Grid item xs={2}>
                    <Stack direction='row' spacing={5} justifyContent='center'>
                      <IconButton onClick={() => this.handleDownloadPDF(doc.name, doc.src)} >
                        <DownloadIcon />
                      </IconButton>
                      <IconButton onClick={() => this.handlePrintPDF(doc.src)}>
                        <DocIcon />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>);
            })}
          </Grid>
        </CustomDialogContent>
      </Dialog>
    );
  }
}

export default AttachmentContainer;
