import React from 'react';
import { Stack, Grid, InputAdornment, IconButton, MenuItem, Box, FormControl } from '@mui/material';
import { StyledCard, StyledCardTitle, CustomInputLabel, CustomDatePicker, CustomSelect, CustomSearch, StyledDataGrid, DataGridProps, SelectInputProps, InputProps, } from '../../../../../custom/components';
import styles from '../../../../../custom/style.module.css';
import fltStyles from './flightmanifest.module.css';
import { AttachFile as AttachFileIcon, Search } from '@mui/icons-material';
import dayjs from 'dayjs';
import moment from 'moment';
import { DateFormat } from 'custom/constants';

const FlightManifest = (props) => {
  const columns = [
    {
      field: 'flight_no',
      headerName: 'Flight No.',
      type: 'text',
      minWidth: 80,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.airline?.code + row?.flight_no;
      }
    },
    {
      field: 'flight_date',
      headerName: 'Flight Date',
      type: 'text',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row?.flight_date).format(DateFormat)?.toUpperCase();  
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      align: 'center',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <div onClick={() => props.handlePopover('attached_docs', true, row?.docs)}>
              <AttachFileIcon fontSize='small' className={`${styles.pointer} ${fltStyles.attIcon}`} />
            </div>
          </Stack>
        );
      }
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledCard>
          <Stack direction='row' justifyContent={'space-between'}>
            <StyledCardTitle gutterBottom variant='' component='div'>Filter Flight List</StyledCardTitle>
            <CustomSearch
              id='search'
              type='search'
              placeholder='Enter Flight Number'
              name='awb'
              className={`${styles.brInput} ${styles.width245}`}
              value={props.searchOptions.search}
              onChange={(e) => props.handleChange(e, 'search')}
              InputProps={{
                classes: { input: styles.pr0 },
                endAdornment: <InputAdornment position='end'>
                  <IconButton
                    size='small'
                    aria-label='search'
                    edge='end'
                    onClick={props.search}
                  >
                    <Search fontSize='inherit' />
                  </IconButton>
                </InputAdornment>,
              }}
              onKeyDown={
                (e) => {
                  if (e.key === 'Enter') {
                    props.search(true);
                  }
                }
              }
            />
          </Stack>
          
          <Stack direction='row'>
            <Grid container spacing={2} columnSpacing={4}>
              <Grid item xs={12} sm={6} md>
                <CustomInputLabel htmlFor='' aria-label='Enter from date'>From</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      placeholder: 'From Date',
                      fullWidth: true
                    },
                  }}
                  className={styles.w100}
                  format='DD MMM YYYY'
                  name='from'
                  value={dayjs(props.searchOptions.from)}
                  // helperText={}
                  onChange={(e) => props.handleChange(e, 'from')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md>
                <CustomInputLabel htmlFor='' aria-label='Enter to date'>To</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      placeholder: 'To Date',
                      fullWidth: true
                    },
                  }}
                  className={styles.w100}
                  format='DD MMM YYYY'
                  name='to'
                  value={dayjs(props.searchOptions.to)}
                  // helperText={}
                  onChange={(e) => props.handleChange(e, 'to')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md>
                <CustomInputLabel id='airline-label' aria-label='Select airline'>Airline</CustomInputLabel>
                <FormControl fullWidth>
                  <CustomSelect
                    {...SelectInputProps}
                    fullWidth
                    id='airline'
                    labelId='airline-label'
                    name='airline'
                    value={props.filter.airline}
                    onChange={props.onChangefilter}
                  >
                    <MenuItem value=''>All</MenuItem>
                    {props.airline && props.airline.map(a => {
                      return (<MenuItem key={a} value={a} >{a}</MenuItem>);
                    })}
                  </CustomSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md>
                <CustomInputLabel id='flight-no-label' aria-label='Enter flight number '>Flight Number</CustomInputLabel>
                <FormControl fullWidth>
                  <CustomSelect
                    {...SelectInputProps}
                    fullWidth
                    id='flight'
                    labelId='flight-no-label'
                    name='flight'
                    value={props.filter.flight}
                    onChange={props.onChangefilter}
                  >
                    <MenuItem value=''>All</MenuItem>
                    {props.flight && props.flight.map(f => {
                      if (!props.filter.airline || f.substring(0, 2) === props.filter.airline)
                        return (<MenuItem key={f} value={f}>{f}</MenuItem>);
                    })}
                  </CustomSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={1} lg={4} className={styles.sqIconsContainer}>
                <CustomInputLabel className={styles.colorTransparent}>-</CustomInputLabel>
                <IconButton disableRipple onClick={() => props.search()} className={styles.p0}>
                  <Search fontSize='1.7rem' classes={{ root: `${styles.sqIcons} ${styles.mb0}` }} />
                </IconButton>
              </Grid>
            </Grid>
          </Stack>
        </StyledCard>
      </Grid>

      <Grid item xs={12}>
        <StyledCard className={styles.positionRelative}>
          <div className={`${styles.fSpaceBtw} ${styles.mb3}`}>
            <StyledCardTitle gutterBottom variant='' component='div' className={styles.pb0}>Flights</StyledCardTitle>
            {/* <CustomSearch
              id='search'
              name='awb'
              value={props.searchOptions.awb}
              variant='outlined'
              size='small'
              placeholder='Enter Flight Number'
              onChange={props.onChangefilter}
              type='search'
              className={styles.brInput}
              InputProps={{
                endAdornment: <InputAdornment position='end'>
                  <IconButton
                    size='small'
                    aria-label='search'
                    edge='end'
                    onClick={() => props.onChangefilter({
                      target: {
                        name: 'awb_search',
                        value: ''
                      }
                    })}
                  >
                    <Search fontSize='inherit' />
                  </IconButton>
                </InputAdornment>,
              }}
              InputLabelProps={{ shrink: false }}
            /> */}
          </div>
          <div className={styles.tableWrapper}>
            <Box className={`${styles.tableOuter} ${fltStyles.fltTableOuter}`}>
              <StyledDataGrid
                {...DataGridProps}
                rows={props.data}
                columns={columns}
                param={props.data?.length}
              />
            </Box>
          </div>
        </StyledCard>
      </Grid>

    </Grid>
  );
};

export default FlightManifest;
