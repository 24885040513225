import React from 'react';
import MyFlights from './MyFlights';
import { connect } from 'react-redux';
import { setDeleteAction } from 'store/actions/deleteAction';
import cookies from 'utils/cookies';
import http from 'utils/http';
import moment from 'moment-timezone';
import { dateTolocale, dateToUTC, getLocatDate } from 'utils/converter';
import events from 'utils/evemts';
import airportTimezone from 'airport-timezone';
import ConfirmModalContainer from '../common/confirmModal/ConfirmModalContainer';

const station = cookies.get('station_code') || null;
class MyFlightsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      action: null,
      airline: [],
      flightData: [],
      flight: [],
      flightDetails: [],
      filteredData: [],
      showflightDetails: false,
      flightID: null,
      flightFields: {},
      flightErrors: {},
      searchFields: {
        from: moment(getLocatDate('YYYY-MM-DD HH:mm')).startOf('day').format('YYYY-MM-DD HH:mm'),
        to: moment(getLocatDate('YYYY-MM-DD HH:mm')).endOf('day').format('YYYY-MM-DD HH:mm'),
        contract_type: null
      },
      flightStatus: {
        arr: true,
        dept: true,
        submitted: false,
        airline: '',
        hideCompleted: false,
      },
      // startDate: moment('2023-10-03 11:11:00', 'YYYY-MM-DD HH:mm:ss'),
      // endDate: moment('2023-10-04 10:10:00', 'YYYY-MM-DD HH:mm:ss'),
      // difference: {},
      flightList: [],
      locationList: [],
      scheduleOrgDest: {
        org: null,
        dest: null
      },
      showTableView: 'folder',
      anchorEl: null,
      reportProps: {},
      userContractTypes: [],
      contractTypesList: [],
      contractTypes: [],
      showRejectedFlights: false
    };
    this.station_code = cookies.get('station_code') || null;
  }

  componentDidMount() {
    //call other functions after getFilters finishes
    Promise.resolve(this.getFilters()).then(() => {
      this.fetchUser();
      this.fetchLocations();
      this.fetchAirline();
      this.fetchContractTypes();
      // this.fetchFlights();
      // this.eventSource = events('admin', 'method');
      this.eventSource = events('flight');
      this.eventSource.onmessage = ({ type, data }) => {
        if (type == 'message') {
          const msg = JSON.parse(data || '{}');
          this.handleEvent(msg);
        }
      };
    });
  }

  componentWillUnmount() {
    this.eventSource && this.eventSource.close();
  }


  handleEvent = (msg) => {
    if (msg.action == 'add') {
      if (msg.data && (msg.data.org == station || msg.data.dest == station)) {
        // based on station get the date and time and add only if it is within the selected date range
        const from = dateToUTC(this.state.searchFields.from, 'YYYY-MM-DD HH:mm');
        const to = dateToUTC(this.state.searchFields.to, 'YYYY-MM-DD HH:mm');
        const date = msg.data.org == station ? msg.data.act_dept || msg.data.est_dept || msg.data.schdl_dept : msg.data.act_arr || msg.data.est_arr || msg.data.schdl_arr;
        if (moment(date).isBetween(from, to)) {
          this.setState((prev) => ({ flightList: [msg.data, ...prev.flightList] }), () => this.filterData());
        }
      }
    }
    if (this.state.flightList && this.state.flightList.length > 0) {
      if (msg.action == 'updates' || msg.action == 'update') {
        const stn_code = cookies.get('station_code');
        if (msg.data && (msg.data?.org == stn_code || msg.data?.dest == stn_code)) {
          const flightList = [...this.state.flightList];
          const index = flightList.findIndex(d => msg.data && d.id == msg.data.id);
          if (index > -1) {
            flightList[index] = {
              ...flightList[index],
              ...msg.data
            };
            this.setState({ flightList }, () => this.filterData());
          }
        }
      }
    }
    if (msg.action === 'delete') {
      if (this.state.flightList && this.state.flightList.length > 0) {
        const flightList = [...this.state.flightList];
        const index = flightList.findIndex(d => msg.data && d.id == msg.data.id);
        if (index > -1) {
          flightList.splice(index, 1);
          this.setState({ flightList }, () => this.filterData());
        }
      }
    }
  };

  // calculateDifference = () => {
  //   const { startDate, endDate } = this.state;
  //   let difference = {};

  //   // difference.hours = endDate.diff(startDate, 'hours');
  //   // difference.minutes = endDate.diff(startDate, 'minutes');
  //   let total  = endDate.diff(startDate, 'minutes'); // Get the difference in minutes
  //   difference.hours = Math.floor(total / 60); // Calculate hours
  //   difference.minutes = total % 60; 
  //   this.setState({ difference, });
  // };

  fetchUser = () => {
    http.get(`/user?id=${cookies.get('user')}`).then(({ data }) => {
      if (data && data.data && data.data.length > 0) {
        // console.log('data.data[0]?.contract_types',data.data[0]?.contract_types)
        this.setState({
          userContractTypes: data.data[0]?.contract_types || [],
          // searchFields: {
          //   ...this.state.searchFields,
          //   contract_type: data.data[0]?.contract_types?.length > 0 ? data.data[0]?.contract_types[0] : null
          // }
        }, () => this.fetchFlights());
      }
    });
  };

  fetchLocations = () => {
    http.get('/locations')
      .then((response) => {
        const locationList = response.data.data.filter((item, index, self) =>
          self.findIndex((t) => t.iata_code === item.iata_code) === index
        );
        this.setState({ locationList: locationList });
      });
  };

  fetchAirline = () => {
    http.get(`/airline?active=${true}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          this.setState({ airline: data.data });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  fetchContractTypes = () => {
    let searchFields = this.state.searchFields;
    let from = searchFields.from;
    let to = searchFields.to;
    http.get(`/check_contract?from=${from}&to=${to}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          this.setState({ contractTypesList: data.data }, () => this.filterContractTypes());
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  filterContractTypes = () => {
    let contractTypesList = this.state.contractTypesList;
    let contractTypes = [];
    contractTypesList.map((contract) => {
      if (this.state.flightStatus.airline === '') {
        contractTypes.push(contract.type);
      }
      if (this.state.flightStatus.airline === contract.airline.code) {
        contractTypes.push(contract.type);
      }
    });
    // remove records from contractTypes which are not present in userContractTypes
    contractTypes = contractTypes.filter((item) => this.state.userContractTypes.includes(item));
    contractTypes = [...new Set(contractTypes)];
    this.setState({
      contractTypes: contractTypes,
      searchFields: {
        ...this.state.searchFields,
        contract_type: contractTypes && contractTypes[0]
      }
    }, () => this.filterData());
  };

  fetchFlights = () => {
    let searchFields = this.state.searchFields;
    console.log('searchFields', searchFields);
    let from = dateToUTC(searchFields.from, 'YYYY-MM-DD HH:mm');
    let to = dateToUTC(searchFields.to, 'YYYY-MM-DD HH:mm');
    // let from = dateToUTC(searchFields.from, 'YYYY-MM-DD HH:mm');
    // let to = dateToUTC(searchFields.to, 'YYYY-MM-DD HH:mm');
    let url = '/flights?';
    url += `from=${from}&`;
    url += `to=${to}&`;
    url += `&station=${cookies.get('station_code')}`;
    url += `&hidecomp=${this.state.flightStatus.hideCompleted}`;
    url += `&contract_type=${this.state.searchFields.contract_type}`;
    url += `&submitted=${this.state.flightStatus.submitted}`;
    if (searchFields && searchFields.from && searchFields.to) {
      http.get(url).then(({ data }) => {
        if (data?.data?.length > 0) {
          this.setState({ flightList: data.data, }, () => { this.filterData(); });
        } else {
          console.log('No data found');
          this.setState({
            flightList: [],
            filteredData: []
          });
        }
      })
        .catch(error => {
          console.error('Error fetching data:', error);
          this.setState({
            flightList: [],
            filteredData: []
          });
        });
    }
  };

  onFilterMain = (e) => {
    console.log(e.target);
    let data = {};
    let name = e.target.name;
    let value = e.target.value;
    if (name == 'to') {
      let from = this.state.searchFields.from;
      let to = value;
      if (from > to) {
        data.from = to;
      }
    }
    if (name == 'from') {
      let from = value;
      let to = this.state.searchFields.to;
      if (from > to) {
        data.to = from;
      }
    }
    console.log('onFilterMain', name, value);
    this.setState((prev) => ({
      searchFields: {
        ...prev.searchFields,
        [name]: value,
        ...data
      }
    }), () => {
      console.log('onFilterMain', this.state.searchFields);
      this.storeFilters();
      this.fetchFlights();
    });
  };

  onChange = (e, newvalue = null) => {
    const name = e.target.name ? e.target.name : e.target.id.split('-')[0];
    let value = newvalue?.iata_code ? newvalue.iata_code : e.target.value;
    if (name == 'org' || name == 'dest') {
      let orgval = name == 'org' ? value : cookies.get('station_code');
      let destval = name == 'dest' ? value : cookies.get('station_code');
      this.setState({
        scheduleOrgDest: {
          org: this.state.locationList.filter(c => c.iata_code === orgval)[0],
          dest: this.state.locationList.filter(c => c.iata_code === destval)[0]
        },
        flightFields: {
          ...this.state.flightFields,
          ['org']: orgval,
          ['dest']: destval
        }
      }, () => {
        this.storeFilters();
      });
    } else {
      this.setState({
        flightFields: {
          ...this.state.flightFields,
          [name]: value
        }
      }, () => {
        this.storeFilters();
      });
    }
  };

  changeDateAndTime = (element, type) => {
    this.setState({
      flightFields: {
        ...this.state.flightFields,
        [type]: (type == 'sta_time' || type == 'std_time') ? element.target.value : element.format('YYYY-MM-DD')
      }
    });
  };

  addFlight = (e) => {
    e.preventDefault();
    const { flightFields } = this.state;
    let formdata = {
      airlineId: flightFields.airlineId,
      flight_no: flightFields.flight_no,
      flight_date: flightFields.flight_date,
      org: flightFields.org,
      dest: flightFields.dest,
      schdl_dept: flightFields.schdl_dept,
      schdl_arr: flightFields.schdl_arr,
      ac_type: flightFields.ac_type,
      platform: flightFields.platform
    };
    if (this.validation()) {
      http.post('/flight', formdata)
        .then(() => {
          this.setState((prev) => ({
            flightFields: {
              airlineId: '',
              flight_no: '',
              flight_date: '',
              org: '',
              dest: '',
              schdl_dept: '',
              schdl_arr: '',
              ac_type: '',
              platform: ''
            },
            modal: prev.modal === 'adhoc_flight' ? false : null
          }));
        });
    }
  };

  validation = () => {
    const flightFields = { ...this.state.flightFields };
    const error = {};
    let formValid = true;
    if (!flightFields.airlineId) {
      formValid = false;
      error.airlineId = 'Select an airline';
    }
    if (!flightFields.platform || (flightFields.platform.trim().length === 0)) {
      formValid = false;
      error.platform = 'Enter a platform';
    }
    if (!flightFields.flight_no || (flightFields.flight_no.trim().length === 0)) {
      formValid = false;
      error.flight_no = 'Enter flight number';
    }
    if (!flightFields.flight_date || (flightFields.flight_date.trim().length === 0)) {
      formValid = false;
      error.flight_date = 'Enter flight date';
    }
    if (!flightFields.org || (flightFields.org.trim().length === 0)) {
      formValid = false;
      error.org = 'Select org';
    }
    if (!flightFields.dest || (flightFields.dest.trim().length === 0)) {
      formValid = false;
      error.dest = 'Select dest';
    }
    if (!flightFields.ac_type || (flightFields.ac_type.trim().length === 0)) {
      formValid = false;
      error.ac_type = 'Enter Aircraft type';
    }
    if (!flightFields.schdl_dept || (flightFields.schdl_dept.trim().length === 0)) {
      formValid = false;
      error.schdl_dept = 'Enter a STD';
    }
    if (!flightFields.schdl_arr || (flightFields.schdl_arr.trim().length === 0)) {
      formValid = false;
      error.schdl_arr = 'Enter a STA';
    }
    this.setState({ flightErrors: error });
    return formValid;
  };


  editFlight = (e) => {
    e.preventDefault();
    const { flightFields } = this.state;
    let formdata = {
      airlineId: flightFields.airlineId,
      flight_no: flightFields.flight_no,
      flight_date: flightFields.flight_date,
      org: this.state.scheduleOrgDest.org.iata_code,
      dest: this.state.scheduleOrgDest.dest.iata_code,
      schdl_dept: moment(flightFields.schdl_dept).format('YYYY-MM-DD HH:mm'),
      schdl_arr: moment(flightFields.schdl_arr).format('YYYY-MM-DD HH:mm'),
      ac_type: flightFields.ac_type,
      platform: flightFields.platform
    };
    if (this.validation()) {
      http.put(`/flight/${flightFields.id}`, formdata)
        .then(() => {
          this.setState((prev) => ({
            flightFields: {
              airlineId: '',
              flight_no: '',
              flight_date: '',
              org: '',
              dest: '',
              schdl_dept: '',
              schdl_arr: '',
              ac_type: '',
              platform: ''
            },
            modal: prev.modal === 'adhoc_flight' ? false : null
          }));
        });
    }
  };

  deleteFlight = (id) => {
    http.delete('/flight/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleDelete = (id, anchorEl) => {
    // e.stopPropagation();
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteFlight(id),
    });
  };

  filterData = () => {
    let filteredData = [...this.state.flightList];
    if (!this.state.flightStatus.dept) {
      filteredData = filteredData.filter((item) => item.org != station);
    }
    if (!this.state.flightStatus.arr) {
      filteredData = filteredData.filter((item) => item.dest != station);
    }
    if (this.state.flightStatus.hideCompleted) {
      filteredData = filteredData.filter((item) => {
        if (item.reports && item.reports.length == 0) {
          return item.reports.length === 0;
        }
        if (item && item.reports && item.reports.length > 0) {
          return item.reports.every((report) => {
            if (report.type === this.state.searchFields?.contract_type) {
              return !report.status;
            }
            else {
              return [];
            }
          });
        }
      });
    }
    if (this.state.flightStatus.submitted) {
      filteredData = filteredData.filter((item) => {
        if (item.reports && item.reports.length == 0) {
          return item.reports.length === 0;
        }
        if (item && item.reports && item.reports.length > 0) {
          return item.reports.some((report) => {
            if (report.type === this.state.searchFields?.contract_type) {
              return report.status !== 'Finalized';
            }
            else {
              return [];
            }
          });
        }
      });
    }
    if (this.state.flightStatus.airline) {
      if (this.state.contractTypes.length === 0) {
        filteredData = [];
      }
      else filteredData = filteredData.filter((item) => item?.airline?.code === this.state.flightStatus.airline);
    }
    if (this.state.searchFields.contract_type) {
      filteredData = filteredData.filter((item, _, self) => {
        let rejLogs = item?.reports?.find((r) => r.status === 'Rejected');
        let rejLogs1 = rejLogs?.type === this.state.searchFields.contract_type;
        if (rejLogs1) {
          return self;
        }
        else if (!rejLogs1) {
          let time = item.dest === station ? dateTolocale(item?.act_arr || item?.est_arr || item?.schdl_arr, 'YYYY-MM-DD HH:mm') : dateTolocale(item?.act_dept || item?.est_dept || item?.schdl_dept, 'YYYY-MM-DD HH:mm');
          if (time >= this.state.searchFields.from && time <= this.state.searchFields.to) {
            return item;
          }
        }
      });
    }
    // Filter rejected flights
    if (this.state.showRejectedFlights) {
      if (this.state.searchFields.contract_type) {
        filteredData = filteredData.filter((item) => {
          let rejLogs = item.reports.find((r) => r.status === 'Rejected');
          let rejLogs1 = rejLogs?.type === this.state.searchFields.contract_type;
          if (rejLogs1) {
            return item;
          }
        });
      }
    }

    //Sort filteredData by time
    filteredData.sort((a, b) => {
      const getItemTime = item => item.dest === station
        ? dateTolocale(item.act_arr || item.est_arr || item.schdl_arr, 'HH:mm')
        : dateTolocale(item.act_dept || item.est_dept || item.schdl_dept, 'HH:mm');

      const dateTimeA = `${a.flight_date} ${getItemTime(a)}`;
      const dateTimeB = `${b.flight_date} ${getItemTime(b)}`;

      return dateTimeA.localeCompare(dateTimeB);
    });

    this.setState({
      filteredData: filteredData,
      anchorEl: null
    });
  };

  changeCheck = (e) => {
    this.setState({
      flightStatus: {
        ...this.state.flightStatus,
        [e.target.name]: e.target.type == 'checkbox' ? e.target.checked : e.target.value
      }
    }, () => {
      this.storeFilters();
      if (e.target.name === 'airline') {
        this.filterContractTypes();
      }
      // else if(e.target.name === 'submitted') {
      //   this.fetchFlights();
      // }
      else this.filterData();
    });
  };

  getTimeZone = (code) => {
    return airportTimezone?.find(function (airport) {
      return airport.code === code;
    })?.timezone;
  };

  dateTolocale = (date, format = 'YYYY-MM-DD', stn) => {
    if (!date) return null;
    const stn_code = stn;
    const tz = this.getTimeZone(stn_code);
    console.log(stn_code, tz);
    return moment.utc(date).tz(tz).format(format);
  };

  handleOpen = (modal, action, data = {}) => {
    // e.stopPropagation();
    this.setState({
      modal,
      action
    });
    if (action === 'add') {
      this.setState((prev) => ({
        flightFields: {
          ...prev.flightFields,
          airlineId: '',
          flight_no: '',
          flight_date: '',
          org: '',
          dest: '',
          schdl_dept: '',
          schdl_arr: '',
          ac_type: '',
          platform: ''
        },
        scheduleOrgDest: {
          org: null,
          dest: null
        },
      }));
    } else {
      this.setState({
        flightFields: { ...data, },
        scheduleOrgDest: {
          org: this.state.locationList.filter(c => c.iata_code === data.org)[0],
          dest: this.state.locationList.filter(c => c.iata_code === data.dest)[0]
        },
      }, () => {
        this.setState((prev) => ({
          flightFields: {
            ...prev.flightFields,
            schdl_dept: this.dateTolocale(data.schdl_dept, 'DD MMM YY HH:mm', prev.flightFields.org),
            schdl_arr: this.dateTolocale(data.schdl_arr, 'DD MMM YY HH:mm', prev.flightFields.dest),
          },
        }));
      }
      );
    }
  };

  onClose = () => {
    this.setState({
      modal: false,
      action: false,
    });
  };

  handleToggle = (e) => {
    let checked = e.target.value;
    this.setState({ showTableView: checked });
  };

  rowSelected = (flight) => {
    this.props.history.push('/flight_folder/notes?id=' + flight?.id + '&org=' + flight?.row?.org + '&dest=' + flight?.row?.dest);
  };

  handlePopover = (anchorEl, data) => {
    this.setState({
      anchorEl,
      reportProps: data
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  storeFilters = () => {
    console.log('storeFilters');
    let filters = {
      searchFields: this.state.searchFields,
      flightFields: this.state.flightFields,
      flightStatus: this.state.flightStatus
    };
    // set expiry got 1 day.
    cookies.set('my_flights', filters, {
      path: '/',
      maxAge: 86400
    });
  };
  getFilters = () => {
    console.log('getFilters');
    let filters = cookies.get('my_flights');
    if (filters) {
      if (filters.searchFields) {
        this.setState({ searchFields: filters.searchFields });
      }
      if (filters.flightFields) {
        this.setState({ flightFields: filters.flightFields });
      }
      if (filters.flightStatus) {
        this.setState({ flightStatus: filters.flightStatus });
      }
    }
  };
  handleRejectedFlights = () => {
    this.setState((prev) => ({
      showRejectedFlights: !prev.showRejectedFlights,
      flightStatus: {
        ...prev.flightStatus,
        hideCompleted: false
      }
    }), () => this.filterData());
  };

  render() {
    return (
      <>
        <MyFlights
          modal={this.state.modal}
          action={this.state.action}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          flightList={this.state.filteredData}
          onFilterMain={this.onFilterMain}
          onChange={this.onChange}
          changeDateTime={this.changeDateAndTime}
          flightFields={this.state.flightFields}
          flightErrors={this.state.flightErrors}
          searchFields={this.state.searchFields}
          airlines={this.state.airline}
          addFlight={this.addFlight}
          editFlight={this.editFlight}
          flightData={this.state.flightData}
          stations={this.props.stations}
          handleDelete={this.handleDelete}
          stationCode={this.station_code}
          openFlightsFolder={this.openFlightsFolder}
          flightDetails={this.state.flightDetails}
          flightID={this.state.flightID}
          showflightDetails={this.state.showflightDetails}
          changeCheck={this.changeCheck}
          flightStatus={this.state.flightStatus}
          showTableView={this.state.showTableView}
          handleToggle={this.handleToggle}
          rowSelected={this.rowSelected}
          locationList={this.state.locationList}
          scheduleOrgDest={this.state.scheduleOrgDest}
          handlePopover={this.handlePopover}
          handlePopoverClose={this.handlePopoverClose}
          anchorEl={this.state.anchorEl}
          reportProps={this.state.reportProps}
          userContractTypes={this.state.userContractTypes}
          contractTypes={this.state.contractTypes}
          showRejectedFlights={this.state.showRejectedFlights}
          handleRejectedFlights={this.handleRejectedFlights}
        />

        <ConfirmModalContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { stations: state.stations };
};

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyFlightsContainer);
