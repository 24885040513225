import React, { Component } from 'react';
import Summary from './Summary';
import http from 'utils/http';
import cookies from 'utils/cookies';
import cloneDeep from 'lodash/cloneDeep';
import AddNotes from '../../common/addnotes';

class SummeryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      log: {},
      data: [],
      docFields: {},
      docErrors: {},
      docModal: false,
      disabled: false,
      popup: false,
      anchorEl: null,
      columnName: [],
      itemValue: {},
      notes: [],
      noteFields: { note: '', },
      contRowId: '',
      showMoreNote: true,
      filteredData: [],
      disabledCheckbox: [],
      checked: [],
      onCheckAll: [],
      rowData: {},
      isRemarks: false,

    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.setState({
      filteredData: this.props.filteredData,
      disabledCheckbox: this.props.disabledCheckbox,
      checked: this.props.checked,
      onCheckAll: this.props.onCheckAll,
      contracts: this.props.contracts,
      log: this.props.log,
      disabled: cookies.get('role') === 'Accounting'
    }, () => {
      this.getData();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filteredData !== this.props.filteredData) {
      this.setState({ filteredData: this.props.filteredData }, () => {
        this.getData();
      });
    }
    if (prevProps.disabledCheckbox !== this.props.disabledCheckbox) {
      this.setState({ disabledCheckbox: this.props.disabledCheckbox },
      );
    }
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked },
      );
    }
    if (prevProps.onCheckAll !== this.props.onCheckAll) {
      this.setState({ onCheckAll: this.props.onCheckAll });
    }
    if (prevProps.contracts !== this.props.contracts) {
      this.setState({
        contracts: this.props.contracts,
        // log: this.props.log,
        disabled: cookies.get('role') === 'Accounting'
      }, () => {
        this.getData();
      });
    }
  }


  getData = () => {
    let data = [];
    let columnName = [];
    let contracts = cloneDeep(this.state.contracts);
    contracts?.forEach(contract => {
      contract.contractrows?.forEach(row => {
        data.push({
          contractId: contract.id,
          contractrowId: row.id,
          label: row.description + ' ' + row.applied_on,
          applied_on: row.applied_on,
          // value: row.rate_type == 'stable' && row?.applied_on?.includes('Auto') ? parseFloat(row?.contrates?.[0]?.rate) : null,
          value: row.applied_on.includes('Rent | Auto') ? parseInt(1) : null,
          roles: row.roles,
          userId: null,
        });
        columnName = [...data]; //column headers
      });
    });
    // if (this.state.log?.data?.length > 0) {
    //   console.log('log data', this.state.log?.data);
    //   this.state.log?.data?.forEach(log => {
    //     const index = data.findIndex(d => d.contractId == log.contractId && d.contractrowId == log.contractrowId);
    //     console.log('index', index);
    //     if (index != -1) {
    //       data[index].value = log.value;
    //       if(data[index].label.includes('Rent | Auto')){
    //         data[index].value = parseFloat(1);
    //       }
    //     } else {
    //       if(log.label.includes('Rent | Auto')){
    //         log.value = parseFloat(1);
    //       }
    //       data.push({ ...log });
    //     }
    //   });
    // }
    this.setState({
      data,
      columnName
    });
  };

  handleKeyDown = (e) => {
    let allowKeys = 
        (e.key >= '0' && e.key <= '9') || // Allow numbers
        e.key === '.' ||                  // Allow decimal point
        e.key === 'Backspace' ||          // Allow backspace
        e.key === 'Delete' ||             // Allow delete
        e.key === 'Tab' ||                // Allow tab
        e.key.startsWith('Arrow');        // Allow arrow keys
    if (!allowKeys) {
      e.preventDefault(); // Block everything else
    }
  };
  
  // handleAllAutos = (row) => {
  //   console.log('handleAllAutos');
  //   console.log('row', row);

  //   if (row.data.length === 0 ){
  //     // row.data = this.state.data;
  //     row.data = [];
  //     this.state.columnName.forEach(item => {
  //       row.data.push({ 
  //         label: item.label, 
  //         value: null, 
  //         contractId: item.contractId, 
  //         contractrowId: item.contractrowId,
  //         userId: null,
  //         date: null,
  //       });
  //     });
  //   }
  //   console.log('row.data', row.data);
  //   // check if type of row.datae is object
  //   if (!Array.isArray(row.data)) {
  //     console.log('here');
  //     row.data = [];
  //     this.state.columnName.forEach(item => {
  //       row.data.push({ 
  //         label: item.label, 
  //         value: null, 
  //         contractId: item.contractId, 
  //         contractrowId: item.contractrowId,
  //         userId: null,
  //         date: null,
  //       });
  //     });
  //   }
  //   this.setState({ data : row },()=>{
  //     this.onSubmit();
  //   });
  // };

  handleChange = (e, row) => {
    const newValue = e.target.value;
    let value = '';
    let regExp =/^[0-9]*\.?[0-9]*$/;
    if (regExp.test(newValue)) {
      value = newValue;
    }

    if (row.data.length === 0 ){
      // row.data = this.state.data;
      row.data = [];
      this.state.columnName.forEach(item => {
        row.data.push({ 
          label: item.label, 
          value: null, 
          contractId: item.contractId, 
          contractrowId: item.contractrowId,
          userId: null,
          date: null,
        });
      });
    }
    // check if type of row.datae is object
    if (!Array.isArray(row.data)) {
      console.log('here');
      row.data = [];
      this.state.columnName.forEach(item => {
        row.data.push({ 
          label: item.label, 
          value: null, 
          contractId: item.contractId, 
          contractrowId: item.contractrowId,
          userId: null,
          date: null,
        });
      });
    }
    row.data.forEach(item => {
      if (row.id + '-' + item.contractId + '-' + item.contractrowId === e.target.name) {
        item.value = value;
        item.userId = cookies.get('user');
        //get current UTC date, time
        item.date = new Date().toISOString();
      }
    });
    this.setState({ data : row });
  };

  onSubmit = () => {
    this.state.data?.data?.forEach(item => {
      if(item.value !== null){
        item.user ={
          first_name: cookies.get('name').split(' ')[0],
          last_name: cookies.get('name').split(' ')[1],
          role: cookies.get('role'),
        };
      }
    });
    let data = {
      date: this.state.data?.date,
      airlineId: this.state.data?.airlineId,
      data: this.state.data?.data,
      type: this.state.data?.type,
      contract: this.props.contract_Type,
    };
    if(this.state.data?.id) {
      let strId = this.state.data && this.state.data.id && this.state.data?.id?.toString();
      let id = strId && strId?.includes('date_') ? ' ' : this.state.data?.id;
      http.post(`/service_log/${id}`, data).then(() => {
        this.setState({ data: [] });
      });
    }
  };

  onChangeDoc = (e) => {
    let value = e.target.value;
    this.setState({
      docFields: {
        ...this.state.docFields,
        [e.target.name]: e.target.name == 'file' ? e.target.files[0] : value
      }
    });
  };

  validateDoc = () => {
    const docFields = { ...this.state.docFields };
    const error = {};
    let formValid = true;
    if (!docFields.name || (docFields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter name.';
    }
    if (!docFields.file) {
      formValid = false;
      error.remark = 'Please select file.';
    }
    this.setState({ docErrors: error });
    return formValid;
  };

  addDoc = (e) => {
    e.preventDefault();
    if (this.validateDoc()) {
      let formdata = { ...this.state.docFields, };
      http.post('/service_log/doc/' + this.state.log?.id, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data.status == 'success') {
          this.props.fetchLogs();
          this.setState({
            docModal: false,
            docFields: { name: '', },
            docErrors: {},
          });
        }
      });
    }
  };

  deleteDoc = (id) => {
    http.delete('/service_log/doc/' + id).then(() => {
      this.props.fetchLogs();
    });
  };


  openPopup = (popup, anchorEl) => {
    this.setState({ 
      popup,
      anchorEl: anchorEl,
    });
  };

  closePopup = () => {
    this.setState({
      popup: false,
      anchorEl: null,
      isRemarks: false,
    });
  };

  openModal = () => {
    this.setState({ docModal: true });
  };

  closeModal = () => {
    this.setState({ docModal: false });
  };

  openNotes = (e, row, item) => {
    let filterNotes = [];
    if (row.notes?.length > 0) {
      filterNotes = row.notes?.filter(note => item.contractrowId === note.cont_rowId);
    }
    if (item === 'remarks') {
      filterNotes = row.log;
      this.setState({ isRemarks: true });
    }
    this.setState({
      anchorEl: e.currentTarget,
      popup: 'notes',
      rowData: row,
      notes: filterNotes || [],
      contRowId: item.contractrowId,
    }, () => {});
  };

  addNote = () => {
    if (this.state.noteFields.note.trim() === '') {
      return;
    }
    http.post(`/note/log/${this.state?.rowData?.id}/${this.state.contRowId}`, { note: this.state.noteFields.note, }).then(() => {
      this.setState({
        noteFields: { note: '', }, 
        popup: false,
        anchorEl: null,
      });
    });
  };

  handleNotes = (e) => {
    this.setState({
      noteFields: {
        ...this.state.noteFields,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleReadMore = (index) => {
    this.setState((prevState) => ({
      showMoreNote: {
        ...prevState.showMoreNote,
        [index]: !prevState.showMoreNote[index]
      },
    }));
  };


  render() {
    return (
      <div>
        <Summary
          dataLogs={this.props.data}
          disabled={this.state.disabled}
          status={this.state?.log?.status}
          log={this.state.log}
          id={this.state?.log?.id}
          docs={this.state.log?.docs}
          data={this.state.data}
          docFields={this.state.docFields}
          docErrors={this.state.docErrors}
          docModal={this.state.docModal}
          openModal={this.openModal}
          closeModal={this.closeModal}
          onChangeDoc={this.onChangeDoc}
          addDoc={this.addDoc}
          handleChange={this.handleChange}
          // handleAllAutos={this.handleAllAutos}
          deleteDoc={this.deleteDoc}
          onSubmit={this.onSubmit} 
          handleDoc={this.props.handleDoc} 
          popup={this.state.popup}
          anchorEl={this.state.anchorEl}
          openPopup={this.openPopup}
          closePopup={this.closePopup}
          onChange={this.props.onChange}

          columnName={this.state.columnName}
          itemValue={this.state.itemValue}
          openNotes={this.openNotes}
          filteredData={this.state.filteredData}
          onCheckAll={this.state.onCheckAll}
          checkedAll={this.props.checkedAll}
          checked={this.state.checked}
          disabledCheckbox={this.state.disabledCheckbox}
          search={this.props.search}
          filterLogs={this.props.filterLogs}
          emptyLog={this.props.emptyLog}
          handleKeyDown={this.handleKeyDown}
        />

        {this.state.popup === 'notes' &&
          <AddNotes popup={this.state.popup} 
            anchorEl={this.state.anchorEl} 
            closePopup={this.closePopup} 
            noteFields={this.state.noteFields} 
            handleNotes={this.handleNotes} 
            addNote={this.addNote} 
            notes={this.state.notes}
            handleReadMore={this.handleReadMore}
            showMoreNote={this.state.showMoreNote}
            isRemarks={this.state.isRemarks}
          />}

      </div> 
    );
  }
}

export default SummeryContainer;
