import React from 'react';
import { Divider, Grid, InputAdornment, MenuItem, Popover, Stack, Typography } from '@mui/material';
import buildUpStyles from './buildup.module.css';
import { CustomButton, CustomDatePicker, CustomInputLabel, CustomSelect, InputProps, SelectInputProps } from 'custom/components';
import { QueryBuilder } from '@mui/icons-material';
import moment from 'moment';
import dayjs from 'dayjs';

const MoveUldToDiffFlight = (props) => {
  const { popover, anchorEl, onClose } = props;
  const LABEL_PROPS = {
    variant: 'caption',
    color: 'grey',
    fontWeight: 500
  };
  const DETAIL_PROPS = {
    variant: 'subtitle1',
    fontWeight: 500,
    color: 'primary'
  };

  return (
    <Popover
      open={popover === 'move_uld_to_diff_flight'}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      slotProps={{ paper: { className: buildUpStyles.popoverWidth, } }}
    >
      <Typography variant='body1' color='primary' px={3} py={2} fontWeight={600}>Move ULD To Different Flight</Typography>
      <Divider />

      <Grid container spacing={2} px={3} py={2}>
        <Grid item xs={3}>
          <Stack direction={'column'}>
            <Typography {...LABEL_PROPS}>ULD#</Typography>
            <Typography {...DETAIL_PROPS}>{props.uld.uld_no}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack direction={'column'}>
            <Typography {...LABEL_PROPS}>o/c</Typography>
            <Typography {...DETAIL_PROPS}>{props.uld.closed ? 'C' : 'O'}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction={'column'}>
            <Typography {...LABEL_PROPS}>Scale Wgt</Typography>
            <Typography {...DETAIL_PROPS}>{props.uld.scale_wgt}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction={'column'}>
            <Typography {...LABEL_PROPS}>Flight#</Typography>
            <Typography {...DETAIL_PROPS}>{props.flight?.airline?.code}{props.flight.flight_no}/{moment(props.flight.flight_date).format('DDMMMYY')?.toUpperCase()}</Typography>
          </Stack>
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={2} columnSpacing={4} px={3} py={2}>
        <Grid item xs={12} md={4}>
          <CustomInputLabel id='airline-label' aria-label='Enter-airline'>Airline</CustomInputLabel>
          <CustomSelect
            {...SelectInputProps}
            variant='outlined'
            id='airline'
            labelId='airline-label'
            fullWidth
            onChange={props.onChangeFlight}
            name='airline'
            value={!props.flightFields.airline ? 'placeholder' : props.flightFields.airline}
          >
            <MenuItem value='placeholder' disabled>Airline</MenuItem>
            {props.airlines.map((airline, i) => (
              <MenuItem key={i} value={airline.code}>
                {airline.code}
              </MenuItem>
            ))}
          </CustomSelect>
        </Grid>

        <Grid item xs={12} md={4}>
          <CustomInputLabel id='flight-number-label' aria-label='Enter-flight-number'>Flight#</CustomInputLabel>
          <CustomSelect
            {...SelectInputProps}
            variant='outlined'
            id='flight_no'
            labelId='flight-number-labell'
            fullWidth
            onChange={props.onChangeFlight}
            value={props.flightFields.flight_no}
            name='flight_no'
          >
            <MenuItem value='placeholder' disabled>Flight</MenuItem>
            {props.flights?.reduce((acc, flight) => {
              if (!acc.find((f) => f.flight_no === flight.flight_no)) {
                acc.push(flight);
              }
              return acc;
            }, [])?.map((flight, i) => (
              <MenuItem key={i} value={flight.flight_no}>
                {flight.flight_no}
              </MenuItem>
            ))}

          </CustomSelect>
        </Grid>

        <Grid item xs={12} md={4}>
          <CustomInputLabel htmlFor='date' aria-label='Enter-date '>Date</CustomInputLabel>
          <CustomDatePicker
            slotProps={{
              textField: {
                ...InputProps,
                placeholder: 'Date',
                fullWidth: true
              },
            }}
            clearable
            format='DD MMM YYYY'
            value={dayjs(moment(props.flightFields.flight_date).format('DD MMM YYYY'))}
            onChange={(date) => props.onChangeFlight({
              target: {
                name: 'flight_date',
                value: moment(date.$d).format('YYYY-MM-DD')
              }
            })}
            shouldDisableDate={(date) => {
              return !props.flights?.filter(
                (f) => f.flight_no === props.flightFields.flight_no
              )?.find((f) => moment(f.flight_date).format('DD MMM YYYY') === moment(date.$d).format('DD MMM YYYY'));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' >
                  <QueryBuilder color='primary' />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomButton fullWidth variant='contained' color='primary' onClick={props.onSaveFlight}>Move ULD</CustomButton>
        </Grid>
      </Grid>

    </Popover>
  );
};
export default MoveUldToDiffFlight;