import React from 'react';
import buildupStyles from './buildup.module.css';
import styles from '../../../../../custom/style.module.css';
import { Box, Button, Checkbox, Divider, FormHelperText, Grid, IconButton, InputAdornment, Popover, Stack, TextField, Typography } from '@mui/material';
import { CustomButton, CustomCheckbox, CustomInputLabel, CustomSearch, CustomTextField, DataGridProps, InputProps, StyledCard, StyledCardTitle, StyledDataGrid, TableBadge } from 'custom/components';
import { AddIcon, ClearXIcon, DeleteIcon, DocIcon, EditIcon, LockIcon, UnlockIcon } from 'custom/constants';
import { FormatListBulleted, Forward, Search } from '@mui/icons-material';
import AwbDetailModal from './AwbDetailsModal';
import History from 'components/pages/common/History';
import MoveUldToDiffFlight from './MoveUldToDiffFlight';
import moment from 'moment';

const Buildup = (props) => {

  const uldListColumns = [
    {
      field: 'uld_no',
      headerName: 'ULD ID',
      minWidth: 120,
      flex: 3,
      renderCell: ({ row }) => {
        return props.editUld == row.id ? (
          <>
            <CustomTextField
              {...InputProps}
              id=''
              fullWidth
              value={props.editUldFields.uld_no}
              name='uld_no'
              onChange={props.onChangeEditUld}
              onBlur={props.onEditUld}
            />
            <ClearXIcon onClick={() => props.onedit(false)} />
          </>
        ) : <>
          <div>{row.uld_no}</div>
          <div className={`${styles.smActionIcons} ${styles.ml2}`}><EditIcon onClick={() => props.onedit(row.id)} /></div>
        </>;
      }
    },
    {
      field: 'o_c',
      headerName: 'O/C',
      align: 'center',
      headerAlign: 'center',
      minWidth: 60,
      flex: 0.4,
      renderCell: ({ row }) => {
        return row.closed ? 'C' : 'O';
      }
    },
    {
      field: 'scale_wgt',
      headerName: 'Scale wgt',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row.scale_wgt || 0 + ' K';
      }
    },
    {
      field: 'mnfst_wgt',
      headerName: 'Mnfst wgt',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row.buildup_awb?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.wgt), 0) + ' K';
      }
    },
    {
      field: 'dev',
      headerName: 'Dev',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'dest',
      headerName: 'ULD dest',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'contour',
      headerName: 'Cont',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'remark',
      headerName: 'Remarks',
      minWidth: 100,
      flex: 1,
    },

    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 140,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={1} justifyContent='center' className={styles.actionIcons}>
            <Button size='small' variant='outlined' className={buildupStyles.historyIcon} disableElevation
              onClick={(e) => props.handleOpen('history', e.currentTarget)}> H</Button>
            <Button size='small' variant='outlined' className={buildupStyles.historyIcon} disableElevation
              onClick={(e) => props.handleOpen('move_uld_to_diff_flight', e.currentTarget)}><Forward /></Button>
            <IconButton size='small' disableElevation className={styles.p0}
              onClick={() => props.handleUldPdf(row)}><DocIcon /></IconButton>
            <DeleteIcon onClick={(e) => props.handleDelete('uld', row.id, e.target)} />
          </Stack>
        );
      }
    },
  ];


  const uldContentColumns = [
    {
      field: 'awb_no',
      headerName: 'AWB Number',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'pcs',
      headerName: 'Pcs',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'wgt',
      headerName: 'Wgt',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'org_dest',
      headerName: 'Org-Dest',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row.org + '-' + row.dest;
      }
    },
    {
      field: 'spl_code',
      headerName: 'SPLs',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'remark',
      headerName: 'Remarks',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={1} justifyContent='center' className={styles.actionIcons}>
            <EditIcon disabled={props.editUldFields.closed} onClick={() => props.onSearch(row.awb_no)} />
            <DeleteIcon disabled={props.editUldFields.closed} onClick={(e) => props.handleDelete('awb', row.id, e.target)} />
          </Stack>
        );
      }
    },
  ];

  let ULDContentRows = [];
  for (let i = 0; i < 5; i++) {
    ULDContentRows.push({
      id: i,
      awb_number: `123456789${i + 1}`,
      pcs: '1',
      wgt: '100',
      org_dest: 'DEL-BOM',
      spls: 'SPLs',
      remarks: 'Remarks',
      action: '',
    });
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <StyledCard className={styles.h100}>
            <Stack direction='row' spacing={2} alignItems={'center'} justifyContent={'space-between'} mb={3}>
              <StyledCardTitle component='div' className={styles.pb0}>ULD List - {props.flight?.airline?.code}{props.flight?.flight_no}/{moment(props.flight?.flight_date).format('DDMMMYY').toUpperCase()}</StyledCardTitle>
              <Stack direction='row' spacing={1} alignItems='center'>
                <CustomInputLabel htmlFor='uld_id' aria-label='Enter ULD ID'>ULD ID</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  id='uld_id'
                  placeholder='ULD ID'
                  type='text'
                  name='uld_no'
                  onChange={props.onChangeAddUld}
                  value={props.addUldFields.uld_no}
                />
                <AddIcon className={styles.addIcon} title='Add ULD' onClick={props.onAddUld} />
              </Stack>
            </Stack>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${buildupStyles.uldListTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.ulds}
                  columns={uldListColumns}
                  onRowClick={({ row }) => {
                    props.setActiveUld(row);
                  }}
                // param={props.taxList?.length}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>

        <Grid item container spacing={3} xs={12} sm={12} md={12} lg={6}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <StyledCard className={styles.h100}>
              <Stack direction='row' spacing={2} alignItems={'center'} justifyContent={'space-between'} mb={3}>
                <StyledCardTitle component='div' className={styles.pb0}>ULD Contents - {props.editUldFields?.uld_no}</StyledCardTitle>
                <CustomSearch
                  {...InputProps}
                  id=''
                  placeholder='Enter Awb Number'
                  type='search'
                  disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                  name='awb'
                  onChange={props.onChangeSearch}
                  value={props.search?.awb || ''}
                  className={`${styles.brInput}`}
                />
                <div className={styles.sqIconsContainer}>
                  <CustomInputLabel className={styles.colorTransparent}>-</CustomInputLabel>
                  <IconButton disableRipple className={styles.p0} onClick={props.onSearch} disabled={!props.editUldFields?.id || props.editUldFields?.closed}>
                    <Search fontSize='1.7rem' classes={{ root: styles.sqIcons }} />
                  </IconButton>
                </div>
              </Stack>

              <div className={styles.tableWrapper}>
                <Box className={`${styles.tableOuter} ${buildupStyles.taxTableOuter}`}>
                  <StyledDataGrid
                    {...DataGridProps}
                    rows={props.editUldFields?.buildup_awb || []}
                    columns={uldContentColumns}
                  // param={props.taxList?.length}
                  />
                </Box>
              </div>
            </StyledCard>
          </Grid>

          <Grid item container spacing={3} xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={3} lg={3}> Totals:</Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>{props.editUldFields?.buildup_awb?.length} AWBS</Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>{props.editUldFields?.buildup_awb?.reduce((acc, curr) => parseInt(acc) + parseInt(curr.pcs), 0)} PCS</Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>{props.editUldFields?.buildup_awb?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.wgt), 0)} K</Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <StyledCard className={styles.h100}>
              <StyledCardTitle gutterBottom>Finalize ULD</StyledCardTitle>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={6} md={2} lg={3} xl={2}>
                  <CustomInputLabel htmlFor='uld_dest' aria-label='Enter Uld Dest'>ULD Dest</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='uld_dest'
                    fullWidth
                    disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                    name='dest'
                    value={props.editUldFields?.dest || ''}
                    onChange={props.onChangeEditUld}
                    onBlur={props.onEditUld}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={3} xl={2}>
                  <CustomInputLabel htmlFor='contour' aria-label='Enter Contour'>Contour</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='contour'
                    fullWidth
                    disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                    name='contour'
                    value={props.editUldFields?.contour || ''}
                    onChange={props.onChangeEditUld}
                    onBlur={props.onEditUld}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2} lg={6} xl={8}>
                  <Stack direction='row' justifyContent={'space-between'} spacing={2}>
                    <CustomInputLabel htmlFor='specials' aria-label='Enter specials'>Specials/Remarks</CustomInputLabel>
                    <CustomCheckbox
                      control={
                        <Checkbox inputProps={{ 'aria-label': '', }}
                          name='dangerous'
                          disableRipple className={styles.p0}
                          onChange={props.onChangeEditUld}
                          checked={props.editUldFields?.dangerous || false}
                          value={props.editUldFields?.dangerous || false}
                        />
                      }
                      label='Contains Dangerous Goods'
                      className={styles.mr0}
                      disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                    />
                  </Stack>
                  <CustomTextField
                    {...InputProps}
                    id='remark'
                    fullWidth
                    disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                    name='remark'
                    value={props.editUldFields?.remark || ''}
                    onChange={props.onChangeEditUld}
                    onBlur={props.onEditUld}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2} lg={3} xl={2}>
                  <CustomInputLabel htmlFor='wgt' aria-label='Enter scale wgt gross'>Scale Wgt Gross</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='scale_wgt'
                    fullWidth
                    disabled
                    value={props.editUldFields?.scale_wgt || ''}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2} lg={3} xl={2}>
                  <CustomInputLabel htmlFor='dolly_tare' aria-label='Enter Dolly tare'>Dolly Tare</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='dolly_tare'
                    fullWidth
                    disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                    name='dolly_tare'
                    value={props.editUldFields?.dolly_tare || ''}
                    onChange={props.onChangeEditUld}
                    onBlur={props.onEditUld}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={3} xl={2}>
                  <CustomInputLabel htmlFor='uld_wgt' aria-label='Enter uld wgt'>ULD Wgt</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='wgt'
                    fullWidth
                    disabled={!props.editUldFields?.id || !props.editUldFields?.add_wgt || props.editUldFields?.closed}
                    name='wgt'
                    value={props.editUldFields?.wgt || ''}
                    onChange={props.onChangeEditUld}
                    onBlur={props.onEditUld}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: <InputAdornment position='end'>
                        <div className={`${styles.actionIcons} ${styles.mr2}`}
                          onClick={() => props.onChangeEditUld({
                            target: {
                              name: 'add_wgt',
                              value: !props.editUldFields?.add_wgt
                            }
                          })}
                        ><EditIcon /></div>
                      </InputAdornment>,
                    }}
                  />
                  <FormHelperText>Edited By: {props.editUldFields?.edited_by ? props.editUldFields?.edited_by?.first_name + ' ' + props.editUldFields?.edited_by?.last_name : ''}</FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6} md={2} lg={3} xl={2}>
                  <Stack direction='row' spacing={1} justifyContent='center'>
                    {!props.editUldFields?.closed && <UnlockIcon title='Deactivate' onClick={() => props.onChangeEditUld({
                      target: {
                        name: 'closed',
                        value: true
                      }
                    })} />}
                    {props.editUldFields?.closed && <LockIcon title='Reactivate' onClick={() => props.onChangeEditUld({
                      target: {
                        name: 'closed',
                        value: false
                      }
                    })} />}
                    <TableBadge color='error' badgeContent={props.accessories?.length || 0} >
                      <IconButton disableRipple className={styles.p0} onClick={(e) => props.handleOpen('loading_accesories', e.currentTarget)}>
                        <FormatListBulleted fontSize='1.7rem' classes={{ root: styles.sqIcons }} />
                      </IconButton>
                    </TableBadge>
                    <CustomButton variant='contained' size='small' fullWidth onClick={props.onTag}>
                      TAG
                    </CustomButton>
                  </Stack>
                </Grid>
              </Grid>
            </StyledCard>
          </Grid>
        </Grid>
      </Grid>

      {props.modal === 'awb_details' &&
        <AwbDetailModal
          modal={props.modal}
          onClose={props.handleClose}
          searchAwb={props.searchAwb}
          awbFields={props.awbFields}
          onChangeAwb={props.onChangeAwb}
          onLoadAwb={props.onLoadAwb}
          onChnageSearchAwb={props.onChnageSearchAwb}
          onSaveSearchAwb={props.onSaveSearchAwb}
          handleDelete={props.handleDelete}
          editLoadAwb={props.editLoadAwb}
          onLoadEditAwb={props.onLoadEditAwb}
          onChangeLoadAwb={props.onChangeLoadAwb}
          onSaveLoadAwb={props.onSaveLoadAwb}
        />
      }

      {
        props.modal === 'history' &&
        <History
          popover={props.modal}
          anchorEl={props.anchorEl}
          onClose={props.handleClose}
          data={props.history}
        />
      }

      {props.modal === 'move_uld_to_diff_flight' &&
        <MoveUldToDiffFlight
          popover={props.modal}
          anchorEl={props.anchorEl}
          onClose={props.handleClose}
          uld={props.editUldFields}
          flight={props.flight}
          flights={props.flights}
          airlines={props.airlines}
          flightFields={props.flightFields}
          onChangeFlight={props.onChangeFlight}
          onSaveFlight={props.onSaveFlight}
        />
      }

      <Popover
        open={props.modal === 'loading_accesories'}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{ paper: { className: buildupStyles.loadAccessoriesPopover, } }}
      >
        <Typography variant='body1' color='primary' px={3} py={2} fontWeight={600}>Loading Accessories Used</Typography>
        <Divider />

        <Grid container spacing={2} px={3} py={2}>
          {props.accessories.map((a, index) => (
            <Grid item key={index} xs={6} className={(index % 2) ? '' : buildupStyles.accRowOdd}>
              <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                <Typography variant='caption' maxWidth={'150px'}>{a.description}</Typography>
                <TextField
                  variant='outlined'
                  id='spreader_boards'
                  defaultValue={props.editUldFields?.uld_loadingaccessories?.find(b => b.loadingaccessoriesId === a.id)?.value || ''}
                  name={a.id}
                  className={buildupStyles.tableTextField}
                  onBlur={props.onChangeAccessories}
                />
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
};

export default Buildup;

