import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const primaryBlue = '#092b4a';
const bkgColor = '#f0f0f0';
const greyTextColor = '#808080';

const styles = StyleSheet.create({
  headerContainerInner: {
    marginTop: 10,
    width: '36%',
  },
  headerContainerInnerLoose: {
    marginTop: 10,
    width: '22%',
  },
  headerContainer: { marginTop: 24, },
  title: {
    fontSize: 9,
    textTransform: 'uppercase',
    backgroundColor: '#f0f0f0',
    color: primaryBlue,
    padding: '4px 35px',
    textAlign: 'center',
    marginBottom: 5,
    lineHeight: 'normal',
    fontFamily: 'NunitoMedium',
  },
  label: {
    fontSize: 8,
    padding: 2,
    textAlign: 'center',
    textTransform: 'uppercase',
    borderBottom: '1px solid lightgrey',
  },
  labelOuter: {     
    backgroundColor: bkgColor,
    color: primaryBlue,
  },
  labelMain: {
    fontSize: 8,
    padding: 5,
    lineHeight: 'normal',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: primaryBlue,
    marginBottom: 2,
    fontWeight: 600
  },
  data: {
    fontSize: 9,
    lineHeight: 'normal',
    textAlign: 'center',
    color: greyTextColor,
    padding: 2,
    fontFamily: 'NunitoRegular',
    width: '100%',
  },
  dataOuter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center', 
    flexGrow: 1,
  },
  dataWrapper: {
    fontSize: 9,
    lineHeight: 'normal',
    textAlign: 'center',
    border: '1px solid lightgrey',
    borderRight: 'none',
    width: '100%',
    flex: 1,
  },
  dataWrapperULD:{ flex: 2, },
  dataWrapperInner: {
    fontSize: 9,
    lineHeight: 'normal',
    borderRight: '1px solid lightgrey',
    borderLeft: 'none',
    borderBottom: 'none',
    borderTop: 'none',
    flex: 1,
  },
  row:{
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 'auto',
  },
  lastChild:{ borderRight: '1px solid lightgrey' }
});

function formatNumber(num) {
  return num % 1 === 0 ? Math.round(num) : num.toFixed(1);
}

const ManifestSummary = (props) => {
  const { summary, nil } = props;

  return (
    <View style={styles.headerContainer}>
      <Text style={styles.title}>Manifest Summary {nil? '[Nil Manifest]' : ''}</Text>
      <View style={styles.row}>
        <View style={styles.headerContainerInnerLoose}>
          <Text style={styles.labelMain}>loose</Text>
          <View style={styles.row}>
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}><Text style={styles.label}>Awb</Text></View> 
              <View style={styles.dataOuter}> 
                <Text style={styles.data}>{summary?.loose?.awbs}</Text>
              </View>
            </View>        
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Pcs</Text>
              </View> 
              <View style={styles.dataOuter}> 
                <Text style={styles.data}>{summary?.loose?.pcs}</Text>
              </View>
            </View>      
            <View style={[styles.dataWrapper, styles.lastChild]}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Wgt</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{formatNumber(Math.round(summary?.loose?.wgt*100)/100)}</Text>
              </View>
            </View>  
            {/* <View style={styles.dataWrapper}>
              <Text style={styles.label}>Ulds</Text>
              <View style={styles.row}>
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>LD3</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.looseulds?.LD3}</Text>
                  </View> 
                </View>       
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>LD7</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.looseulds?.LD7}</Text>
                  </View> 
                </View>       
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>MD</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.looseulds?.MD}</Text>
                  </View> 
                </View> 
              </View> 
            </View> */}
          </View>
        </View>
        
        <View style={styles.headerContainerInner}>
          <Text style={styles.labelMain}>Intact</Text>
          <View style={styles.row}>
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Awb</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{summary?.intact?.awbs}</Text>
              </View> 
            </View>        
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Pcs</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{summary?.intact?.pcs}</Text>
              </View> 
            </View>      
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Wgt</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{formatNumber(Math.round(summary?.intact?.wgt*100)/100)}</Text>
              </View> 
            </View>  
            <View style={[styles.dataWrapper, styles.dataWrapperULD]}>
              <View style={styles.labelOuter}>
                <Text style={[styles.label, { borderRight: '1px solid lightgrey' }]}>Ulds</Text>
              </View> 
              <View style={styles.row}>
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>LD3</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.intactulds?.LD3}</Text>
                  </View> 
                </View>       
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>LD7</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.intactulds?.LD7}</Text>
                  </View> 
                </View>       
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>MD</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.intactulds?.MD}</Text>
                  </View> 
                </View> 
              </View> 
            </View>
          </View>
        </View>

        <View style={styles.headerContainerInner}>
          <Text style={styles.labelMain}>total</Text>
          <View style={styles.row}>
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Awb</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{summary?.total?.awbs}</Text>
              </View> 
            </View>        
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Pcs</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{summary?.total?.pcs}</Text>
              </View> 
            </View>      
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Wgt</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{formatNumber(Math.round(summary?.total?.wgt*100)/100)}</Text> 
              </View>        
            </View>  
            <View style={[styles.dataWrapper, styles.dataWrapperULD]}>
              <View style={styles.labelOuter}>
                <Text style={[styles.label, { borderRight: '1px solid lightgrey' }]}>Ulds</Text>
              </View> 
              <View style={styles.row}>
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>LD3</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.totalulds?.LD3}</Text>
                  </View> 
                </View>       
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>LD7</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.totalulds?.LD7}</Text>
                  </View> 
                </View>       
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>MD</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.totalulds?.MD}</Text>
                  </View> 
                </View> 
              </View> 
            </View>
          </View>
        </View>

      </View>
    </View>
  );
};
  
export default ManifestSummary;