import React, { Fragment } from 'react';
import { StyledTableContainer } from 'custom/components';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import fltStyles from './fltloadinfo.module.css';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
// import Invoice from './invoice/Invoice';

function formatNumber(num) {
  return num % 1 === 0 ? Math.round(num) : num.toFixed(2);
}

const ContractData = (props) => {

  const handleQuantity = (row) => {
    let value = 0;
    if (props.billingFields?.exclude_disc) {
      if (props.billingFields?.transit === 'none' || !props.billingFields?.transit) {
        value = typeof row.quantity === 'number' ? formatNumber(Math.round(row.quantity * 100) / 100) : row.quantity;
      } else if (props.billingFields?.transit === 'inbound') {
        value = typeof row.in_quantity === 'number' ? formatNumber(Math.round(row.in_quantity * 100) / 100) : row.in_quantity;
      } else {
        value = typeof row.out_quantity === 'number' ? formatNumber(Math.round(row.out_quantity * 100) / 100) : row.out_quantity;
      }
    } else {
      if (props.billingFields?.transit === 'none' || !props.billingFields?.transit) {
        value = typeof row.dis_quantity === 'number' ? formatNumber(Math.round(row.dis_quantity * 100) / 100) : row.dis_quantity;
      } else if (props.billingFields?.transit === 'inbound') {
        value = typeof row.dis_in_quantity === 'number' ? formatNumber(Math.round(row.dis_in_quantity * 100) / 100) : row.dis_in_quantity;
      } else {
        value = typeof row.dis_out_quantity === 'number' ? formatNumber(Math.round(row.dis_out_quantity * 100) / 100) : row.dis_out_quantity;
      }
    }
    return value === 0 ? 'Nil' : value;
  };

  // const flightLoadColumns = [
  //   {
  //     field: 'item',
  //     headerName: 'Line Item',
  //     minWidth: 100,
  //     flex: 1.5,
  //   },
  //   {
  //     field: 'platform',
  //     headerName: 'Platform',
  //     minWidth: 100,
  //     flex: 1.5,
  //   },
  //   {
  //     field: 'driver',
  //     headerName: 'Driver',
  //     minWidth: 100,
  //     flex: 1,
  //   },
  //   {
  //     field: 'quantity',
  //     headerName: 'Quantity',
  //     minWidth: 100,
  //     flex: 1,
  //     renderCell: ({ row }) => {
  //       let value = 0;
  //       if (props.billingFields?.exclude_disc) {
  //         if (props.billingFields?.transit === 'none' || !props.billingFields?.transit) {
  //           value = typeof row.quantity === 'number' ? formatNumber(Math.round(row.quantity * 100) / 100) : row.quantity;
  //         } else if (props.billingFields?.transit === 'inbound') {
  //           value = typeof row.in_quantity === 'number' ? formatNumber(Math.round(row.in_quantity * 100) / 100) : row.in_quantity;
  //         } else {
  //           value = typeof row.out_quantity === 'number' ? formatNumber(Math.round(row.out_quantity * 100) / 100) : row.out_quantity;
  //         }
  //       } else {
  //         if (props.billingFields?.transit === 'none' || !props.billingFields?.transit) {
  //           value = typeof row.dis_quantity === 'number' ? formatNumber(Math.round(row.dis_quantity * 100) / 100) : row.dis_quantity;
  //         } else if (props.billingFields?.transit === 'inbound') {
  //           value = typeof row.dis_in_quantity === 'number' ? formatNumber(Math.round(row.dis_in_quantity * 100) / 100) : row.dis_in_quantity;
  //         } else {
  //           value = typeof row.dis_out_quantity === 'number' ? formatNumber(Math.round(row.dis_out_quantity * 100) / 100) : row.dis_out_quantity;
  //         }
  //       }
  //       return value === 0 ? 'Nil' : value;
  //     }
  //   },
  //   {
  //     field: 'source',
  //     headerName: 'Source',
  //     minWidth: 100,
  //     flex: 1,
  //   },
  //   // {
  //   //   field: 'recorded_from',
  //   //   headerName: 'Recorded from (# Flights)',
  //   //   minWidth: 100,
  //   //   flex: 1,
  //   // },
  // ];

  return <>
    <div className={`${styles.tableWrapper} ${styles.pt1}`}>
      {/* <Box className={`${styles.tableOuter} ${fltStyles.cdtableOuter}`}>
        <StyledDataGrid
          {...DataGridProps}
          rows={props.contractData}
          columns={flightLoadColumns}
          apiRef={props.apiRef}
          param={props.contractData?.length}
        />
      </Box> */}

      <StyledTableContainer className={fltStyles.collapsibleTableContainer} apiRef={props.apiRef}>
        <Table aria-label='collapsible table' className={fltStyles.collapsibleTable}>
          <TableHead className={fltStyles.collapsibleTableHead}>
            <TableRow className={fltStyles.collapsibleHeadRow}>
              <TableCell />
              <TableCell className={`${fltStyles.collapsibleTableCell}`}>Item</TableCell>
              <TableCell className={`${fltStyles.collapsibleTableCell}`}>Platform</TableCell>
              <TableCell className={`${fltStyles.collapsibleTableCell}`}>Driver</TableCell>
              <TableCell className={`${fltStyles.collapsibleTableCell}`}>Quantity</TableCell>
              <TableCell className={`${fltStyles.collapsibleTableCell}`}>Souce</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={fltStyles.collapsibleTableBody}>
            <TableRow sx={{
              '& > *': { borderBottom: 'unset' },
              height: 20 
            }}></TableRow>
            {props.contractData.map((row, index) => (
              <Fragment key={index}>
                <TableRow key={index} className={fltStyles.collapsibleTableRow}>
                  <TableCell className={fltStyles.collapsibleTableCell} width={50}>
                    {row.children?.length > 0 &&
                    <IconButton
                      aria-label='expand row'
                      size='small'
                      onClick={() => props.handleTableCollapse(index)}
                      disabled={row.children?.length === 0}
                    >
                      {props.collapse.includes(index) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>}
                  </TableCell>
                  <TableCell width={'20%'} className={fltStyles.collapsibleTableCell}>{row.item}</TableCell>
                  <TableCell width={'20%'} className={fltStyles.collapsibleTableCell}>{row.platform}</TableCell>
                  <TableCell width={'20%'} className={fltStyles.collapsibleTableCell}>{row.driver}</TableCell>
                  <TableCell width={'20%'} className={fltStyles.collapsibleTableCell}>{handleQuantity(row)} </TableCell>
                  <TableCell width={'20%'} className={fltStyles.collapsibleTableCell}>{row.source}</TableCell>
                </TableRow>
                <TableRow className={fltStyles.spRow}>
                  <TableCell colSpan={6}>
                    <Collapse in={props.collapse.includes(index)} timeout='auto' unmountOnExit>
                      <Box className={fltStyles.collapsibleBox}>
                        <Table aria-label='' className={fltStyles.collapsedTable}>
                          <TableHead className={fltStyles.collapsedTableHead}>
                            <TableRow className={fltStyles.collapsedTableRow}>
                              <TableCell className={`${fltStyles.collapsedTableCell}`}>Item</TableCell>
                              <TableCell className={`${fltStyles.collapsedTableCell}`}>Platform</TableCell>
                              <TableCell className={`${fltStyles.collapsedTableCell}`}>Driver</TableCell>
                              <TableCell className={`${fltStyles.collapsedTableCell}`}>Quantity</TableCell>
                              <TableCell className={`${fltStyles.collapsedTableCell}`}>Souce</TableCell>
                            </TableRow>
                            <TableRow className={fltStyles.spRow} /> {/* add space between table head and the body */}
                          </TableHead>
                          <TableBody className={fltStyles.collapsedTableBody}>
                            {row?.children?.map((rowc, index) => (
                              <TableRow key={index} className={fltStyles.collapsedTableRow}>
                                <TableCell width={'20%'} className={fltStyles.collapsedTableCell}>{rowc.controw_data ? rowc.controw_data.label : rowc.label}</TableCell>
                                <TableCell width={'20%'} className={fltStyles.collapsedTableCell}>{rowc.controw_data ? rowc.controw_data.platform : rowc.platform}</TableCell>
                                <TableCell width={'20%'} className={fltStyles.collapsedTableCell}>{rowc.controw_data ? rowc.controw_data.applied_on?.split('|')[1].trim() : rowc.applied_on?.split('|')[1].trim()}</TableCell>
                                <TableCell width={'20%'} className={fltStyles.collapsedTableCell}>{rowc.controw_data ? handleQuantity(rowc.controw_data) : handleQuantity(rowc)} </TableCell>
                                <TableCell width={'20%'} className={fltStyles.collapsedTableCell}>{rowc.controw_data ? rowc.controw_data.applied_on?.split('|')[0].trim() : rowc.applied_on?.split('|')[0].trim()}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </div>
  </>;

};

export default ContractData;