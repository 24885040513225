import React, { Fragment } from 'react';
import { Grid, /*InputAdornment,*/ Stack, Typography, Box, Popover, Tooltip, Radio, RadioGroup, Divider } from '@mui/material';
import { CustomButton, CustomRadio, CustomTextField, DataGridProps, InputProps, NUMBERINPUT_PROPS, StyledCard, StyledCardTitle, StyledDataGrid, TableBadge } from '../../../../../custom/components';
import styles from '../../../../../custom/style.module.css';
import reportStyles from './reports.module.css';
import flightStyles from '../../flightsfolder.module.css';
import PDF from '../../../../../assets/images/pdf.svg';
import theme from 'custom/theme';
import { AddIcon, DateFormat, DeleteIcon, ExcelIcon, StickyNoteIcon, contractType, statisticsRoles } from 'custom/constants';
import ManifestSummaryContainer from '../../summary/ManifestSummaryContainer';
import cookies from 'utils/cookies';
import AddReportDocModal from './AddReportDocModal';
import { dateTolocale } from 'utils/converter';
import environment from 'custom/environments';
import difference from 'lodash/difference';
import AddNotes from './AddNotes';
import { JsonToExcel } from 'custom/export';
import ReviewModal from 'components/pages/common/ReviewModal';
import ReOpenModal from 'components/pages/common/ReOpenModal';
import moment from 'moment';
import StatusModal from 'components/pages/common/StatusModal';
import FlightReportPDFDownloadContainer from 'components/pages/flightReportPDFDownload/FlightReportPDFDownloadContainer';

const timezone = cookies.get('timezone') || null;

const checkAccessArray = (rowrole, userrole) => {
  let flag = false;
  let foundroles = statisticsRoles.find((statRole) => statRole.role === rowrole);
  if (foundroles?.access.includes(userrole)) {
    flag = true;
  }
  return flag;
};

const FlightReport = (props) => {
  const checklistColumns = [
    {
      field: 'description',
      headerName: 'Checks',
      minWidth: 150,
      flex: 1,
      cellClassName: styles.cellTextWrap
    },
    {
      field: 'role',
      headerName: 'Roles',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'user',
      headerName: 'Entered By',
      minWidth: 100,
      flex: 1,
      className: reportStyles.checkdetailsText,
      renderCell: ({ row }) => {
        let length = row?.flight_checklist?.length - 1;
        let checklist_data = row?.flight_checklist[length];
        let user = checklist_data?.user;
        let userDetails = `${user?.first_name} ${user?.last_name} | ${dateTolocale(checklist_data?.created, DateFormat + ' ' + 'HH:mm')?.toUpperCase()}`;
        return user ? <div className={reportStyles.checkdetails}>{userDetails} </div> : '';
      }
    },
    {
      field: 'remark',
      headerName: 'Remarks',
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        let arrayLength = row?.flight_checklist && row?.flight_checklist?.length > 0 && row?.flight_checklist?.length - 1;
        let lastArrayId = row?.flight_checklist && row?.flight_checklist?.length > 0 && row?.flight_checklist[arrayLength]?.id;
        return (<div className={reportStyles.addTextFieldStats}>
          <CustomTextField
            {...InputProps}
            label={false}
            variant='outlined'
            id='remarks'
            fullWidth
            placeholder=''
            type='text'
            name=''
            disabled={(getReportstatus() === 'Submitted' || getReportstatus() === 'Finalized') ? props.disabledChecklistRemarks(row) : true}
            onChange={(e) => props.handleChecklistRemarks(e, row, 'add')}
            onFocus={() => props.setChecklistId(row)}
            onBlur={props.saveRemark}
            value={props.checklistRemarks[lastArrayId]?.remark || ''}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            // onKeyUp={(event) => {
            //   console.log('Key up: ', event.key);
            // }}
            // onKeyPress={(event) => {
            //   console.log('Key press: ', event.key);
            // }}
            // InputProps={{
            //   disableUnderline: true,
            //   endAdornment: <InputAdornment position='end'>
            //     <IconButton
            //       size='small'
            //       aria-label='send'
            //       edge='end'
            //       onClick={props.saveRemark}
            //       color='primary'
            //       disabled={props.disabledChecklistRemarks(row)}
            //     >
            //       <Save fontSize='inherit' />
            //     </IconButton>
            //   </InputAdornment>,
            // }}
            sx={{
              marginTop: '-4px',
              marginBottom: '-4px'
            }}
          />
        </div>);
      }
    }
  ];

  const statColumn = [
    {
      field: 'description',
      headerName: 'Line Item',
      minWidth: 100,
      flex: 1,
      cellClassName: styles.cellTextWrap
    },
    {
      field: 'applied_on',
      headerName: 'Driver',
      minWidth: 100,
      flex: 1,
      cellClassName: styles.cellTextWrap,
      renderCell: ({ row }) => {
        let applied_on = row.applied_on;
        return applied_on && applied_on.includes('Auto') ? applied_on.split('|')[0] : applied_on;
      }
    },
    {
      field: 'roles',
      headerName: 'Roles',
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <div className={`${reportStyles.checkdetails} ${styles.textCenter}`}>
            {row.roles && row.roles.length > 0 ? row.roles[0] : '-'}
          </div>
        );
      }
    },
    {
      field: 'user',
      headerName: 'Entered By',
      minWidth: 100,
      flex: 1,
      className: reportStyles.checkdetailsText,
      renderCell: ({ row }) => {
        if (!row?.user) {
          if (row.applied_on.includes('Auto')) {
            return <div className={`${reportStyles.centerAlignText}`}>Auto</div>;
          } else {
            return <div className={`${reportStyles.centerAlignText}`}> - </div>;
          }
        } else {
          return (
            <div className={reportStyles.checkdetails}>
              {`${row?.user?.first_name || ''} ${row?.user?.last_name || '-'} | ${row?.date ? moment(row.date).tz(timezone).format('DDMMMYY HH:mm')?.toUpperCase() : '-'}`}
            </div>
          );
        }
      }
    },
    {
      field: 'remark',
      headerName: 'Quantity',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <CustomTextField
            {...InputProps}
            sx={NUMBERINPUT_PROPS}
            label={false}
            variant='outlined'
            id='loose_kgs'
            fullWidth
            placeholder=''
            type='number'
            name='quantity'
            // disabled={row.applied_on.includes('Auto') ? true : props.disableButtons('Save') ? true : false}
            disabled={
              getReportstatus() !== 'Submitted' ? true
                : row.applied_on.includes('Auto') && (cookies.get('role') !== 'Senior Accounting' && cookies.get('role') !== 'System Admin')
                  ? true
                  // : props.disableButtons('Save') 
                  //   ? true
                  : row.applied_on.includes('Manual') && row.roles && row.roles.length > 0 &&
                  // !row.roles.includes(cookies.get('role')) && 
                  !checkAccessArray(row.roles?.[0], cookies.get('role'))
            }
            value={row.quantity && !isNaN(parseFloat(row.quantity)) ? (row.applied_on.includes('KG') ? Math.round(parseFloat(row.quantity) * 100) / 100 : parseFloat(row.quantity)) : row.applied_on.includes('Auto') ? 0 : ''}
            onChange={(e) => props.handleStats(e, props.contindex, row.rowIndex)}
            onBlur={props.handleStatisticPost}
          />
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      minWidth: 40,
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      className: styles.flexWrap,
      renderCell: ({ row }) => {
        return (
          <TableBadge badgeContent={row?.note?.length} color='error' overlap='circular'>
            <StickyNoteIcon title='Add/View Notes' onClick={(e) => props.openPopup('notes', e.currentTarget, props.contindex, row.rowIndex, row)} />
          </TableBadge>
        );
      }
    }
  ];

  const getReportstatus = () => {
    let status = null;
    let logs = props.reportLogs.find((log) => log.type === props?.cont_item);
    if (logs && logs.log && logs.log.length > 0) {
      let lastlog = logs.log[logs.log.length - 1];
      // status = ((logs.status !== 'Finalized' && logs.status !== 'Pending' && (!logs.approved_acc && !logs.approved_air)) && !lastlog.rejected) ? 'Finalized' :
      //   (!logs.approved_acc || !logs.approved_air) ? 'Approved' : null;
      status = (((logs.status !== 'Submitted' || logs.status === 'Submitted') && logs.status !== 'Finalized' && logs.status !== 'Pending' && (!logs.approved_acc && !logs.approved_air)) && !lastlog.rejected) ? 'Submitted' :
        // ((logs.status !== 'Finalized' && logs.status !== 'Pending' && (!logs.approved_acc && !logs.approved_air)) && !lastlog.rejected) ? 'Finalized' :
        (!logs.approved_acc || !logs.approved_air) ? 'Approved' : null;
    } else {
      // status = 'Finalized';
      status = 'Submitted';
    }
    return status;
  };

  const handleCheck = () => {
    const data = props.filteredData?.map((row) => {
      return {
        Checks: row.description,
        Roles: row.role,
        Performed_By: row?.flight_checklist?.length > 0 ? `${row?.flight_checklist[row?.flight_checklist.length - 1]?.user?.first_name} ${row?.flight_checklist[row?.flight_checklist.length - 1]?.user?.last_name}` : '',
        Performed_Time: row?.flight_checklist?.length > 0 ? dateTolocale(row?.flight_checklist[row?.flight_checklist.length - 1]?.created, DateFormat + ' ' + 'HH:mm') : '',
        Remarks: row?.flight_checklist?.length > 0 ? row?.flight_checklist[row?.flight_checklist.length - 1]?.remark : '',
      };
    });
    JsonToExcel(data, 'Checklist');
  };

  const handleStat = () => {
    const data = props.statRows?.map((row) => {
      return {
        LineItem: row.description,
        Driver: row.applied_on,
        Roles: row.roles && row.roles.length > 0 ? row.roles[0] : '-',
        Entered_By: row?.applied_on.includes('Auto') ? 'Auto' : `${row?.user?.first_name || ''} ${row?.user?.last_name || ''}`,
        Entered_Time: row?.date ? dateTolocale(row.date, DateFormat + ' ' + 'HH:mm') : '',
        Remarks: row.quantity,
      };
    });
    JsonToExcel(data, 'Statistics');
  };

  return (
    <>
      <Grid container columnSpacing={3} rowSpacing={3} className={reportStyles.gridContainer}>
        <Grid item xs={12} container columnSpacing={3} rowSpacing={3} justifyContent={'space-between'} position={'relative'}>
          <Grid item xs={3}>
            {
              !props.ctype ?
                <RadioGroup
                  aria-labelledby='contract-type-radio-buttons-group'
                  name='contract-type-radio-buttons-group'
                  value={props.cont_item}
                  row
                  className={styles.radioToggleGroup}
                >
                  {Object.entries(contractType).map(([, value], index) => {
                    if (props.userContractTypes?.includes(value)) {
                      return <Fragment key={index}>
                        <CustomRadio
                          value={value}
                          control={<Radio className={styles.radioToggleBtn} onChange={() => props.handleStatType(index, value)} />}
                          label={value}
                          className={`${styles.radioToggle} ${props.cont_item === value ? styles.radioToggleBtnChecked : ''
                          }`}
                          classes={{ label: styles.radioToggleLabel }}
                        />
                      </Fragment>;
                    }
                  })
                  }
                </RadioGroup> : null
            }
          </Grid>

          <Grid item xs={12}
            className={styles.pt0}
            sx={{
              marginTop: '-18px',
              [`& .${flightStyles.manifestCheckbox}`]: {
                maxWidth: 'max-content',
                marginLeft: 'auto',
                marginRight: '60px !important',
                marginTop: '4px'
              },
              [`& .${flightStyles.manifestCard}`]: { marginBottom: '0px !important', },
            }}>
            <ManifestSummaryContainer showReportModal={props.showReportModal} nil={props.nil_manifest} />
          </Grid>
          <Grid item xs className={`${reportStyles.excelWrapper} ${reportStyles.reportIcon} `}>
            <div className={`${styles.mr4}`}></div>
            <FlightReportPDFDownloadContainer history_data={props.history_data} nil={props.nil_manifest} statistics={props.statRows} checklist={props.filteredData} ctype={props.ctype} />
          </Grid>
        </Grid>
        {
          props.userContractTypes && props.userContractTypes.length > 0 ?
            <Grid item xs={12} container columnSpacing={3} rowSpacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={5} className={reportStyles.gridItem} classes={{ root: reportStyles.gridItem }}>
                <StyledCard className={styles.h100}>
                  <StyledCardTitle gutterBottom component='div' className={`${styles.fSpaceBtw} ${styles.pb2}`}>Flight Report Checklist
                    <ExcelIcon title='Download File' onClick={handleCheck} className={styles.tableIcon} />
                  </StyledCardTitle>
                  <div className={styles.tableWrapper}>
                    <Box className={`${styles.tableOuter} ${reportStyles.checklistTableOuter}`} sx={{ height: 500 }}>
                      <StyledDataGrid
                        {...DataGridProps}
                        rows={props.filteredData || []}
                        columns={checklistColumns}
                        checkboxSelection
                        isRowSelectable={(params) => getReportstatus() === 'Submitted' ? props.isRowSelectable(params.row) : false}
                        disableRowSelectionOnClick={true}
                        rowSelectionModel={props.rowSelectionModel}
                        onRowSelectionModelChange={(ids, d) => {
                          props.onRowsSelectionHandler(ids, d);
                          const selectedIds = difference(ids, props.rowSelectionModel);
                          const unselectedIds = difference(props.rowSelectionModel, ids);
                          props.checkboxSelectionHandler(selectedIds, unselectedIds);
                        }}
                        className={reportStyles.checklistTable}
                        classes={{ checkboxInput: styles.p0 }}
                        sx={{
                          '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': { display: 'none' },
                          '& .MuiDataGrid-row .MuiDataGrid-cell:not(:nth-last-of-type(2))': { paddingRight: 0 },
                        }}
                        getRowClassName={(params) => params.row.isRedCheckist && props.redChecklist ? reportStyles.redRow : ''}
                        param={props.filteredData?.length}
                      />
                    </Box>
                  </div>
                </StyledCard>
              </Grid>

              <Grid item xs={12} sm={6} md={8} lg={8} xl={5} className={reportStyles.gridItem}>
                <StyledCard className={`${styles.h100} ${styles.w100} ${styles.fColumn}`}>
                  <StyledCardTitle gutterBottom component='div' className={`${styles.fSpaceBtw} ${styles.pb2}`}>Statistics {props.nil ? ' (Nil Manifest)' : ''}
                    <ExcelIcon title='Download File' onClick={handleStat} className={styles.tableIcon} />
                  </StyledCardTitle>
                  <div className={styles.tableWrapper}>
                    <Box className={`${styles.tableOuter} ${reportStyles.checklistTableOuter}`} sx={{ height: 500 }}>
                      <StyledDataGrid
                        {...DataGridProps}
                        rows={props.statRows || []}
                        columns={statColumn}
                        param={props.statRows?.length}
                        sx={{ '& .MuiDataGrid-row .MuiDataGrid-cell:not(:last-of-type)': { paddingRight: 0 } }}
                      />
                    </Box>
                  </div>
                  {/* <div className={`${styles.fJustifyEnd} ${styles.fZero} ${styles.mtauto}`}>
                      <div className={`${reportStyles.addTextFieldStats} ${styles.pt3}`}>
                        <CustomButton variant='contained' onClick={(e) => props.openPopup('confirm', e.target, null, null, null)} className={styles.w100} disabled={props.disableButtons('Save') ? true : false} >Save</CustomButton>
                      </div>
                    </div> */}
                </StyledCard>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} xl={2} className={reportStyles.gridItem}>
                <StyledCard elevation={0} className={styles.h100}>
                  <div className={`${styles.mb2} ${styles.fSpaceBtw}`} >
                    <StyledCardTitle gutterBottom component='div'>Attachment</StyledCardTitle>
                    {!props.showReportModal && <AddIcon title='Add document' onClick={() => props.handleOpen(true)} />}
                  </div>
                  <Stack spacing={3} className={reportStyles.attachmentWrapper}>
                    {props.attachment.map((item) => (
                      <div key={item.id}>
                        <div className={styles.positionRelative}>
                          <DeleteIcon onClick={(e) => { props.handleDelete(item.id, e.target); }} disabled={props.ffmData?.ffmId == item?.id} className={reportStyles.delIcon} />
                          <Tooltip title='Click to download file'>
                            <div className={`${reportStyles.pdfWrapper} ${styles.mb3} ${styles.pointer}`} onClick={() =>
                              window.open(`${environment.apiService}document?key=${item.src}`, '_blank')
                            }>
                              <img src={PDF} alt='pdf' width={'50px'} height={'60px'} />
                            </div>
                          </Tooltip>
                        </div>
                        <Stack spacing={1}>
                          <div className={`${styles.dFlex} ${styles.fWrap}`}>
                            <Typography variant='caption' component='div' lineHeight='normal' display={'inline-block'} className={reportStyles.attDetails}>Document Type </Typography>
                            <Typography variant='caption' component='span' lineHeight='normal' fontWeight={'bold'} color={theme.palette.primary.main} textAlign={'right'}>{item.name}</Typography>
                          </div>
                          <div className={styles.dFlex}>
                            <Typography variant='caption' component='div' lineHeight='normal' display={'inline-block'} className={reportStyles.attDetails}>Pages</Typography>
                            <Typography variant='caption' component='span' lineHeight='normal' fontWeight={'bold'} color={theme.palette.primary.main} textAlign={'right'}>{item.numPages}</Typography>
                          </div>
                          <div className={styles.dFlex}>
                            <Typography variant='caption' component='div' lineHeight='normal' display={'inline-block'} className={reportStyles.attDetails}>Added By</Typography>
                            <Typography variant='caption' component='span' lineHeight='normal' fontWeight={'bold'} color={theme.palette.primary.main} textAlign={'right'}>{item.added_by ? `${item.added_by?.first_name} ${item.added_by?.last_name}` : (item?.manual ? 'Docs Stream' : '')}</Typography>
                          </div>
                          <div className={styles.dFlex}>
                            <Typography variant='caption' component='div' lineHeight='normal' display={'inline-block'} className={reportStyles.attDetails}>Date Added</Typography>
                            <Typography variant='caption' component='span' lineHeight='normal' fontWeight={'bold'} color={theme.palette.primary.main} textAlign={'right'}>{dateTolocale(item.created, DateFormat + ' ' + 'hhmm').toUpperCase()}</Typography>
                          </div>
                        </Stack>
                      </div>
                    ))}
                  </Stack>
                </StyledCard>
              </Grid>
            </Grid> : null
        }

        {!props.showReportModal &&
          props.userContractTypes && props.userContractTypes.length > 0 ?
          <Grid item container columnSpacing={3} rowSpacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} order={{
              xs: 2,
              xl: 1
            }}>
              <div ref={props.refROBrn} className={styles.w100}>
                <CustomButton variant='contained' className={`${styles.minWidth280}`} name='reopen'
                  onClick={() => { props.handleClick(props.refROBrn.current, 3); }}
                  // onClick={() => props.handleChange('Reopen')} 
                  disabled={props.disableButtons('Reopen') ? true : false} > Reopen Flight Report (Supervisors Only)</CustomButton>
              </div>
            </Grid>
            <Grid item xs={12} xl={4} order={{
              xs: 1,
              xl: 2
            }}>
              {/* <CustomTextField
                // {...InputProps}
                variant='outlined'
                id=''
                fullWidth
                placeholder='Enter remarks (Optional)'
                type='remark'
                name=''
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={(e) => props.handleRemarks(e)}
                value={props.remarks}
                // disabled={props.disableButtons( (cookies.get('role') === 'Office Agent' || cookies.get('role') === 'Senior Office Agent') ? 'Finalized' :
                //   (cookies.get('role') === 'Accounting' || cookies.get('role') === 'Airline Rep') ? 'Approved' : null
                // ) ? true : false}
                disabled={props.disableButtons(getReportstatus()) ? true : false}
                reportLogs
              /> */}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={2} order={{ xs: 3 }}>
              {
                (/*(cookies.get('role') === 'Office Agent' || cookies.get('role') === 'Senior Office Agent') || cookies.get('role') !== 'Airline Rep' && */  getReportstatus() === 'Submitted') ?
                  <div className={`${reportStyles.finalizeBtn} ${styles.mlauto}`}>
                    <CustomButton variant='contained' className={`${styles.mlauto} ${styles.dFlex} ${styles.w100}`} name='submit' onClick={() => props.handleChange('Submitted', null, null)}
                      disabled={props.disableButtons('Submitted') ? true : false}
                    >	Submit for Billing</CustomButton>
                  </div>
                  :
                  // (( (cookies.get('role') === 'Accounting' || cookies.get('role') === 'Senior Accounting') && getReportstatus() === 'Finalized') || getReportstatus() === 'Finalized') ?
                  //   <div className={`${reportStyles.finalizeBtn} ${styles.mlauto}`}>
                  //     <CustomButton variant='contained' className={`${styles.mlauto} ${styles.dFlex} ${styles.w100}`} name='finalize' onClick={() => props.handleChange('Finalized')}
                  //       disabled={props.disableButtons('Finalized') ? true : false}
                  //     >Finalize Flight Report</CustomButton>
                  //     {props.lastLog && props.lastLog.status === 'Rejected' && 
                  //     <><Typography variant='caption' component='div' lineHeight='normal' color='error' fontWeight={'bold'} display={'flex'}>
                  //       <span className={styles.spaceNowrap}>Last Rejected Log: &nbsp;</span>
                  //       <span>{props.lastLog.status} by {props.lastLog.user.first_name} {props.lastLog.user.last_name}</span> 
                  //     </Typography>
                  //     <Typography variant='caption' component='div' lineHeight='normal' color='error' fontWeight={'bold'}>Remark: &nbsp;{props.lastLog.remark}</Typography></> }
                  //   </div>             
                  //   :
                  (cookies.get('role') === 'Accounting' || cookies.get('role') === 'Senior Accounting' || cookies.get('role') === 'Airline Rep' || cookies.get('role') === 'System Admin') ?
                    <div className={`${styles.dFlex} ${styles.gap10} ${styles.w100}`}>
                      {/* <CustomButton variant='contained' className={`${styles.mlauto} ${styles.dFlex} ${reportStyles.finalizeBtn}`} name='Approved'
                          onClick={() => props.handleChange('Approved')} disabled={props.disableButtons('Approved') ? true : false}
                        >Approve</CustomButton> */}
                      {/* <CustomButton variant='contained' className={`${styles.mlauto} ${styles.dFlex} ${reportStyles.finalizeBtn}`} name='Approved'
                          onClick={() => props.handleChange('Approved')} disabled={props.disableButtons('Approved') ? true : false}
                        >Approve</CustomButton> 

                      <CustomButton variant='contained' className={`${styles.mlauto} ${styles.dFlex} ${reportStyles.finalizeBtn}`} name='Rejected'
                        onClick={() => props.handleChange('Rejected')} disabled={props.disableButtons('Rejected') ? true : false}
                      >Reject</CustomButton> */}
                      <div ref={props.refBtn} className={styles.w100}>
                        <CustomButton variant='contained'
                          className={`${styles.mlauto} ${styles.dFlex} ${reportStyles.finalizeBtn}`}
                          name='approve'
                          onClick={() => { props.handleClick(props.refBtn.current, 2); }}
                          disabled={props.disableButtons('Approved') ? true : false}
                        >Complete Review</CustomButton>
                      </div>
                    </div> : null
              }
            </Grid>
          </Grid> : null
        }
      </Grid>
      <Popover
        open={props.popup === 'confirm'}
        anchorEl={props.anchorEl}
        onClose={props.closePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      ><div className={styles.p3}>
          <Typography variant='caption' >Are you sure you want to save Statistics?</Typography>
          <Stack className={styles.pt3} spacing={1} direction='row'>
            <CustomButton variant='contained' size='small' onClick={props.handleStatisticPost}> <Typography variant='caption' lineHeight='normal' fontWeight='bold'>Yes</Typography></CustomButton>
            <CustomButton variant='outlined' size='small' onClick={props.closePopup}> <Typography variant='caption' lineHeight='normal'>No</Typography></CustomButton>
          </Stack>
        </div>
      </Popover>
      <>
        {props.popup === 'finalize' && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 2,
            }}
            onClick={props.closePopup}
          />
        )}
        <Popover
          open={props.popup === 'finalize'}
          onClose={props.closePopup}
          anchorReference='anchorPosition'
          anchorPosition={{
            top: window.innerHeight / 2,
            left: window.innerWidth / 2,
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          style={{ zIndex: 4 }}
        ><div className={styles.p4}>
            <Typography variant='h6' pb={1}>Confirm Action</Typography>
            <Divider direction='horizontal' className={`${styles.w100}`} />
            <Typography variant='body2' pt={3}>Flight report data will now be processed for billing and invoicing.</Typography>
            <Stack pt={'2rem'} spacing={1} direction='row' justifyContent={'space-between'}>
              <CustomButton variant='contained' size='small' onClick={() => { props.closePopup(); props.handleChange('Finalized'); }} > <Typography variant='body2' lineHeight='normal' fontWeight='bold'>Okay</Typography></CustomButton>
              <CustomButton variant='outlined' size='small' onClick={props.closePopup}> <Typography variant='body2' lineHeight='normal'>Cancel</Typography></CustomButton>
            </Stack>
          </div>
        </Popover>
      </>
      <AddReportDocModal
        modal={props.modal}
        fields={props.fields}
        onChange={props.onChange}
        onSubmit={props.onSubmit}
        handleOpen={props.handleOpen}
      />

      <AddNotes
        // handleOpen={props.handleOpen}
        handleOpen={props.openPopup}
        closePopup={props.closePopup}
        anchorEl={props.anchorEl}
        popup={props.popup}
        note={props.note}
        handleStats={props.handleStats}
        statRowData={props.statRowData}
        cont_item={props.cont_item}
        flightData={props.flightData}
        handleNoteBlur={props.handleNoteBlur}
        handleNotes={props.handleNotes}
        contractList={props.contractList}
        handleReadMore={props.handleReadMore}
        showMoreNote={props.showMoreNote}
      />

      <StatusModal
        statusModal={props.statusModal}
        handleStatusOpen={props.handleStatusOpen}
        statusMessage={props.statusMessage}
        onClose={props.handleStatusClose}
      />

      <ReviewModal
        anchorEl={props.anchorEl2}
        handleReview={props.handleReview}
        submitFunc={props.updateFlightReport}
        remark={props.remark}
        setRemark={props.setRemark}
        selectedOption={props.selectedOption}
        selectOption={props.selectOption}
        modalflight={props.filteredReportLogs}
        handleClose={props.handleClose}
      />
      <ReOpenModal
        anchorEl={props.anchorEl3}
        handleReview={props.handleReview}
        submitFunc={() => props.handleChange('Reopen')}
        remark={props.remark}
        setRemark={props.setRemark}
        modalflight={props.filteredReportLogs}
        handleClose={props.handleClose}
      />
    </>
  );
};

export default FlightReport;